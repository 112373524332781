import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2, 4, 3),
        height: "100%"
    }
}));

export default function GridItem({ xs = 12, children, ...rest }) {
    const classes = useStyles();

    return (
        <Grid item xs={xs}>
            <Paper elevation={3} className={classes.paper}>
                <Box p={1} pt={3} {...rest}>
                    {children}
                </Box>
            </Paper>
        </Grid>
    );
}
