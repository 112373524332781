import firebaseConfig from './firebaseConfig';

export const FIREBASE_CONFIG = firebaseConfig;

export const FIREBASE_ID_TOKEN = 'firebase_id_token';
export const LOCAL_SERVER_URI = 'http://127.0.0.1:8080';
export const PROD_SERVER_URI = 'https://api.vendorpm.com';
export const DEVELOPMENT = process.env.NODE_ENV === 'development';

export const SERVER_URI = DEVELOPMENT ? LOCAL_SERVER_URI : PROD_SERVER_URI;
