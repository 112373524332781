import React, { useState } from "react";
import { Divider, Typography, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import ReviewItem from "./ReviewItem";
import { SnackBarContext } from "../../../components/SnackBarProvider";

const useStyles = makeStyles(theme => ({
    cardContainer: {
        marginLeft: "auto",
        marginRight: "auto",
        padding: theme.spacing(2),
        paddingRight: 0,
        paddingTop: 0
    }
}));

export default function VendorProfileReviewSection({ reviews, reviewerView }) {
    const classes = useStyles();
    const [toast, settoast] = useState(true);
    const pushSnackBar = React.useContext(SnackBarContext);

    React.useEffect(() => {
        if (
            !!reviews.filter(
                ({ created_date }) => (new Date() - Date.parse(created_date)) / 1000 < 5
            ).length
        )
            pushSnackBar({
                severity: "success",
                message: "Review Submitted!"
            });
    }, [reviews]);

    return (
        <div className={classes.cardContainer} style={{ width: "100%" }}>
            <Typography variant="h5" style={{ marginBottom: 16 }}>
                {(reviews && !reviews.length && "No Reviews Yet") || null}
                {(reviews && !!reviews.length && "Reviews") || null}
            </Typography>

            {reviews.map(review => (
                <React.Fragment key={review._id}>
                    <ReviewItem {...{ ...review, reviewerView }} />
                    <Divider
                        style={{
                            marginTop: 32,
                            marginBottom: 8
                        }}
                    />
                </React.Fragment>
            ))}
        </div>
    );
}
