import "date-fns";

import React from "react";
import { Typography, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { TextField, Chip } from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

export default function PaymentForm({
    service_id,
    scopeOfWork,
    setScopeOfWork,
    files,
    setFiles,
    notes,
    setNotes,
    date,
    setDate
}) {
    return (
        <React.Fragment>
            <Typography variant="h6" style={{ textTransform: "capitalize", marginBottom: 32 }}>
                {service_id.replace(/-/g, " ")}
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <TextField
                        id="cardName"
                        label="Scope of Work"
                        fullWidth
                        value={scopeOfWork}
                        onChange={e => setScopeOfWork(e.target.value)}
                        multiline
                        rows={5}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        id="additionalNotes"
                        label="Additional Notes"
                        fullWidth
                        value={notes}
                        onChange={e => setNotes(e.target.value)}
                        multiline
                        rows={3}
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <input
                        style={{ display: "none" }}
                        id="raised-button-file"
                        multiple
                        onChange={e => setFiles([...files, ...e.target.files])}
                        type="file"
                    />
                    <label htmlFor="raised-button-file">
                        <Button variant="contained" component="span" color="primary">
                            Upload Attachments
                        </Button>
                    </label>
                    <br />
                    <br />
                    {files.map((file, index) => (
                        <Chip
                            key={file.name}
                            label={file.name}
                            style={{ marginRight: 16 }}
                            color={"primary"}
                            onDelete={() => setFiles(files.filter((file, i) => index !== i))}
                        />
                    ))}
                </Grid>
                <Grid item xs={12} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            margin="normal"
                            id="date-picker-dialog"
                            label="Submission Deadline"
                            format="MM/dd/yyyy"
                            value={date}
                            onChange={date => {
                                console.log(date.getTime());
                                setDate(date);
                            }}
                            KeyboardButtonProps={{
                                "aria-label": "change date"
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
