import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useSignUpStyles } from "../../../components/sign_up/useSignUpStyles";
import { VerifiedAddressPicker } from "../../../components/VerifiedAddressPicker";
import { ServiceMultiPicker } from "../../../components/ServiceMultiPicker";
import RatingInput from "../speed_rating/RatingInput";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles(theme => ({
    formContainer: {
        width: 600
    },
    formMargin: {
        margin: theme.spacing(3)
    }
}));

const FILTERS = [
    {
        key: "companyName",
        name: "Company Name"
    },
    {
        key: "firstName",
        name: "First Name"
    },
    {
        key: "lastName",
        name: "Last Name"
    },
    {
        key: "phoneNumber",
        name: "Phone Number"
    }
];

export default function AddVendorForm({ onSubmit }) {
    const classes = useStyles();
    const signUpClasses = useSignUpStyles();

    const initialFormState = FILTERS.map(({ key }) => ({ [key]: "" }));
    const [form, setState] = useState(initialFormState);

    const submitForm = e => {
        e.preventDefault();
        onSubmit && onSubmit();
        console.log(form);
    };

    const updateField = e => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const makeSetStateForProp = prop => value => setState({ ...form, [prop]: value });
    const setDisplayedFormattedAddress = makeSetStateForProp("displayedFormattedAddress");

    return (
        <Paper>
            <Box p={3} className={classes.formContainer}>
                <form onSubmit={submitForm} className={classes.formMargin}>
                    <h1>Add a Vendor</h1>
                    {FILTERS.map(({ key, name }) => (
                        <Box mb={3}>
                            <TextField
                                label={name}
                                id={key}
                                variant="outlined"
                                className={classes.basicInfoField}
                                fullWidth
                                value={form[key]}
                                onChange={updateField}
                            />
                        </Box>
                    ))}
                    <Box mb={3}>
                        <VerifiedAddressPicker
                            label={"Address*"}
                            classes={signUpClasses}
                            addressPredicate={address => address.province === "Ontario"}
                            onAddress={setDisplayedFormattedAddress}
                            margin="normal"
                        />
                    </Box>
                    <ServiceMultiPicker
                        services={form.services}
                        setServices={makeSetStateForProp("services")}
                    />
                    <Box
                        m={2}
                        pt={2}
                        flexDirection="row"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <InputLabel>Rating</InputLabel>
                        <RatingInput
                            rating={form.rating}
                            setRating={makeSetStateForProp("rating")}
                        />
                    </Box>
                    <Box mt={4} justifyContent="center" display="flex">
                        <Button variant="contained" color="primary" label="Submit" type="submit">
                            Add
                        </Button>
                    </Box>
                </form>
            </Box>
        </Paper>
    );
}
