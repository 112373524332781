import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { fieldProps } from '../../lib/helpers';
import { gql, useMutation } from '../../lib/apollo';
import { createUserWithEmailAndPassword } from '../../lib/firebase';

const useStyles = makeStyles(theme => ({
    formElement: {
        marginTop: theme.spacing(2)
    }
}));

const defaultData = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    company: '',
    phoneNumber: '',
    streetName: '',
    streetNumber: '',
    city: '',
    province: '',
    country: '',
    postalCode: '',
};

export default function EnterpriseSignUp() {
    const classes = useStyles();
    const [data, setData] = useState(defaultData);
    const [createEnterpriseUser] = useMutation(CREATE_ENTERPRISE_USER, { onError: err => console.error(err.message) });

    const passwordMatch = (data.password === data.confirmPassword);

    const handleSubmit = async event => {
        event.preventDefault();
        if (!passwordMatch) return alert("Passwords do not match, please try again.");

        let uid = (await createUserWithEmailAndPassword(data.email, data.password) || {}).uid;
        const variables = getVariables(data, uid);

        createEnterpriseUser({ variables });
    };

    const formFieldProps = (label, type = 'text') =>
        fieldProps(label, type, classes.formElement, data, setData);

    return (
        <Container maxWidth="sm">
            <Typography variant="h3">Register Your Business</Typography>
            <Typography variant="subtitle1">Please provide all the required details to register your business with us.</Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
                <TextField {...formFieldProps('First Name')} />
                <TextField {...formFieldProps('Last Name')} />
                <TextField {...formFieldProps('Email')} />
                <TextField {...formFieldProps('Password', 'password')} />
                <TextField {...formFieldProps('Confirm Password', 'password')} />
                <TextField {...formFieldProps('Company')} />
                <TextField {...formFieldProps('Phone Number', 'tel')} />
                <TextField {...formFieldProps('streetName')} />
                <TextField {...formFieldProps('streetNumber')} />
                <TextField {...formFieldProps('City')} />
                <TextField {...formFieldProps('Province')} />
                <TextField {...formFieldProps('Country')} />
                <TextField {...formFieldProps('Postal Code')} />
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    className={classes.formElement}
                >Sign Up</Button>
            </form>
        </Container>
    );
}


const CREATE_ENTERPRISE_USER = gql`
    mutation createEnterpriseUser($user: UserInput!) {
        createEnterpriseUser(user: $user) {
            _id
            uid
        }
    }
`;

const getVariables = (data, uid) => ({
    user: {
        email: data.email,
        name: {
            first: data.firstName,
            last: data.lastName,
        },
        company_name: data.companyName,
        phone_number: data.phoneNumber,
        address: {
            postal_code: data.postalCode,
            street: data.streetName,
            street_number: data.streetNumber,
            city: data.city,
            province: data.province
        },
        role: "PM",
        uid: data.uid || uid,
    },
});