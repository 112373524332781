/** @format */

import React, { useState } from "react";
import { Paper, Typography, InputBase, Fab, Grid, Tooltip } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Link, withRouter } from "react-router-dom";
import cn from "classnames";

import { listOfServices } from "../../lib/serviceSearch";
import Suggestions from "./Suggestions";
import { CLEAR_SELECTED_VENDORS } from "../../lib/apollo/mutations";
import { useMutation } from "react-apollo";

const useStyles = makeStyles(theme => ({
    paper: {
        padding: "48px 40px",
        paddingBottom: 72,
        width: 860,
        borderTop: `solid 16px ${theme.palette.primary.main}`,
        backgroundColor: "rgba(0,0,0,0.7)",
        color: "#fff",
        textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
        boxShadow: [
            "0 0 4px -1px rgba(80,80,80,0.2)",
            "0 0 5px 6px rgba(80,80,80,0.07)",
            "0 0 5px 0px rgba(80,80,80,0.09)"
        ].join(", "),
        borderRadius: 0,
        margin: 16,
        position: "relative",
        textAlign: "center",
        "@media (max-width: 900px)": {
            borderTop: `solid 12px ${theme.palette.primary.dark}`,
            paddingTop: 32,
            marginTop: 8,
            maxWidth: 700,
            width: "100%"
        },
        "@media (max-width: 560px)": {
            paddingBottom: 96
        }
    },

    headline: {
        fontWeight: 800,
        fontSize: 44,
        "@media (max-width: 900px)": {
            fontSize: 34
        },
        "@media (max-width: 600px)": {
            fontSize: 30
        }
    },

    prompt: {
        margin: "16px 0.5rem 0 0",
        paddingTop: 4,
        paddingBottom: 9,
        fontWeight: "600",
        letterSpacing: "0.05em",
        display: "inline-block",
        fontSize: 36,
        "@media (max-width: 900px)": {
            fontSize: 24
        }
    },

    prompt2: {
        margin: "16px 0.5rem 0 0.5rem",
        paddingTop: 4,
        paddingBottom: 9,
        fontWeight: "600",
        letterSpacing: "0.05em",
        display: "inline-block",
        fontSize: 36,
        "@media (max-width: 900px)": {
            fontSize: 24
        }
    },

    textField: {
        paddingBottom: 0,
        color: "#fff",
        fontWeight: "600",
        width: "7em",
        borderBottom: `solid 4px ${theme.palette.primary.light}`,
        "& input": {
            textAlign: "center",
            letterSpacing: "0.05em",
            textShadow: "0 0 1px rgba(0,0,0,0.2)"
        },
        fontSize: 36,
        "@media (max-width: 900px)": {
            fontSize: 24
        }
    },

    containerPaddingTop: {
        paddingTop: 120,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingBottom: 200,
        position: "relative",
        width: "100%",
        overflow: "hidden",
        backgroundColor: "rgba(0,0,0,0.1)",
        "@media (max-width: 900px)": {
            paddingTop: 120,
            paddingBottom: 150
        },
        "@media (max-width: 700px)": {
            paddingTop: 80,
            paddingBottom: 120
        },
        "@media (max-width: 450px)": {
            paddingTop: 40,
            paddingBottom: 104
        }
    },

    landingPageBG: {
        position: "absolute",
        left: "50%",
        top: "50%",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        zIndex: -100,
        filter: "brightness(0.8)",
        minHeight: "100%",
        backgroundImage: 'url("https://vendorpm.blob.core.windows.net/vendorpm/fallbackBG.jpg")',
        minWidth: "100%",
        transform: "translateX(-50%) translateY(-50%)",
        width: "auto",
        height: "auto",
        overflow: "hidden"
    },

    floatingBtnContainer: {
        position: "absolute",
        bottom: 16,
        right: 0,
        width: "100%",
        "& a": {
            textDecoration: "none",
            transition: "150ms",
            "& p": {
                fontWeight: "bold",
                margin: "0 56px",
                fontSize: 18
            },
            opacity: 0.8,
            color: "#27a9e1",
            "&:hover": {
                color: "#2079c1"
            }
        }
    },

    floatingBtns: {
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`
    },

    input_wSuggestions: {
        position: "relative"
    }
}));

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: "rgba(0,0,0,0.87)",
        color: "rgba(255,255,255,1)",
        // textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
        boxShadow:
            "0 0 4px -1px rgba(80,80,80,0.2), 0 0 5px 6px rgba(80,80,80,0.07), 0 0 5px 0px rgba(80,80,80,0.09)",
        //borderRadius: 24,
        margin: 24,
        padding: 12,
        fontWeight: 400,
        borderTop: `solid 4px ${theme.palette.primary.light}`,
        maxWidth: 315,
        fontSize: theme.typography.pxToRem(16)
        //border: '1px solid #dadde9',
    }
}))(Tooltip);

export default withRouter(function CallToSearch({ pickView, render, setRender, history }) {
    let classes = useStyles();
    const [serviceSearchText, setServiceSearchText] = useState("");
    // const [locationSearchText, setLocationSearchText] = useState("");
    const [clearSelectedVendors] = useMutation(CLEAR_SELECTED_VENDORS);
    const [highlightedSuggestion, setHighlightedSuggestion] = useState(0);
    const [videoPlaying, setVideoPlaying] = useState(false);

    const serviceSearchRegEx = new RegExp(serviceSearchText, "i");

    const serviceSearchSuggestions = serviceSearchText
        ? listOfServices.filter(service => serviceSearchRegEx.test(service)).slice(0, 3)
        : [];

    return (
        <Grid container spacing={0} className={cn(classes.containerPaddingTop)} justify="center">
            <video
                autoPlay
                muted
                loop
                onPlay={() => setVideoPlaying(true)}
                id="backgroundVid"
                className={cn(classes.landingPageBG)}
            >
                {/* <source src="https://vendorpm.blob.core.windows.net/vendorpm/skylinebg.mp4" type="video/mp4" /> */}
            </video>
            <div
                className={cn(classes.landingPageBG)}
                style={{
                    visibility: videoPlaying ? "hidden" : "visible"
                }}
            />
            <Paper className={cn(classes.paper)}>
                <Typography style={{ letterSpacing: "5px" }} variant="h5" gutterBottom>
                    Office | Condo | Retail | Industrial | Apartment
                </Typography>
                <Typography
                    variant="h4"
                    component="h4"
                    className={cn(classes.headline)}
                    gutterBottom
                >
                    Connecting Property Managers To Trusted Vendors
                </Typography>

                <React.Fragment>
                    <span style={{ whiteSpace: "nowrap" }}>
                        <span className={classes.prompt}>I need</span>

                        <span className={classes.input_wSuggestions}>
                            <InputBase
                                id="search-services"
                                value={serviceSearchText}
                                onChange={event => {
                                    setServiceSearchText(event.target.value);
                                }}
                                placeholder="service"
                                type="search"
                                className={classes.textField}
                                onKeyDown={e => {
                                    switch (e.keyCode) {
                                        case 38:
                                            e.preventDefault();
                                            setHighlightedSuggestion(
                                                highlightedSuggestion
                                                    ? highlightedSuggestion - 1
                                                    : serviceSearchSuggestions.length - 1
                                            );
                                            break;
                                        case 40:
                                            e.preventDefault();
                                            setHighlightedSuggestion(
                                                highlightedSuggestion >
                                                    serviceSearchSuggestions.length - 2
                                                    ? 0
                                                    : highlightedSuggestion + 1
                                            );
                                            break;
                                        case 13:
                                            clearSelectedVendors();
                                            history.push(
                                                `/vendors-of/${encodeURIComponent(
                                                    serviceSearchSuggestions[highlightedSuggestion]
                                                )}`
                                            );
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            />

                            <Suggestions
                                items={serviceSearchSuggestions}
                                highlightedSuggestion={highlightedSuggestion}
                            />
                        </span>
                    </span>

                    <span style={{ whiteSpace: "nowrap" }}>
                        <span className={classes.prompt2}>in</span>

                        <span className={classes.input_wSuggestions}>
                            {/* <HtmlTooltip
                                placement="top"
                                leaveDelay={500}
                                interactive
                                title={
                                    <React.Fragment>
                                        <b color="inherit">
                                            Looking for vendors outside Toronto?
                                        </b>
                                        <br />
                                        We're only operating in the GTA right
                                        now but are expanding soon.{" "}
                                        <Link
                                            to="/signup"
                                            style={{ color: "#fff" }}
                                        >
                                            Sign up
                                        </Link>{" "}
                                        to find out when we launch in your city!
                                    </React.Fragment>
                                }
                            > */}
                            <InputBase
                                id="search-services"
                                placeholder="location"
                                value={"Ontario"}
                                readOnly
                                onChange={event => {
                                    // setLocationSearchText(event.target.value);
                                }}
                                type="search"
                                className={classes.textField}
                            />
                            {/* </HtmlTooltip> */}
                        </span>
                        <Suggestions items={[]} />
                    </span>
                </React.Fragment>
                {/* <div style={{ marginTop: 8 }}>OR</div> */}
                <div className={classes.floatingBtnContainer}>
                    <Link to="/search-vendors">
                        <Typography>Search company listings and read reviews</Typography>
                        {/* <Fab
                            color="primary"
                            size="large"
                            variant="extended"
                            className={classes.floatingBtns}
                        >
                            Search Vendors
                        </Fab> */}
                    </Link>

                    {/* <Link to="/all-qualified-vendors/write-a-review">
                        <Fab
                            color="primary"
                            variant="extended"
                            size="large"
                            className={classes.floatingBtns}
                        >
                            Write A Review
                        </Fab>
                    </Link> */}
                </div>
            </Paper>
        </Grid>
    );
});
