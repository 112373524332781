import React, { useState } from "react";
import SignUpContainer from "../../components/sign_up/SignUpContainer";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";
import { useSignUpStyles } from "../../components/sign_up/useSignUpStyles";
import firebase from "firebase/app";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CREATE_USER } from "../../lib/apollo/mutations";
import { GET_USER, GET_USER_FROM_TOKEN } from "../../lib/apollo/queries";
import BasicInfo from "../../components/sign_up/BasicInfo";
import CredentialsView from "../../components/sign_up/CredentialsView";
import { VerifiedAddressPicker } from "../../components/VerifiedAddressPicker";
import SigninCredentials from "../../components/sign_up/SigninCredentials";
import SignupCredentials from "../../components/sign_up/SignupCredentials";

export default function SignUp({ invite, signin, vendor }) {
    const classes = useSignUpStyles();
    const history = useHistory();
    const { invitation_token, auth_needed } = useParams();
    async function onLogin() {
        // window.location.href = "/";
        history.push("/");
    }

    const [state, setState] = useState({
        ErrorMsg: "",
        step: 0,
        vendor,
        email: "",
        password: ""
    });
    const makeSetStateForProp = prop => value => setState({ ...state, [prop]: value });
    const setError = makeSetStateForProp("ErrorMsg");

    return (
        <SignUpContainer>
            <Paper className={classes.paperForFields} elevation={0}>
                <Typography gutterBottom variant="h5" style={{ marginBottom: 24 }}>
                    Sign In
                </Typography>
                <SigninCredentials {...{ state, setState, setError, onLogin }} />
            </Paper>
        </SignUpContainer>
    );
}
