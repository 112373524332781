import React from "react";
import {
    Button,
    Divider,
    Grid,
    TextField,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    Table,
    makeStyles
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useMutation } from "@apollo/react-hooks";
import { SEND_INVITE } from "../../lib/apollo/mutations";
import Alert from "@material-ui/lab/Alert";
import { FiCheckCircle } from "react-icons/fi";
import { GET_USER } from "../../lib/apollo/queries";

const useStyles = makeStyles({
    table: {}
});

export default function RequestPane({ vendor, invitees }) {
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [showAlert, setShowAlert] = React.useState(false);
    const [invite, { called, data, loading, error }] = useMutation(SEND_INVITE, {
        variables: { invitee_email: email, invitee_phone: phone },
        onCompleted: () => {
            setPhone("");
            setEmail("");
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 2500);
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_USER
            }
        ]
    });
    return (
        <Grid container spacing={2}>
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ marginBottom: 24 }}>
                            {vendor
                                ? "Invite PM's to Boost Your Profile"
                                : "Get Your Vendors on VendorPM"}
                        </Typography>
                        <Divider style={{ marginBottom: 24 }} />
                    </Grid>
                </Grid>
            </React.Fragment>

            <Grid item xs={12}>
                <Typography variant="body1">
                    {vendor
                        ? "Enter your client's email and phone number. We will send them an email so they can write a review."
                        : "Enter your vendor's email and phone number. We will send them an email so they can sign up for VendorPM and start taking RFQ's."}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    id="email"
                    label="Email"
                    required={true}
                    fullWidth
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    id="phone"
                    label="Phone Number"
                    fullWidth
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right" }}
                    onClick={invite}
                >
                    Send
                </Button>
            </Grid>

            <Grid item xs={12}>
                {showAlert &&
                    (data?.sendInvitation !== null ? (
                        <Alert severity="info" color="info">
                            Request Sent Successfully
                        </Alert>
                    ) : (
                        <Alert severity="error" color="error">
                            We couldn't send the email at this time.
                        </Alert>
                    ))}
            </Grid>
            {invitees && <Invitees invitees={invitees} />}
        </Grid>
    );
}

function Invitees({ invitees }) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ marginBottom: 24 }}>
                        Your Invites
                    </Typography>
                    <Divider style={{ marginBottom: 24 }} />
                </Grid>

                <Grid item xs={12}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell align="left">Phone</TableCell>
                                <TableCell align="center">Request Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {invitees.map(row => (
                                <TableRow key={row.email}>
                                    <TableCell component="th" scope="row">
                                        {row.email}
                                    </TableCell>
                                    <TableCell align="left">{row.phone}</TableCell>
                                    <TableCell align="center">
                                        {row.joined && <FiCheckCircle size={18} strokeWidth={3} />}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
