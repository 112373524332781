import { useSignUpStyles } from "./useSignUpStyles";
import { Redirect, useHistory, useParams } from "react-router-dom";
import firebase from "firebase/app";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_USER, SIGN_IN } from "../../lib/apollo/mutations";
import { GET_USER } from "../../lib/apollo/queries";
import React from "react";
import { Button, Divider, TextField, Typography } from "@material-ui/core";
import PasswordField from "../PasswordField";
import { makeSetterForProperty } from "../../lib/helpers";

export default function CredentialsView({
    state,
    setEmail,
    children,
    doSubmitAction,
    onGoogleSignIn,
    invitedUser,
    submitText
}) {
    const classes = useSignUpStyles();
    const history = useHistory();

    return (
        <React.Fragment>
            <TextField
                label="Email*"
                variant="outlined"
                className={classes.basicInfoField}
                fullWidth
                value={state.email}
                helperText={invitedUser ? "You've been invited with this email" : undefined}
                onChange={invitedUser ? null : e => setEmail(e.target.value)}
            />

            {children}
            <Typography color="error">{state.ErrorMsg}</Typography>

            <React.Fragment>
                <Button
                    fullWidth
                    variant="outlined"
                    className={classes.signUpButton}
                    onClick={doSubmitAction}
                >
                    {submitText}
                </Button>

                <div style={{ textAlign: "center" }}>
                    <Divider className={classes.orDivider} />
                    OR
                    <Divider className={classes.orDivider} />
                </div>

                <Button
                    fullWidth
                    variant="outlined"
                    className={classes.signInWithGoogleButton}
                    onClick={onGoogleSignIn}
                >
                    Continue With Google
                </Button>
            </React.Fragment>
        </React.Fragment>
    );
}
