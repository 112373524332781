import gql from "graphql-tag";
import { FIND_VENDORS } from "./queries";

export default {
    Vendor: {
        selected: ({ _id }, variables, { client, cache, getCacheKey }) => {
            const vendor = client.readFragment({
                id: _id,
                fragment: gql`
                    fragment vendorSelected on Vendor {
                        _id
                        company_name
                        selected @client
                    }
                `
            });
            return vendor?.selected || false;
        }
    },

    Mutation: {
        clearSelectedVendors(_, args, { cache, client }) {
            // debugger;
            Object.entries(cache.data.data)
                .filter(([k, s]) => s.__typename === "Vendor")
                .map(([k, s]) => client.writeData({ id: k, data: { selected: false } }));
        },

        selectVendor: (_, { _id }, { cache, client }) => {
            const vendor = client.readFragment({
                id: _id,
                fragment: gql`
                    fragment vendorSelected on Vendor {
                        _id
                        company_name
                        selected @client
                    }
                `
            });
            client.writeData({ id: _id, data: { selected: !vendor?.selected } });
            return !vendor?.selected;
        },

        setLoggedIn: (_, { loggedIn }, { cache }) => {
            return true;
        }
    },

    Query: {
        getSelectedVendors: (parent, variables, { cache, client, getCacheKey }) => {
            // TODO Do this properly
            return Object.entries(cache.data.data)
                .filter(([k, s]) => s.__typename === "Vendor" && s.selected)
                .map(([k, s]) => s);

            // const vendors = client.readFragment({
            //     selected: true,
            //     fragment: gql`
            //         fragment vendorSelected on Vendor {
            //             company_name
            //             selected
            //         }
            //     `,
            // },{
            //     fetchPolicy: "cache-only"
            // });
            // console.log(vendors);
            // // client.writeData({id: _id, data: {selected: !vendor?.selected}});
            // //console.log(`selcted?: ${!(vendor?.selected)}`);
            //
            // return vendors;

            // const serializedState = cache.extract();
            // const typeNameItems = Object.values(serializedState).filter(
            //     item => item.__typename === "Vendor" && item.selected
            // );
            // return typeNameItems;
        }
    }
};
