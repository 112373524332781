import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { CLOSE_RFQ, RESPOND_TO_RFQ } from "../../lib/apollo/mutations";
import { Button, Paper, Typography } from "@material-ui/core";
import { formatRelative, subDays } from "date-fns";
import ContactInfoBox from "./ContactInfoBox";
import RFQDialog from "../service_planner/RFQDialog";
import { makeStyles } from "@material-ui/core/styles";
import ResponseTypePicker from "./ResponseTypePicker";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import { GET_USER } from "../../lib/apollo/queries";
import { storageUrlFrom } from "../../lib/helpers";

export default function RFQItem({ wrappedItem }) {
    const classes = useStyles();
    const [closedState, setClosedState] = useState(false);

    return (
        <Paper
            className={classes.paper}
            elevation={0}
            key={87}
            style={{ display: !!closedState ? "none" : "block" }}
        >
            <div
                style={{
                    display: "inline-block",
                    width: "60%"
                }}
            >
                <Typography
                    variant="h6"
                    style={{
                        textTransform: "capitalize",
                        marginBottom: 24
                    }}
                >
                    {wrappedItem.service.replace(/-/g, " ")}
                </Typography>
                <Typography variant="subtitle2">Scope of Work</Typography>
                <Typography variant="body1" style={{ marginBottom: 24 }}>
                    {wrappedItem.scope_of_work}
                </Typography>
                <Typography variant="subtitle2">Notes</Typography>
                <Typography variant="body1" style={{ marginBottom: 24 }}>
                    {wrappedItem.notes}
                </Typography>

                <Typography variant="subtitle2">File Attachments</Typography>
                <Typography variant="body1" gutterBottom style={{ marginBottom: 24 }}>
                    {wrappedItem.attachments.map(file => (
                        <a
                            className={classes.blueLinks}
                            href={storageUrlFrom(file)}
                            key={file}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            {file.split("xz-b")[1] || file}
                        </a>
                    ))}
                </Typography>

                <Typography variant="subtitle2">Submission Deadline</Typography>
                <Typography variant="body1" style={{ marginBottom: 24 }}>
                    {formatRelative(subDays(new Date(), 3), new Date(wrappedItem.deadline_date))}
                </Typography>
            </div>
            <div
                style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    width: "40%",
                    textAlign: "right"
                }}
            >
                {wrappedItem.vendors && (
                    <React.Fragment>
                        <Typography variant="h6" style={{ marginBottom: 8 }}>
                            Invited Vendors
                        </Typography>
                        {wrappedItem.vendors.map(vendor => {
                            const response = (wrappedItem.responses || []).filter(
                                r => r.vendor_id === vendor._id
                            )[0];
                            // responded_date
                            return (
                                <Typography
                                    variant="body1"
                                    key={vendor._id + wrappedItem.deadline_date}
                                >
                                    {vendor.company_name}
                                    <VendorResponseIcon response={response && response.status} />
                                </Typography>
                            );
                        })}
                    </React.Fragment>
                )}
                {wrappedItem.pm && !wrappedItem.vendors && (
                    <React.Fragment>
                        <Typography variant="h6" style={{ marginBottom: 8 }}>
                            Requesting Property Manager
                        </Typography>

                        <ContactInfoBox user={wrappedItem.pm} />
                    </React.Fragment>
                )}
            </div>

            <div
                style={{
                    textAlign: "right"
                }}
            >
                {!wrappedItem.vendors ? (
                    <VendorButtonBank wrappedItem={wrappedItem} />
                ) : (
                    <PMButtonBank setClosedState={setClosedState} wrappedItem={wrappedItem} />
                )}
            </div>
        </Paper>
    );
}

function VendorResponseIcon({ response }) {
    switch (response) {
        case "Email":
            return (
                <Tooltip placement="top" title="This vendor has emailed you!">
                    <IconButton aria-label="email recieved">
                        <EmailOutlinedIcon />
                    </IconButton>
                </Tooltip>
            );
        case "Phone":
            return (
                <Tooltip placement="top" title="This vendor has called you!">
                    <IconButton aria-label="email recieved">
                        <PhoneIcon />
                    </IconButton>
                </Tooltip>
            );
        default:
            return (
                <IconButton aria-label="no response" style={{ visibility: "hidden" }}>
                    <EmailOutlinedIcon />
                </IconButton>
            );
    }
}

function PMButtonBank({ setClosedState, wrappedItem }) {
    const [openDialog, setOpenDialog] = useState(false);

    const [closeRFQ] = useMutation(CLOSE_RFQ, {
        variables: { rfq: { _id: wrappedItem._id, closed: true } },
        refetchQueries: [
            {
                query: GET_USER
            }
        ]
    });

    return (
        <React.Fragment>
            <Grid container spacing={2} direction="row" justify={"flex-end"}>
                <Grid item>
                    <Button variant="outlined" onClick={() => setOpenDialog(true)}>
                        Details
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="outlined"
                        onClick={async () => {
                            await closeRFQ();
                            setClosedState(true);
                        }}
                        color="primary"
                    >
                        Close RFQ
                    </Button>
                </Grid>
            </Grid>
            <RFQDialog
                open={openDialog}
                closeDialog={() => setOpenDialog(false)}
                rfqIn={wrappedItem}
            />
        </React.Fragment>
    );
}

function VendorButtonBank({ wrappedItem }) {
    const [respondToRFQ] = useMutation(RESPOND_TO_RFQ, {
        refetchQueries: [
            {
                query: GET_USER
            }
        ]
    });
    const responseEnum = ["Read", "Email", "Phone"];

    return (
        <React.Fragment>
            <Grid container spacing={2} direction="row" justify={"flex-end"}>
                {/* <Grid item>
               <Button variant="outlined" onClick={() => {
               }}>
                   Dismiss
               </Button>
            </Grid> */}
                <Grid item>
                    <ResponseTypePicker
                        initialPick={
                            wrappedItem.my_response
                                ? responseEnum.indexOf(wrappedItem.my_response.status)
                                : 0
                        }
                        onPick={picked => {
                            respondToRFQ({
                                variables: {
                                    _id: wrappedItem._id,
                                    response: responseEnum[picked]
                                }
                            });
                        }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        paddingLeft: 64,
        position: "relative",
        padding: 16,
        paddingTop: 24,
        borderRadius: 0,
        flexBasis: 800,
        paddingBottom: 32,
        borderTop: "solid 2px rgba(0,0,0,0.2)",
        marginBottom: 16,
        "@media (max-width: 700px)": {
            paddingLeft: 16
        }
    },

    blueLinks: {
        textDecoration: "none",
        color: "#0073bb",
        textAlign: "center",
        margin: "0 auto",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));
