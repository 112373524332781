export function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const makeSetterForProperty = (obj, setObj, propertyName) => value =>
    setObj({ ...obj, [propertyName]: value });

export const storageUrlFrom = path => `https://vendorpm.blob.core.windows.net/vendorpm/${path}`;
export const imgixUrlFrom = path => `http://vendorpm.imgix.net/${path}?w=288`;
