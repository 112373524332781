// This must be the first line in src/index.js
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import theme from "./lib/theme";
import client from "./lib/apollo/index";
import Footer from "./Footer";
import Navbar from "./Navbar";
import Routes from "./Routes";
import SnackBarProvider from "./components/SnackBarProvider";
import HubSpotListener from "./components/HubSpotListener";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

ReactGA.initialize("UA-48847913-3");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
    return (
        <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Router>
                    <HubSpotListener>
                        <ScrollToTop />
                        <Navbar />
                        <SnackBarProvider>
                            <Routes />
                        </SnackBarProvider>
                        <Footer />
                    </HubSpotListener>
                </Router>
            </ThemeProvider>
        </ApolloProvider>
    );
}

ReactDOM.render(<App />, document.getElementById("root"));
