import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import typeDefs from "./typeDefs";
import resolvers from "./resolvers";
import { SET_LOGGED_IN } from "./mutations";
import fetch from "unfetch";
import ReactGA from "react-ga";

import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyCfvubiHTCD6v7wlPrVJPozea0R6wAXrYM",
    authDomain: "vendorpm-b6167.firebaseapp.com",
    databaseURL: "https://vendorpm-b6167.firebaseio.com",
    projectId: "vendorpm-b6167",
    storageBucket: "vendorpm-b6167.appspot.com",
    messagingSenderId: "737611827041",
    appId: "1:737611827041:web:a96150731087f0fe842720",
    measurementId: "G-CYVPN0FLCL"
};

firebase.initializeApp(firebaseConfig);

export const cache = new InMemoryCache({
    dataIdFromObject: object => object._id
});

export const onIdTokenChanged = async token => {
    try {
        const idToken = token && (await token.getIdToken());
        if (idToken) {
            localStorage.setItem("token", idToken);
        } else {
            localStorage.removeItem("token");
            localStorage.removeItem("as_user");
        }
    } catch (e) {
        console.log(e);
    }
};

firebase.auth().onIdTokenChanged(onIdTokenChanged);

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    ReactGA.event({
        category: "GraphQL Op",
        label: process.env.NODE_ENV,
        action: _.operationName,
        nonInteraction: _.operationName === "getUser" ? true : false
    });

    return {
        headers: {
            ...headers,
            as_user: localStorage.getItem("as_user") || "",
            authorization: token || ""
        }
    };
});
let uri = "https://api2.vendorpm.com";
// if (process.env.NODE_ENV === 'development') uri = 'http://52.60.150.208:8080'
if (process.env.NODE_ENV === "development") uri = "http://localhost:8080";
const httpLink = new HttpLink({
    uri: uri,
    fetchOptions: { fetch },
    fetch,
    defaultOptions: {
        //     watchQuery: {
        //         fetchPolicy: 'cache-first',
        //         errorPolicy: 'all',
        //     },
        // query: {
        //     fetchPolicy: "cache-first",
        //     errorPolicy: "all",
        // },
        // mutate: {
        //     errorPolicy: 'all'
        // }
    }
});

const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink),
    typeDefs,
    resolvers,
    connectToDevTools: process.env.NODE_ENV === "development"
});

export default client;
