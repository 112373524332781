import React, { useState } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import LandingPage from "./pages_common/landing_page/LandingPage";
import VendorSearch from "./pages_common/vendor_search/VendorSearch";
import ServiceListPage from "./pages_common/ServiceListPage";
import TermsAndConditions from "./pages_common/TermsAndConditions";
import VendorProfile from "./pages_common/vendorProfile";
import pm_routes from "./pages_pm/pm_routes";
import SignOut from "./SignOut";
import SignUp from "./pages_pm/sign_up/SignUp";
import FAQ from "./pages_common/FAQ";
import { GET_USER } from "./lib/apollo/queries";
import { useQuery } from "react-apollo";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";
import VendorSignUp from "./pages_vendor/vendor_sign_up/VendorSignUp";
import VendorWelcome from "./pages_vendor/VendorWelcome";
import { SnackBarContext } from "./components/SnackBarProvider";
import EnterpriseRoutes from "./enterprise/Routes";
import EnterpriseEnrollment from "./pages_common/enterprise_enrollment/EnterpriseEnrollment";
import { AdminVendorList } from "./pages_admin/AdminVendorList";
import AdminPanel from './pages_admin/AdminPanel'

export default withRouter(function Routes() {
    const pushSnackBar = React.useContext(SnackBarContext);
    const { data, loading, error } = useQuery(GET_USER, {
        onCompleted: data => {
            if (data?.getUser) {
                pushSnackBar({
                    severity: "info",
                    message: `Signed In As ${data?.getUser?.name?.first}`
                });
            }
        }
    });

    return (
        <React.Fragment>
            <Switch>
                {pm_routes}
                <Route path="/vendor-sign-up/invitation-token/:invitation_token/">
                    <VendorSignUp invite />
                </Route>
                <Route path="/vendor-sign-up">
                    <VendorSignUp />
                </Route>
                <Route path="/sign-up/invitation/:invitation_token/">
                    <SignUp invite />
                </Route>
                <Route path="/sign-out">
                    <SignOut />
                </Route>
                <Route
                    exact
                    path={[
                        "/categories/:category",
                        "/categories",
                        "/services/get-a-quote",
                        "/services"
                    ]}
                >
                    <ServiceListPage />
                </Route>
                <Route path={["/search-vendors", "/vendors-of/:service_id"]}>
                    <VendorSearch />
                </Route>
                <Route path="/vendor-welcome">
                    <VendorWelcome />
                </Route>
                <Route path="/vendor-profile/:vendor_id/claim/:token">
                    <VendorProfile claim />
                </Route>
                <Route path="/vendor-profile/:vendor_id/claim">
                    <VendorProfile claim />
                </Route>
                <Route path="/vendor-profile/:vendor_id">
                    <VendorProfile />
                </Route>
                <Route path="/terms-and-conditions">
                    <TermsAndConditions />
                </Route>
                <Route path="/faq">
                    <FAQ />
                </Route>
                <Route path="/enterprise-enrollment">
                    <EnterpriseEnrollment />
                </Route>
                <Route path="/enterprise">
                    <EnterpriseRoutes />
                </Route>
                <Route path="/admin">
                    <AdminPanel />
                </Route>
                <Route path="/admin/vendor-list">
                    <AdminVendorList />
                </Route>
                <Route exact path="/">
                    <LandingPage />
                </Route>
                <Route path="/">
                    <Redirect to="/" />
                </Route>
            </Switch>
        </React.Fragment>
    );
});
