import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import BuildingInformationForm from "./BuildingInformationForm";
import ScopeForm from "./PaymentForm";
import Review from "./Review";
import { useParams, Redirect } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_USER, GET_SELECTED_VENDORS } from "../../lib/apollo/queries";
import { CLEAR_SELECTED_VENDORS, CREATE_RFQ } from "../../lib/apollo/mutations";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/Error";
import { useHistory } from "react-router-dom";
import uuidv4 from "uuid/v4";
// const { BlobServiceClient } = require("@azure/storage-blob");
// var blobService = new BlobServiceClient(
//     `https://vendorpm.blob.core.windows.net`,
//     "DefaultEndpointsProtocol=https;AccountName=vendorpm;AccountKey=9wB1PYpZajjXqAypHk5IOgU1U6gEZclSl4Wp3YF+tut0gbcY9x95aOqgi8zA7E6Aa1m+6MajthdRoD9P2GWUSg==;EndpointSuffix=core.windows.net"
// );

// const containerClient = blobService.getContainerClient("vendorpm");

const useStyles = makeStyles(theme => ({
    appBar: {
        position: "relative"
    },
    layout: {
        flexGrow: 1,
        width: "calc(100% - 24px)",
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        maxWidth: 1050
    },
    paper: {
        borderTop: "solid 2px #d7d7d7",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: 770,
        minHeight: 600,
        position: "relative",
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3)
        }
    },
    stepper: {
        padding: theme.spacing(3, 0, 5)
    },
    stepsMobile: {
        "@media(max-width: 485px)": { padding: 3 }
    },
    stepsMobileLabel: {
        "@media(max-width: 485px)": { fontSize: 12 }
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end"
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
    },
    poofItsGoneMD: {
        "@media(max-width: 960px)": {
            display: "none"
        }
    }
}));

const steps = ["Scope of Work", "Building Information", "Review RFQ"];

function getStepContent(
    step,
    service_id,
    chosen_vendors,
    firstName,
    lastName,
    address,
    rfq_data,
    email,
    phone_number
) {
    switch (step) {
        case 0:
            return <ScopeForm service_id={decodeURIComponent(service_id)} {...rfq_data} />;
        case 1:
            return (
                <BuildingInformationForm
                    chosen_vendors={chosen_vendors}
                    firstName={firstName}
                    lastName={lastName}
                    address={address}
                    phone_number={phone_number}
                    email={email}
                />
            );
        case 2:
            return (
                <Review
                    {...rfq_data}
                    chosen_vendors={chosen_vendors}
                    firstName={firstName}
                    lastName={lastName}
                    address={address}
                    phone_number={phone_number}
                    email={email}
                />
            );
        default:
            throw new Error("Unknown step");
    }
}

export default function RequestQuotesForm() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);

    const [files, setFiles] = useState([]);
    const [scopeOfWork, setScopeOfWork] = useState("");
    const [notes, setNotes] = useState("");
    const DEFAULT_RFQ_DAYS = 14;
    const default_due_on = new Date();
    default_due_on.setDate(new Date().getDate() + DEFAULT_RFQ_DAYS);
    const [date, setDate] = useState(default_due_on);

    const { service_id } = useParams();
    const service = decodeURIComponent(service_id);
    const { data: chosen_vendors_data } = useQuery(GET_SELECTED_VENDORS, {
        fetchPolicy: "no-cache"
    });
    const chosen_vendors = (chosen_vendors_data && chosen_vendors_data.getSelectedVendors) || [];
    const history = useHistory();
    const { data, loading, error } = useQuery(GET_USER);
    const { address, name, email, phone_number } = data ? data.getUser || {} : {};
    const { first: firstName, last: lastName } = name || {};

    const [clearSelectedVendors] = useMutation(CLEAR_SELECTED_VENDORS);
    const createRfqArgs = {
        variables: {
            rfq: {
                vendors: chosen_vendors.map(v => v._id),
                service: service,
                submission_date: new Date().getTime(),
                deadline_date: date.getTime(),
                notes: notes,
                priority: "Requested",
                scope_of_work: scopeOfWork,
                attachments: files,
                pm_email: email,
                pm_phone_number: phone_number,
                pm_name: firstName + " " + lastName
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_USER
            }
        ],
        onCompleted: () => {
            clearSelectedVendors();
        }
    };
    const [createRFQ, createRFQResponse] = useMutation(CREATE_RFQ, createRfqArgs);

    if (loading) return <Loading />;
    if (error) return <ErrorMessage message={error} />;
    if (data && !data.getUser) return <Redirect to="/sign-up/return" />;
    // if (chosen_vendors && chosen_vendors.length === 0)
    //     return <Redirect to="/search-vendors" />;

    const handleFileUpload = () => {
        return Promise.all(
            files.map(
                file =>
                    new Promise(async (resolve, reject) => {
                        // const name = file.name;
                        // const path = uuidv4() + "xz-b" + name;
                        // console.log(file);
                        // const blockBlobClient = containerClient.getBlockBlobClient(path);
                        // const uploadBlobResponse = await blockBlobClient.upload(file, file.size);
                        // console.log(uploadBlobResponse);
                        //     blobService.createBlockBlobFromStream(
                        //         "vendorpm",
                        //         path,
                        //         await file.stream(),
                        //         {
                        //             blockSize: file.size,
                        //             contentSettings: {
                        //                 contentType: file.type,
                        //                 contentEncoding: file.type
                        //             }
                        //         },
                        //         error => {
                        //             if (error) {
                        //                 console.log("File upload failed");
                        //                 console.log(error);
                        //                 reject();
                        //             } else {
                        //                 resolve({
                        //                     name,
                        //                     path
                        //                 });
                        //             }
                        //         }
                        //     );
                    })
            )
        );
    };

    const submitRFQ = () => {
        createRFQ();
    };

    const handleNext = async () => {
        if (activeStep === steps.length - 1) {
            const uploadedFiles = await handleFileUpload();
            createRFQ({
                ...createRfqArgs,
                variables: {
                    ...createRfqArgs.variables,
                    rfq: {
                        ...createRfqArgs.variables.rfq,
                        attachments: uploadedFiles.map(f => f.path)
                    }
                }
            });
        }
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <React.Fragment>
            <Grid container spacing={3} justify="center" className={classes.layout}>
                <Grid item md={3} className={classes.poofItsGoneMD}>
                    <Paper className={classes.paper} elevation={0} style={{ minHeight: 400 }}>
                        <Typography variant="h6" style={{ textAlign: "center", fontWeight: 600 }}>
                            Chosen Vendors
                        </Typography>
                        {chosen_vendors.map(vendor => (
                            <Typography
                                variant="subtitle2"
                                style={{
                                    textAlign: "center",
                                    marginTop: 4,
                                    marginBottom: 4
                                }}
                                key={vendor._id}
                            >
                                {vendor.company_name}
                            </Typography>
                        ))}
                    </Paper>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Paper className={classes.paper} elevation={0}>
                        <Typography
                            component="h1"
                            variant="h4"
                            align="center"
                            style={{ fontWeight: 600 }}
                        >
                            Request Quotes
                        </Typography>
                        <Stepper activeStep={activeStep} className={classes.stepper}>
                            {steps.map(label => (
                                <Step key={label} className={classes.stepsMobile}>
                                    <StepLabel
                                        classes={{
                                            label: classes.stepsMobileLabel
                                        }}
                                    >
                                        {label}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {activeStep === steps.length ? (
                                <React.Fragment>
                                    <Typography variant="subtitle1">
                                        Thanks, and best of luck with your quote requests.
                                    </Typography>
                                    <div
                                        style={{
                                            marginTop: 48,
                                            display: "inline-block",
                                            cursor: "pointer",
                                            textDecoration: "none"
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={
                                                createRFQResponse.called &&
                                                createRFQResponse.loading
                                            }
                                            onClick={() => {
                                                history.push("/your-quotes");
                                            }}
                                        >
                                            Manage Quotes
                                        </Button>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {getStepContent(
                                        activeStep,
                                        decodeURIComponent(service),
                                        chosen_vendors,
                                        firstName,
                                        lastName,
                                        address,
                                        {
                                            notes,
                                            setNotes,
                                            files,
                                            setFiles,
                                            date,
                                            setDate,
                                            scopeOfWork,
                                            setScopeOfWork
                                        },
                                        email,
                                        phone_number
                                    )}
                                    <div
                                        className={classes.buttons}
                                        style={{
                                            position: "absolute",
                                            bottom: 32,
                                            right: 30
                                        }}
                                    >
                                        {activeStep !== 0 && (
                                            <Button onClick={handleBack} className={classes.button}>
                                                Back
                                            </Button>
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={e => {
                                                handleNext();
                                            }}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1 ? "Send RFQs" : "Next"}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
