import React, { useState } from "react";
import SignUpContainer from "../../components/sign_up/SignUpContainer";
import { useHistory, useParams } from "react-router-dom";
import { Paper, Typography } from "@material-ui/core";
import { useSignUpStyles } from "../../components/sign_up/useSignUpStyles";
import firebase from "firebase/app";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CREATE_USER } from "../../lib/apollo/mutations";
import { GET_USER, GET_USER_FROM_TOKEN } from "../../lib/apollo/queries";
import BasicInfo from "../../components/sign_up/BasicInfo";
import CredentialsView from "../../components/sign_up/CredentialsView";
import { VerifiedAddressPicker } from "../../components/VerifiedAddressPicker";
import SigninCredentials from "../../components/sign_up/SigninCredentials";
import SignupCredentials from "../../components/sign_up/SignupCredentials";
import AdditionalInfo from "../../pages_vendor/vendor_sign_up/AdditionalInfo";

export const makeUserFromTokenOptions = ({ onCompleted, invite, invitation_token }) => ({
    variables: { token: invitation_token },
    skip: !invite,
    onCompleted
});

export default function SignUp({ invite, signin, vendor }) {
    const classes = useSignUpStyles();
    const history = useHistory();
    const { invitation_token, auth_needed } = useParams();

    async function onLogin() {
        if (auth_needed === "return") {
            history.goBack();
        }
        if (state.signin) {
            if (mongo_user.data.getUser.is_vendor) history.push("/");
            else history.push("/service-planner");
        } else {
            firebase.auth().currentUser.sendEmailVerification();
            history.push("/service-planner/get-started");
        }
    }
    const [ErrorMsg, setError] = useState();
    const [state, setState] = useState({
        signin: signin,
        step: 0,
        // vendor,
        email: "",
        password: "",
        confirmPassword: ""
        // firstName: "",
        // lastName: "",
        // companyName: "",
        // displayedFormattedAddress: {},
        // phoneNumber: "",
        // availability: "Soon",
        // services: [],
        // associations: [],
        // compliances: []
    });
    const makeSetStateForProp = prop => value => setState({ ...state, [prop]: value });
    const setDisplayedFormattedAddress = makeSetStateForProp("displayedFormattedAddress");
    const currentUser = firebase.auth().currentUser;

    const mongo_user = useQuery(GET_USER, currentUser);

    const userFromToken = useQuery(
        GET_USER_FROM_TOKEN,
        makeUserFromTokenOptions({ invite, invitation_token })
    );

    const makeVariables = uid => ({
        variables: {
            user: {
                invitation_token,
                email: state.email || currentUser?.email,
                phone_number: state.phoneNumber,
                company_name: state.companyName,
                name: {
                    first: state.firstName,
                    last: state.lastName
                },
                address: state.displayedFormattedAddress,
                uid
            }
        },
        refetchQueries: [{ query: GET_USER }],
        awaitRefetchQueries: true,
        onCompleted: _ => {
            onLogin();
        }
    });
    const variables = makeVariables(currentUser?.uid);
    const [createUser] = useMutation(CREATE_USER, variables);

    const onSignUp = uid => {
        createUser(makeVariables(uid));
    };
    console.log(state);
    return (
        <SignUpContainer>
            <Paper className={classes.paperForFields} elevation={0}>
                <Typography gutterBottom variant="h5" style={{ marginBottom: 24 }}>
                    Sign Up
                </Typography>

                {(() => {
                    switch (state.step) {
                        case 0:
                            return (
                                <BasicInfo {...{ state, setState }}>
                                    <VerifiedAddressPicker
                                        label={"Building Address / Condo Corp.*"}
                                        classes={classes}
                                        addressPredicate={address => address.province === "Ontario"}
                                        onAddress={setDisplayedFormattedAddress}
                                    />
                                </BasicInfo>
                            );
                        case 1:
                            return (
                                <SignupCredentials {...{ state, setState, setError, onSignUp }} />
                            );
                    }
                })()}
            </Paper>
        </SignUpContainer>
    );
}
