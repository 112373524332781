import { useSignUpStyles } from "./useSignUpStyles";
import React from "react";
import { VerifiedAddressPicker } from "../VerifiedAddressPicker";
import { Button, Typography, Divider } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from '@material-ui/core/MenuItem';

export default function BasicInfo({ state, setState, children, prefill, edit_page }) {
    const classes = useSignUpStyles();
    const makeSetStateForProp = prop => value => setState({ ...state, [prop]: value });
    const setFirstName = makeSetStateForProp("firstName");
    const setLastName = makeSetStateForProp("lastName");
    const setCompanyName = makeSetStateForProp("companyName");
    const setPhoneNumber = makeSetStateForProp("phoneNumber");
    const setPrice = makeSetStateForProp("price");
    const setEmployeeCount = makeSetStateForProp("employee_count");
    const setWebsite = makeSetStateForProp("website");
    const setStep = makeSetStateForProp("step");
    const setError = makeSetStateForProp("ErrorMsg");
    React.useEffect(() => setCompanyName(prefill?.getVendor?.company_name), [prefill]);

    return (
        <React.Fragment>
            <TextField
                label="Company Name*"
                variant="outlined"
                className={classes.basicInfoField}
                fullWidth
                value={prefill?.getVendor?.company_name || state.companyName}
                onChange={e => setCompanyName(e.target.value)}
                readOnly={!!prefill?.getVendor?.company_name}
                disabled={!!prefill?.getVendor?.company_name}
            />
            { !edit_page && 
            <TextField
                label="First Name*"
                className={classes.basicInfoField}
                variant="outlined"
                value={state.firstName}
                onChange={e => setFirstName(e.target.value)}
                fullWidth
            />
            }
            { !edit_page && 
            <TextField
                label="Last Name*"
                className={classes.basicInfoField}
                variant="outlined"
                fullWidth
                value={state.lastName}
                onChange={e => setLastName(e.target.value)}
            />
            }
            { !edit_page && 
            <TextField
                label="Phone Number"
                variant="outlined"
                className={classes.basicInfoField}
                fullWidth
                value={state.phoneNumber}
                onChange={e => setPhoneNumber(e.target.value)}
            />
            }
            <TextField
                select
                label="Company Size"
                variant="outlined"
                className={classes.basicInfoField}
                fullWidth
                value={state.employee_count}
                onChange={e => {setEmployeeCount(e.target.value)}}
                >
                    <MenuItem key={'1-9'} value={'1-9'}>
                    1-9
                    </MenuItem>
                    <MenuItem key={'10-49'} value={'10-49'}>
                    10-49
                    </MenuItem>
                    <MenuItem key={'50-99'} value={'50-99'}>
                    50-99
                    </MenuItem>
                    <MenuItem key={'100+'} value={'100+'}>
                    100+
                    </MenuItem>
            </TextField>

            <TextField
                label="Website"
                variant="outlined"
                className={classes.basicInfoField}
                fullWidth
                value={prefill?.getVendor?.website || state.website}
                onChange={e => setWebsite(e.target.value)}
            />

            {children}
            <Typography color="error">{state.ErrorMsg}</Typography>
            {state.step !== undefined && (
                <Button
                    fullWidth
                    variant="outlined"
                    className={classes.signUpButton}
                    onClick={() => {
                        if (state.displayedFormattedAddress?.street) {
                            setStep(1);
                        } else {
                            setError("Please verify an address");
                        }
                    }}
                >
                    Next
                </Button>
            )}
            {
                state.step !== undefined &&
            
                <div style={{ textAlign: "center" }}>
                    <Divider className={classes.orDivider} />
                    OR
                    <Divider className={classes.orDivider} />
                </div>
            }
            {
                state.step !== undefined && 
                <Button
                    fullWidth
                    variant="outlined"
                    className={classes.signUpButton}
                    onClick={() => window.location.replace('/sign-in')}
                >
                    Sign in
                </Button>
            }
        </React.Fragment>
    );
}
