import React, { useState } from "react";
import TrelloBoard from "../../../components/TrelloBoard";
import ServicePlanForm, { PRIORITIES } from "./ServicePlanForm";
import CentralizationCard from "./CentralizationCard";

const FAKE_DATA = {
    lanes: [
        {
            id: "urgent",
            title: "Urgent",
            cards: [
                {
                    id: "0",
                    title: "Window Cleaning",
                    description: "11",
                    label: "Properties",
                    draggable: true
                },
                {
                    id: "1",
                    title: "Caulking",
                    description: "6",
                    label: "Properties",
                    draggable: true
                },
                {
                    id: "2",
                    title: "Caulking",
                    description: "4",
                    label: "Properties",
                    draggable: true
                }
            ]
        },
        {
            id: "upcoming",
            title: "Upcoming",
            cards: [
                {
                    id: "4",
                    title: "Window Cleaning",
                    description: "11",
                    label: "Properties",
                    draggable: true
                },
                {
                    id: "5",
                    title: "Caulking",
                    description: "6",
                    label: "Properties",
                    draggable: true
                },
                {
                    id: "6",
                    title: "Caulking",
                    description: "4",
                    label: "Properties",
                    draggable: true
                }
            ]
        },
        {
            id: "wishlist",
            title: "Wishlist",
            cards: [
                {
                    id: "7",
                    title: "Window Cleaning",
                    description: "11",
                    label: "Properties",
                    draggable: true
                },
                {
                    id: "8",
                    title: "Caulking",
                    description: "6",
                    label: "Properties",
                    draggable: true
                }
            ]
        }
    ]
};

export default function CentralizationBoard() {
    const updateBoardData = newBoardData => {
        // DATA STITCH
        // update centralization board data
        console.log(`👨‍🌾 => `, newBoardData);
    };

    return (
        <TrelloBoard
            {...{
                data: FAKE_DATA,
                updateData: updateBoardData,
                CardForm: ServicePlanForm,
                CustomCard: CentralizationCard,
                draggable: true,
                getFormDataFromId: ({ laneId, cardId }) => {
                    // lane ID translates to the priority level
                    const { key: priority } = PRIORITIES.find(priority => priority.key === laneId);
                    // DATA STITCH
                    // use card ID to grab data from server on all fields
                    console.log(`👨‍🌾 => `, laneId);
                    console.log(`👨‍🌾 => `, cardId);
                    const initialData = { priority };
                    return initialData;
                },
                onSubmitForm: ({ formData }) => {
                    // DATA STITCH
                    // on form submission send data to server
                    console.log(`👨‍🌾 => `, formData);
                }
            }}
        />
    );
}
