import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Container } from "@material-ui/core";
import ThreatsBoard from "./ThreatsBoard";
import NumberWithLabel from "../../../components/NumberWithLabel";
import GridItem from "../../../components/GridItem";
import PieChart from "../../../components/PieChart";
import BarChart from "../../../components/BarChart";
import CardTitle from "../../../components/CardTitle";

const FAKE_DATA = {
    internalQuality: "4.0",
    servicesThreshold: "3",
    ratingThreshold: "2.0",
    threatAllocation: [
        {
            name: "Vendors",
            value: 2400
        },
        {
            name: "Properties",
            value: 4567
        },
        {
            name: "PM",
            value: 1398
        },
        {
            name: "Resolved",
            value: 9800
        }
    ],
    threatHistory: [
        {
            name: "Jan",
            Vendor: 4000,
            Property: 2400,
            Average: 2400
        },
        {
            name: "Feb",
            Vendor: 3000,
            Property: 1398,
            Average: 2210
        },
        {
            name: "Mar",
            Vendor: 2000,
            Property: 9800,
            Average: 2290
        },
        {
            name: "Apr",
            Vendor: 2780,
            Property: 3908,
            Average: 2000
        },
        {
            name: "May",
            Vendor: 1890,
            Property: 4800,
            Average: 2181
        },
        {
            name: "Jun",
            Vendor: 2390,
            Property: 3800,
            Average: 2500
        },
        {
            name: "Jul",
            Vendor: 3490,
            Property: 4300,
            Average: 2100
        }
    ]
};

const BARS = [
    { key: "Vendor", fill: "#EFC255" },
    { key: "Property", fill: "#385FF6" }
];
const LINES = [{ key: "Average", fill: "#845CF6" }];

export default function Threats() {
    return (
        <Box>
            <h1>Threats and Weaknesses</h1>
            <Box p={2} justifyContent="center" display="flex" flex={1}>
                <ThreatsBoard />
            </Box>
            <Container>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    spacing={2}
                >
                    <GridItem xs={4}>
                        <NumberWithLabel
                            value={FAKE_DATA.internalQuality}
                            labelColor="red"
                            primaryLabel="Internal Quality Threshold"
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <NumberWithLabel
                            value={FAKE_DATA.servicesThreshold}
                            labelColor="red"
                            primaryLabel="Poor Services/Property Threshold"
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <NumberWithLabel
                            value={FAKE_DATA.ratingThreshold}
                            labelColor="red"
                            primaryLabel="Industry:Internal Rating Threshold"
                        />
                    </GridItem>
                </Grid>
            </Container>
            <Box pt={3} p={2}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    spacing={2}
                >
                    <GridItem xs={6}>
                        <Box pb={3}>
                            <CardTitle>THREAT ALLOCATION</CardTitle>
                        </Box>
                        <PieChart data={FAKE_DATA.threatAllocation} />
                    </GridItem>
                    <GridItem xs={6}>
                        <Box pb={3}>
                            <CardTitle>THREATS: SERVICE VS PROPERTY VS PM</CardTitle>
                        </Box>
                        <BarChart data={FAKE_DATA.threatHistory} bars={BARS} lines={LINES} />
                    </GridItem>
                </Grid>
            </Box>
        </Box>
    );
}
