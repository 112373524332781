import { TextField, Tooltip, Typography } from "@material-ui/core";
import { asReadableString, getFormattedAddress } from "../lib/address";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

export function VerifiedAddressPicker(props) {
    const classes = useStyles();
    const prefillAddress = props?.prefill?.getVendor?.address || props.address || {};

    // TODO Replicate this elsewhere
    const reconstituded_address = prefillAddress?.street_number
        ? `${prefillAddress?.street_number} ${prefillAddress?.street}, ${prefillAddress?.city}`
        : "";

    const [address, setAddress] = React.useState(reconstituded_address);
    const [verifiedAddress, setVerifiedAddress] = React.useState(!! props.address);
    const [displayedFormattedAddress, setDfa] = React.useState(props.address || {});
    const setDisplayFormattedAddress = dfa => {
        setDfa(dfa);
        props.onAddress(dfa);
    };
    React.useEffect(() => {if(verifiedAddress) props.onAddress(displayedFormattedAddress)}, [verifiedAddress]);
    React.useEffect(() => {
        (async function() {
            try {
                if (address.length < 4) {
                    // TODO what's going on here, I'm leaving it (sortta debouncing)
                    setDisplayFormattedAddress({});
                    return () => {};
                }

                const formattedAddress = await getFormattedAddress(address);
                if (formattedAddress == null) return;
                if (!props.addressPredicate(formattedAddress)) return;
                setDisplayFormattedAddress(formattedAddress);
            } catch (e) {
                console.log(e);
            }
        })();
    }, [address]);

    return (
        <React.Fragment>
            {!verifiedAddress && (
                <Tooltip
                    title={
                        <div>
                            <Typography>{asReadableString(displayedFormattedAddress)}</Typography>
                            <div style={{ textAlign: "center" }}>
                                <Typography variant="subtitle2" style={{ marginTop: 8 }}>
                                    Is this your address?
                                    <FiCheckCircle
                                        strokeWidth={3}
                                        className={classes.confirmAddress}
                                        size={16}
                                        onClick={() => setVerifiedAddress(true)}
                                    />
                                    <FiXCircle
                                        strokeWidth={3}
                                        onClick={() => setDisplayFormattedAddress({})}
                                        className={classes.confirmAddress}
                                        size={16}
                                    />
                                </Typography>
                            </div>
                        </div>
                    }
                    arrow
                    open={displayedFormattedAddress.hasOwnProperty("postal_code")}
                    interactive
                    placement="bottom"
                >
                    <TextField
                        label={props.label}
                        variant="outlined"
                        className={classes.basicInfoField}
                        fullWidth
                        value={address}
                        error={!!address && true}
                        onChange={e => setAddress(e.target.value)}
                    />
                </Tooltip>
            )}

            {verifiedAddress && (
                <div style={{ textAlign: "left", margin: 4 }}>
                    <div className={classes.verifiedAddressDisplay}>
                        <Typography variant="subtitle2">Address:</Typography>
                        <Typography
                            onClick={() => {
                                setVerifiedAddress(false);
                                setDisplayFormattedAddress({});
                            }}
                            variant="caption"
                        >
                            edit
                        </Typography>
                    </div>

                    <Typography gutterBottom>
                        {asReadableString(displayedFormattedAddress)}
                    </Typography>
                </div>
            )}
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    verifiedAddressDisplay: {
        display: "flex",
            justifyContent: "space-between",
            "& span": {
            cursor: "pointer",
                "&:hover": {
                color: "rgba(0,0,0,0.4)",
            },
        },
    },

    confirmAddress: {
        verticalAlign: "middle",
        marginLeft: 8,
        marginBottom: 4,
        cursor: "pointer",
        marginTop: 4,
        "&:hover": {
            color: "rgba(255,255,255, 0.5)",
        },
    },
}));
