import React from "react";
import { Typography }  from '@material-ui/core';



export function Associations({association_objs}) {

    return (
        association_objs !== 0 &&
        <div>
            <Typography variant="h6">
                Associations
            </Typography>
            {
                association_objs.includes("boma") &&
                <img src="/logo-boma.jpg" alt="BOMA" />
            }
            {
                association_objs.includes("acmo") &&
                <img src="/logo-acmo.jpg" alt="ACMO" />
            }
            {
                association_objs.includes("contractorWorks") &&
                <img src="/logo-contractorcheck.jpg" alt="contractorcheck" />
            }
            {
                association_objs.includes("complyworks") &&
                <img src="/logo-complyworks.jpg" alt="complyworks" />
            }
        </div>
    )
} 