import { Typography } from "@material-ui/core";
import React from "react";

export default function ContactInfoBox({ user }) {
    return (
        user && (
            <React.Fragment>
                <Typography variant="body1">
                    Name: {user.name.first} {user.name.last}
                </Typography>
                {user.phone_number && (
                    <Typography variant="body1">Phone: {user.phone_number}</Typography>
                )}

                <Typography variant="body1">Email: {user.email}</Typography>
            </React.Fragment>
        )
    );
}
