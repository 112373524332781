import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles(theme => ({
    paper: {
        width: "100%",
        padding: 40,
        marginTop: 40,
    },
}));

export default function ErrorMessage({ message }) {
    const classes = useStyles();
    return (
        <Container>
            <Paper className={classes.paper}>
                <Typography variant="h5" color="error">
                    Error
                </Typography>
                <Typography color="error">
                    <pre>
                        {message instanceof String
                            ? message
                            : JSON.stringify(message, null, 4)}
                    </pre>
                </Typography>
            </Paper>
        </Container>
    );
}
