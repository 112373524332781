import Autocomplete from "@material-ui/lab/Autocomplete";
import { listOfServices } from "../lib/serviceSearch";
import { TextField, Tooltip } from "@material-ui/core";
import React from "react";

// Was being used in vendor search sidebar
// Isn't used anywhere anymore
// might be usefule later?

export function ServiceMultiPicker({
    services = [],
    setServices,
    findOnly,
    multiple = true,
    ...rest
}) {
    console.log(services);
    return (
        <Autocomplete
            multiple={multiple}
            freeSolo={!findOnly}
            id="services-autocomplete"
            autoHighlight
            options={listOfServices}
            style={{ marginBottom: 16 }}
            getOptionLabel={option => option}
            closeIcon={<div style={{ position: "absolute" }}></div>}
            filterSelectedOptions
            onChange={(e, value) => setServices(value)}
            value={services || []}
            defaultValue={services || []}
            renderInput={params => {
                if (findOnly)
                    return (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={`Service${multiple ? "s" : ""}*`}
                            fullWidth
                            {...rest}
                        />
                    );

                return (
                    <Tooltip
                        title={
                            "No vendors have listed this service yet, press enter to be the first."
                        }
                        arrow
                        open={
                            !listOfServices.filter(option =>
                                // @ts-ignore TODO figure this out
                                new RegExp(params.inputProps.value, "i").test(option)
                            ).length
                        }
                        interactive
                        placement="bottom"
                    >
                        <TextField
                            {...params}
                            variant="outlined"
                            label={`Service${multiple ? "s" : ""}*`}
                            fullWidth
                            {...rest}
                        />
                    </Tooltip>
                );
            }}
        />
    );
}
