import {
    Button,
    Divider,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    Hidden,
    Typography,
    ListSubheader,
    Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";
import { AssociationPicker } from "../../../components/AssociationPicker";
import uglyFeatureFlags from "../../../lib/uglyFeatureFlags";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(2),
        width: "calc(100% - 32px)"
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    toggleContainer: {
        marginLeft: 16
    },
    goddamnLinks: {
        color: theme.palette.primary.light,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    sidebarContainer: {
        borderRadius: 4,
        borderStyle: "solid",
        marginTop: 64,
        marginLeft: 16,
        marginRight: 16,
        borderWidth: 1,
        borderColor: "#eee",
        "@media (max-width: 600px)": {
            display: "none"
        },
        marginBottom: 16,
        "& h5": {
            fontWeight: 600
        },
        "@media (max-width: 960px)": {
            "& h5": {
                fontWeight: 400,
                marginBottom: 32
            },
            marginBottom: 0,
            borderStyle: "none",
            padding: 0,
            marginLeft: 16,
            marginTop: 24
        }
    },

    list: {
        marginLeft: 16,
        position: "sticky",
        top: 0,
        zIndex: 1000,
        minHeight: 64,
        paddingTop: 0,
        paddingBottom: 16,
        "@media (max-width: 960px)": {
            position: "fixed",
            paddingLeft: 64,
            bottom: 0,
            left: 0,
            right: 0,
            top: "auto",
            boxShadow: [
                "0 0 4px -1px rgba(80,80,80,0.2)",
                "0 0 5px 6px rgba(80,80,80,0.07)",
                "0 0 5px 0px rgba(80,80,80,0.09)"
            ].join(", "),
            borderStyle: "none",
            margin: 0,
            background: "rgba(255,255,255,1)"
        },
        "@media (max-width: 600px)": {
            paddingLeft: 32,
            "& li": {
                height: 32,
                padding: 0,
                margin: 0
            }
        }
    }
}));

function SortByPicker(props) {
    const classes = useStyles();
    const options = ["Distance", "Rating", "Best Match"];

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    const [state, setState] = React.useState("Best Match");

    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const handleChange = event => {
        setState(event.target.value);
    };

    return (
        <FormControl variant="outlined" className={classes.formControl}>
            {
                <InputLabel ref={inputLabel} htmlFor="outlined-age-native-simple">
                    Sort By
                </InputLabel>
            }
            <Select
                native
                value={state}
                onChange={handleChange}
                labelWidth={labelWidth}
                inputProps={{
                    id: "outlined-age-native-simple"
                }}
            >
                {options.map(o => (
                    <option key={o} value={o}>
                        {o}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
}

export default function VendorSearchControls({
    pickedVendors,
    service,
    vendorListLength,
    loading,
    viewingByService,
    searchText
}) {
    const classes = useStyles();

    const [associations, setAssociations] = React.useState([]);
    const association_picker = uglyFeatureFlags.AssociationPicker && (
        <div className={classes.formControl}>
            <AssociationPicker
                classes={classes}
                associations={associations}
                setAssociations={setAssociations}
            />
        </div>
    );

    return (
        <List
            className={classes.list}
            subheader={
                <ListSubheader disableSticky style={{ color: "black" }}>
                    {viewingByService && !!vendorListLength && "Request Quotes"}
                </ListSubheader>
            }
        >
            {/* <SortByPicker /> */}
            {/* {association_picker} */}
            {viewingByService && !!vendorListLength && (
                <React.Fragment>
                    <List dense>
                        {uglyFeatureFlags.requireThreeVendorSelectionsForQuoteRequests && (
                            <ListItem
                                style={{
                                    marginTop: pickedVendors.length ? -8 : 0,
                                    marginBottom: pickedVendors.length ? 12 : 0
                                }}
                                divider={!!pickedVendors.length}
                            >
                                <ListItemText
                                    primaryTypographyProps={{
                                        variant: "subtitle2",
                                        color: "textSecondary"
                                    }}
                                    style={{ height: 40, paddingBottom: 8 }}
                                >
                                    "Select Three Or More To Get Started"
                                    {(pickedVendors.length &&
                                        ` (${pickedVendors.length} selected)`) ||
                                        null}
                                </ListItemText>
                            </ListItem>
                        )}
                        <Hidden smDown>
                            {pickedVendors.map(v => (
                                <ListItem key={v._id}>
                                    <ListItemText
                                        inset
                                        primaryTypographyProps={{
                                            variant: "subtitle2"
                                        }}
                                    >
                                        {v.company_name}
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </Hidden>
                    </List>
                    <ListItem>
                        <Link
                            to={
                                service !== "undefined"
                                    ? `/request-quotes/${encodeURIComponent(service)}`
                                    : "/"
                            }
                            style={{ textDecoration: "none" }}
                            onClick={e => {
                                if (
                                    uglyFeatureFlags.requireThreeVendorSelectionsForQuoteRequests
                                        ? pickedVendors.length < 3
                                        : pickedVendors.length < 1
                                )
                                    e.preventDefault();
                            }}
                        >
                            <Button
                                disabled={pickedVendors.length < 3}
                                style={{
                                    transition: "0.15s",
                                    color: uglyFeatureFlags.requireThreeVendorSelectionsForQuoteRequests
                                        ? pickedVendors.length < 3
                                            ? "#ccc"
                                            : "#0073bb"
                                        : pickedVendors.length < 1
                                        ? "#ccc"
                                        : "#0073bb",
                                    borderColor: uglyFeatureFlags.requireThreeVendorSelectionsForQuoteRequests
                                        ? pickedVendors.length < 3
                                            ? "#ccc"
                                            : "#0073bb"
                                        : pickedVendors.length < 1
                                        ? "#ccc"
                                        : "#0073bb"
                                }}
                                onClick={e => {
                                    if (
                                        uglyFeatureFlags.requireThreeVendorSelectionsForQuoteRequests
                                            ? pickedVendors.length < 3
                                            : pickedVendors.length < 1
                                    )
                                        e.preventDefault();
                                }}
                                variant="outlined"
                            >
                                Get Quotes
                            </Button>
                        </Link>
                    </ListItem>
                </React.Fragment>
            )}
            <div
                className={classes.sidebarContainer}
                style={{
                    marginTop: !viewingByService || !!vendorListLength ? 16 : null,
                    transform:
                        viewingByService && !!vendorListLength && loading ? "scale(1.5)" : null
                }}
            >
                <ListSubheader
                    style={{
                        height: 40,
                        marginTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 16,
                        color: "rgba(0,0,0,0.87)",
                        paddingRight: 0
                    }}
                >
                    Can't find your company?
                </ListSubheader>
                <Link to={`/vendor-sign-up`} className={classes.goddamnLinks}>
                    <ListItem
                        style={{
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 0,
                            paddingBottom: 0
                        }}
                    >
                        <ListItemText style={{ marginTop: 0 }}>
                            Register your company for free
                        </ListItemText>
                    </ListItem>
                </Link>
                { uglyFeatureFlags.pmsCanAddVendor && <Link
                    to={`/vendor-profile/${searchText || " "}/review/new`}
                    className={classes.goddamnLinks}
                >
                    <ListItem
                        style={{
                            marginTop: 0,
                            marginBottom: 4,
                            paddingTop: 0
                        }}
                    >
                        <ListItemText style={{ marginTop: 0 }}>Add a new company</ListItemText>
                    </ListItem>
                </Link>}
            </div>
            {/* <Hidden smDown>
                <Divider style={{ margin: "8px 20px" }} />
                <Typography variant="subtitle2" style={{ margin: "8px 28px" }}>
                    Can't find your preferred vendor? Invite them to sign up!
                </Typography>
            </Hidden> */}
        </List>
    );
}

// <Paper
//     elevation={0}
//     style={{ width: "100%", marginTop: 8, border: "rgba(0,0,0,0.2)" }}
// >

// </Paper>
