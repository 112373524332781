import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Switch, FormControl, FormGroup } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    filterContainer: {
        width: 400,
        backgroundColor: "white"
    },
    formMargin: {
        margin: theme.spacing(3)
    },
    complianceLogo: {
        height: 40
    }
}));

const FILTERS = [
    {
        key: "boma",
        label: "Boma Toronto",
        imgUrl: "/boma_logo.png"
    },
    {
        key: "acmo",
        label: "ACMO",
        imgUrl: "/acmo_logo.png"
    },
    {
        key: "contractor",
        label: "Contractor Check",
        imgUrl: "/cc_logo.png"
    },
    {
        key: "complyworks",
        label: "Complyworks",
        imgUrl: "/cw_logo.png"
    }
];

export default function ComplianceFilter() {
    const initialFormState = FILTERS.map(({ key }) => ({
        [key]: false
    })).reduce((prevVal, currVal) => ({ ...prevVal, ...currVal }));
    const [form, setState] = useState(initialFormState);

    const onSubmit = e => {
        e.preventDefault();
        // DATA STITCH
        // send form values to refetch query on Preferred Vendors page with filters
        console.log(form);
    };

    const handleChange = name => event => {
        setState({ ...form, [name]: event.target.checked });
    };

    const classes = useStyles();

    return (
        <Box className={classes.filterContainer} m={3} p={1} boxShadow={2}>
            <Box m={3}>
                <Typography variant="subtitle2">Compliance</Typography>
            </Box>
            <form onSubmit={onSubmit} className={classes.formMargin}>
                <FormGroup>
                    {FILTERS.map(({ key, label, imgUrl }) => (
                        <Box
                            p={2}
                            flexDirection="row"
                            display="flex"
                            justifyContent="space-between"
                        >
                            <img src={imgUrl} alt="" className={classes.complianceLogo} />
                            <Switch checked={form[key]} onChange={handleChange(key)} value={key} />
                        </Box>
                    ))}
                </FormGroup>
                <Box mt={4} justifyContent="center" display="flex">
                    <Button variant="outlined" label="Submit" type="submit">
                        Search
                    </Button>
                </Box>
            </form>
        </Box>
    );
}
