import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles(theme => ({
    grid: {
        width: "100%"
    }
}));

export default function NumberWithLabel({
    value,
    primaryLabel,
    secondaryLabel,
    onClick,
    labelColor,
    defaultValue = 0
}) {
    const classes = useStyles();
    const number = `${value || defaultValue}`;
    const label = `${primaryLabel ? ` ${primaryLabel}` : ""}`;

    return (
        <Grid container justify="space-between" spacing={1} className={classes.grid}>
            <Grid item>
                <Typography component="div">
                    <Box fontSize="h3.fontSize" color={labelColor} lineHeight={1}>
                        {number}
                    </Box>
                    <Box fontSize="h4.fontSize" color="gray" lineHeight={1}>
                        {label}
                    </Box>
                    {secondaryLabel && (
                        <Box fontSize={16} color="gray">
                            {secondaryLabel}
                        </Box>
                    )}
                </Typography>
            </Grid>
            {onClick && (
                <Grid item>
                    <Fab color="primary" onClick={onClick}>
                        <AddIcon />
                    </Fab>
                </Grid>
            )}
        </Grid>
    );
}
