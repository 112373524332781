import gql from "graphql-tag";

export const SIGN_IN = gql`
    mutation signIn {
        signIn
    }
`;

export const SELECT_VENDOR = gql`
    mutation selectVendor($_id: ID) {
        selectVendor(_id: $_id) @client
    }
`;

export const CLEAR_SELECTED_VENDORS = gql`
    mutation clearSelectedVendors {
        clearSelectedVendors @client
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($user: ID!) {
        deleteUser(user: $user)
    }
`;

export const CREATE_USER = gql`
    mutation createUser($user: UserInput!) {
        createUser(user: $user)
    }
`;

export const CREATE_USER_WITH_VENDOR = gql`
    mutation createUserWithVendor($user: UserInput!, $vendor: VendorInput!) {
        createUserWithVendor(user: $user, vendor: $vendor)
    }
`;

export const CREATE_USER_CLAIM_VENDOR = gql`
    mutation createUserClaimVendor($user: UserInput!, $vendor_id: ID!) {
        createUserClaimVendor(user: $user, vendor_id: $vendor_id)
    }
`;

export const UPDATE_VENDOR = gql`
    mutation updateVendor($vendor: VendorInput!) {
        updateVendor(vendor: $vendor)
    }
`;

export const ADMIN_DELETE_VENDOR = gql`
    mutation adminDeleteVendor ($vendor: ID!) {
        adminDeleteVendor (vendor: $vendor)
    }
`;

export const ADMIN_DELETE_REVIEW = gql`
    mutation adminDeleteReview ($review: ID!) {
        adminDeleteReview (review: $review)
    }
`;

export const CREATE_RFQ = gql`
    mutation createRFQ($rfq: RFQInput!) {
        createRFQ(rfq: $rfq) {
            _id
            service
            submission_date
            deadline_date
            scope_of_work
            notes
            priority
            attachments
            pm {
                email
                email_verified
            }
            vendors {
                _id
                company_name
            }
            pm_email
            pm_phone_number
            pm_name
        }
    }
`;

export const CLOSE_RFQ = gql`
    mutation closeRFQ($rfq: RFQUpdateInput!) {
        updateRFQ(rfq: $rfq)
    }
`;

export const DELETE_RFQ = gql`
    mutation deleteRFQ($rfq: ID!) {
        deleteRFQ(rfq: $rfq)
    }
`;

export const RESPOND_TO_RFQ = gql`
    mutation respondToRFQ($_id: String!, $response: String!) {
        respondToRFQ(_id: $_id, response: $response)
    }
`;

export const CREATE_REVIEW = gql`
    mutation createReview($review: ReviewInput!) {
        createReview(review: $review) {
            _id
            company_name
            reviews_objs {
                _id
                rating
                created_date
                anonymous
                description
                reviewer_obj {
                    _id
                    email
                    reviews {
                        _id
                    }
                    name {
                        first
                        last
                    }
                    photo_url
                }
            }
        }
    }
`;

export const UPDATE_RFQ = gql`
    mutation updateRFQ($rfq: RFQUpdateInput!) {
        updateRFQ(rfq: $rfq)
    }
`;

export const UPDATE_USER = gql`
    mutation updateUser($user: UserUpdateInput!) {
        updateUser(user: $user)
    }
`;

export const SEND_INVITE = gql`
    mutation sendInvitation($invitee_email: String!, $invitee_phone: String!) {
        sendInvitation(
            invitee_email: $invitee_email
            invitee_phone: $invitee_phone
        )
    }
`;
