import React, { useState } from "react";
import Board from "react-trello";
import { Button, Container, makeStyles, Typography, Backdrop } from "@material-ui/core";
import RFQDialog from "./RFQDialog";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { CREATE_RFQ, UPDATE_RFQ } from "../../lib/apollo/mutations";
import { GET_USER } from "../../lib/apollo/queries";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/Error";
import Fab from "@material-ui/core/Fab";
import { Redirect } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import theme from "../../lib/theme";
import CustomCard from "./CustomCard";
import { useParams } from "react-router-dom";
import { IoIosCheckboxOutline, IoIosCheckbox } from "react-icons/io";
import { FiArrowRight } from "react-icons/fi";
import { login } from "../../lib/apollo";
import Link from "@material-ui/core/Link";
import AddCardLink from "react-trello/dist/components/AddCardLink";
import NewCardForm from "react-trello/dist/components/NewCardForm";

const subheading = ["(Immediate)", "(1-3 Months)", "(3 Months+)"];

export default function ServicePlanner() {
    const priorities = ["Urgent", "Upcoming", "Wishlist", "Requested", "Completed"];
    // const [cardSets, setCardSets] = React.useState([[0, 1, 2], [3, 4, 5], [6, 7, 8], [9, 10]]);
    const [openCard, setOpenCard] = React.useState(null);
    const [rfqs, setRfqs] = React.useState([]);
    const [newRFQPriority, setNewRFQPriority] = React.useState(false);
    const [cardSets, setCardSets] = React.useState([[], [], [], []]);
    const [X, setX] = useState(false);
    if (!X) {
        setTimeout(() => {
            setX(true);
            setNewRFQPriority(false);
        }, 23000);
    }

    const { param1 } = useParams();
    const getStartedMsg = param1 === "get-started";

    const { data, loading, error } = useQuery(GET_USER, {});
    const [updateRFQ] = useMutation(UPDATE_RFQ, {
        refetchQueries: [
            {
                query: GET_USER,
            },
        ],
    });
    React.useEffect(
        () => setRfqs(data && data.getUser && data.getUser.rfqs ? data.getUser.rfqs : []),
        [data]
    );
    React.useEffect(
        () =>
            setCardSets(
                priorities.map(p =>
                    rfqs.filter(
                        rfq =>
                            (rfq.closed && p === "Completed") || (!rfq.closed && rfq.priority === p)
                    )
                )
            ),
        [rfqs]
    );

    if (loading) return <Loading />;
    if (error) return <ErrorMessage message={error} />;
    if (!(data && data.getUser)) return <Redirect to="/" />;
    if (data?.getUser.is_vendor) return <Redirect to="/your-quotes" />;

    const onCardMoveAcrossLanes = (fromLaneId, toLaneId, cardId, index) => {
        // TODO Update updateRFQ({priority: priorities[toLaneId]}, cardId)
        const rfq = rfqs.filter(rfq => rfq._id === cardId)[0];
        updateRFQ({
            variables: {
                rfq: { _id: rfq._id, priority: priorities[toLaneId] },
            },
        });
        setRfqs(
            rfqs.map(r => {
                if (r._id === rfq._id) r.priority = priorities[toLaneId];
                return r;
            })
        );
    };

    function card(laneId, rfq) {
        return {
            id: rfq._id,
            title: rfq.service,
            description: rfq.scope_of_work,
            label:
                laneId === 3 ? (
                    <IoIosCheckbox
                        size={20}
                        style={{ float: "right" }}
                        onClick={e => {
                            onCardMoveAcrossLanes(laneId, 2, rfq._id, 0);
                            e.stopPropagation();
                        }}
                    />
                ) : (
                    <IoIosCheckboxOutline
                        size={20}
                        style={{ float: "right" }}
                        onClick={e => {
                            onCardMoveAcrossLanes(laneId, 3, rfq._id, 0);
                            e.stopPropagation();
                        }}
                    />
                ),
            draggable: true,
            metadata: rfq,
        };
    }

    const lanes = cardSets.map((cardIds, i) => {
        const label = priorities[i];
        const cards = cardIds.map(cardId => card(i, cardId));

        return {
            id: String(i),
            title: label,
            label: subheading[i] ? subheading[i] : "",
            draggable: true,
            cards,
            style: {
                width: "25%",
                background: "transparent",
                fontFamily: theme.typography.h6.fontFamily,
                // minHeight: 600,
                paddingBottom: 32,
                borderWidth: 1,
                borderColor: "#eee",
                borderStyle: "solid",
                borderRadius: 0,
            },
            cardStyle: {
                fontFamily: theme.typography.body1.fontFamily,
                minHeight: 64,
                borderRadius: 0,
                boxShadow: "0 1px 7px rgba(0,0,0,0.08)",
                backgroundColor: "#fff",
                borderLeftStyle: "solid",
                borderLeftWidth: 4,
                borderLeftColor: "#9c27b0",
                borderBottom: "",
            },
        };
    });
    const closeDialogs = () => {
        console.log("closing");
        setNewRFQPriority(false);
        setOpenCard(null);
    };

    const boardData = {
        lanes,
        //disallowAddingCard: false,
    };
    return (
        <Container maxWidth="lg">
            {getStartedMsg && (
                <Backdrop
                    style={{
                        zIndex: theme.zIndex.drawer + 1,
                        color: "#fff",
                    }}
                    open={!X}
                    handleClose={() => {
                        setX(true);
                    }}
                >
                    <Container maxWidth="lg" style={{ position: "relative" }}>
                        <Typography
                            variant="h6"
                            style={{
                                marginTop: 72,
                                marginBottom: 56,
                                marginLeft: 56,
                                marginRight: 56,
                                textShadow: "1px 1px 8px rgba(0,0,0,0.5)",
                                textAlign: "center",
                                fontWeight: 500,
                            }}
                        >
                            One last step to complete your profile, be proactive with your
                            personalized service planner.
                            <br />
                            Plan ahead for which services you need ungently, upcoming and on a
                            wish-list. <br />
                            We will proactively seek out the best vendors at any given time for you.
                        </Typography>
                        <Typography
                            variant="body1"
                            style={{
                                position: "fixed",
                                bottom: 96,
                                marginLeft: 32,
                                textShadow: "1px 1px 8px rgba(0,0,0,0.5)",
                                right: 148,
                            }}
                        >
                            Add a service by clicking this floating button
                            <FiArrowRight
                                size={24}
                                style={{
                                    verticalAlign: "middle",
                                    marginLeft: 8,
                                }}
                            />
                        </Typography>
                    </Container>
                </Backdrop>
            )}
            <RFQDialog
                open={!!newRFQPriority}
                closeDialog={closeDialogs}
                rfqIn={{
                    service: "",
                    priority: newRFQPriority,
                }}
                creatingNew
                onSave={newRfq => {
                    setRfqs([...rfqs, newRfq]);
                }}
            />
            {openCard !== null && (
                <RFQDialog
                    open={!!openCard}
                    closeDialog={closeDialogs}
                    rfqIn={openCard}
                    onSave={updatedRfq => {
                        setRfqs(
                            rfqs.map(r => {
                                return r._id === updatedRfq._id ? updatedRfq : r;
                            })
                        );
                    }}
                />
            )}
            <Board
                data={boardData}
                style={{
                    backgroundColor: "rgba(0,0,0,0)",
                    marginTop: 32,
                    height: "calc(100vh - 128px)",
                    minHeight: 600,
                }}
                onCardMoveAcrossLanes={onCardMoveAcrossLanes}
                onCardClick={(cardId, metadata, laneId) => setOpenCard(metadata)}
                draggable={true}
                laneDraggable={false}
                editable={true}
                hideCardDeleteIcon={true}
                onCardAdd={(...x) => {}}
                components={{
                    Card: CustomCard,
                    AddCardLink: props => {
                        return (
                            <Link onClick={props.onClick} style={{ cursor: "pointer" }}>
                                Add item
                            </Link>
                        );
                    },
                    NewCardForm: props => {
                        setNewRFQPriority(priorities[parseInt(props.laneId)]);
                        props.onCancel();
                        return <React.Fragment />;
                    },
                }}
            />
            <Fab
                color="primary"
                aria-label="add"
                style={{
                    position: "fixed",
                    bottom: 80,
                    right: 80,
                    zIndex: theme.zIndex.drawer + 2,
                }}
                onClick={() => {
                    setNewRFQPriority("Upcoming");
                    setX(true);
                }}
            >
                <AddIcon />
            </Fab>
        </Container>
    );
}
