import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { VerifiedAddressPicker } from "../../components/VerifiedAddressPicker";
import { useSignUpStyles } from "../../components/sign_up/useSignUpStyles";

export default function BuildingInformationForm({
    service_id,
    address,
    firstName,
    lastName,
    phone_number,
    email
}) {
    const [localAddress, setLocalAddress] = React.useState("");
    const [displayedFormattedAddress, setDisplayedFormattedAddress] = React.useState(address);
    const [verifiedAddress, setVerifiedAddress] = React.useState(address !== null);
    const addressContext = {
        selectedButton: 1,
        address: localAddress,
        setAddress: setLocalAddress,
        displayedFormattedAddress,
        setDisplayedFormattedAddress,
        verifiedAddress,
        setVerifiedAddress
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label="First name"
                        fullWidth
                        autoComplete="fname"
                        value={firstName}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="lastName"
                        name="lastName"
                        label="Last name"
                        fullWidth
                        autoComplete="lname"
                        value={lastName}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="phone_number"
                        name="phone_number"
                        label="Phone number"
                        fullWidth
                        value={phone_number}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="email"
                        name="email"
                        label="Email address"
                        fullWidth
                        value={email}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
