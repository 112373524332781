import React, { Component } from 'react'
import { useHistory } from "react-router-dom";
//

function HubSpotListener ({children}){
    const history = useHistory();

    const sendPageView = (location) => {
        var _hsq = window._hsq = window._hsq || [];
        _hsq.push(['setPath', location.pathname]);
        _hsq.push(['trackPageView']);
    };

    history.listen(sendPageView);

    return children
}

export default HubSpotListener