import { useHistory } from "react-router-dom";
import React from "react";
import { useMutation } from "react-apollo";
import { SELECT_VENDOR } from "../../../lib/apollo/mutations";
import ServicePickerModal from "../../../components/ServicePickerModal";
import VendorListItem from "../../../components/VendorListItem";
import credentialLevel from "../../../lib/credentialLevel";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { EditVendorProfilePane } from "../../../components/EditVendorProfilePane";

export function AllSearchVendorListItem(props) {
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [pickedService, setPickedService] = React.useState(null);

    const [selectVendor] = useMutation(SELECT_VENDOR, {
        variables: { _id: props.item._id },
        onCompleted: () => {
            history.push(`/vendors-of/${encodeURIComponent(pickedService)}`);
        }
    });

    const services = props.item.services || [];
    return (
        <React.Fragment>
            <ServicePickerModal
                {...{ open, setOpen, services }}
                onServicePick={service => {
                    setOpen(false);
                    selectVendor();
                    setPickedService(service);
                }}
            />
            <EditVendorModal
                open={editOpen}
                onClose={() => setEditOpen(false)}
                vendor={props.item}
                refetchQueries={props.refetchQueries}
            />
            <VendorListItem
                {...props}
                actions={{
                    "Get Quote": () => setOpen(true),
                    ...(credentialLevel() === "admin" ? { Edit: () => setEditOpen(true) } : {})
                }}
            />
        </React.Fragment>
    );
}

function EditVendorModal({ vendor, refetchQueries, ...props }) {
    return (
        <Dialog fullWidth maxWidth="md" {...props} aria-labelledby="max-width-dialog-title">
            <DialogTitle id="max-width-dialog-title">
                <Grid container justify="space-between">
                    <Grid item>Admin Vendor Editing</Grid>
                    <Grid item>
                        <Button onClick={props.onClose} color="primary">
                            Close
                        </Button>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <EditVendorProfilePane
                    onCompleted={props.onClose}
                    {...{ vendor, refetchQueries }}
                />
            </DialogContent>
        </Dialog>
    );
}
