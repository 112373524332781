import React, { useState } from 'react'
import { GET_ALL_RFQS } from '../lib/apollo/queries'
import { useQuery } from 'react-apollo'
import Loading from '../components/Loading'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useStyles } from '../styles/Settings'
// import DeleteVendorButton from './DeleteVendorButton'

export default function RFQPanel() {
    const [searchText, setSearchText] = useState('');
    const classes = useStyles();
    const keyPress = (e) => { if (e.keyCode == 13) setSearchText(e.target.value) }
    // const resetSearch = () => { setSearchText('') }

    const { loading, data: all_rfqs, error } = useQuery(GET_ALL_RFQS);
    if (loading) return <Loading />
    if (error) return error.toString()

    return (
        <React.Fragment>
        <TextField id='searchBar' className={classes.textField} onKeyDown={keyPress} label="Search RFQs (case-sensitive)" variant="outlined" fullWidth/>
        {/* <Button onClick={() => resetSearch} color="primary">Reset</Button> */}

        <table>
        <thead>
            <tr>
            <th>Service</th>
            <th>Vendors</th>
            <th>Submit Date</th>
            <th>Deadline Date</th>
            <th>Priority</th>
            <th>Scope</th>
            <th>Notes</th>
            <th>Attachments</th>
            <th>PM Email</th>
            <th>PM Phone</th>
            </tr>
        </thead>
        <tbody>

        {
        all_rfqs.getAllRfqs
            .filter(a => JSON.stringify(a).toLowerCase().includes(searchText.toLowerCase()))
            .map((rfq, index) => (
            <tr key={index} id={index}>
            <td className={classes.tableItem} align="right">{
                rfq.service ?
                <span>{rfq.service}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                "DB is badly shaped. Cannot get vendors."
            }</td>
            <td className={classes.tableItem}>{
                rfq.submission_date ?
                <span>{new Date(rfq.submission_date).toISOString().substring(0, 10)}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem}>{
                rfq.deadline_date ?
                <span>{new Date(rfq.deadline_date).toISOString().substring(0, 10)}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                rfq.priority ?
                <span>{rfq.priority}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                rfq.scope ?
                <span>{rfq.scope}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                rfq.notes ?
                <span>{rfq.notes}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                rfq.attachments ?
                <span>{rfq.attachments}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                rfq.pm && rfq.pm.email ?
                <span>{rfq.pm.email}</span> : 'check user table'
            }</td>
            <td className={classes.tableItem} align="right">{
                rfq.pm && rfq.pm.phone_number ?
                <span>{rfq.pm.phone_number}</span> : 'check user table'
            }</td>
            {/* <td className={classes.tableItem}><DeleteVendorButton del={vendor} index={index} /></td> */}
            </tr>
            ))
        }
        </tbody>
        </table>
        </React.Fragment>
    )
}
