import firebase from "firebase/app";
import { onIdTokenChanged } from "./apollo";

export const loginWithGoogle = async onComplete => {
    let fbase = firebase.auth();
    if (!fbase.currentUser) {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope("profile");
        provider.addScope("email");
        provider.setCustomParameters({
            display: "popup"
        });
        try {
            const user = (await firebase.auth().signInWithPopup(provider)).user;
            await onIdTokenChanged(user);
            onComplete(user);
        } catch (e) {
            console.error(e);
        }
    }
};

export const signInWithEmail = async (email, password, onComplete, onError) => {
    try {
        const user = (await firebase.auth().signInWithEmailAndPassword(email, password)).user;
        if (user) {
            await onIdTokenChanged(user);
            onComplete(user);
        } else {
            onError(new Error("No user created"));
        }
    } catch (e) {
        console.log(e);
        onError(e);
    }
};

export const signUpWithEmail = async (email, password, onComplete, onError) => {
    try {
        await firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then(cred => {
                if (!cred.user) return onError(new Error("No user created"));

                return onIdTokenChanged(cred.user).then(() => {
                    onComplete(cred.user);
                });
            });
    } catch (e) {
        console.log(e);
        onError(e);
    }
};
