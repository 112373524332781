import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash-es/get';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { gql, useQuery } from '../../lib/apollo';
import VendorRating from './VendorRating';

const useStyles = makeStyles(theme => ({
    formGroup: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    formControl: {
        width: '100%',
    }
}));

export default function SpeedRating() {
    const classes = useStyles();

    const history = useHistory();

    const { data } = useQuery(GET_ENTERPRISE_USER);
    const vendors = get(data, 'getEnterpriseUser.enterprise.vendors', []);

    const handleSubmit = event => {
        event.preventDefault();
        history.push('/enterprise');
    };

    return (
        <Container maxWidth="sm">
            <Typography variant="h5">Cadillac Fairfield | Internal Vendor Rating</Typography>
            <form onSubmit={handleSubmit} className={classes.form}>
                <FormControl className={classes.formControl}>

                    {vendors.map((vendor, i) => <VendorRating vendor={vendor} key={i} />)}

                    <FormGroup className={classes.formGroup}>
                        <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                        >Return to Dashboard</Button>
                    </FormGroup>
                </FormControl>
            </form>
        </Container>
    );
}

const GET_ENTERPRISE_USER = gql`
    query getEnterpriseUser {
        getEnterpriseUser {
            _id
            uid
            email
            enterprise {
                vendors {
                    _id
                    company_name
                }
            }
        }
    }
`;
