import React from "react";
import Process from "../../pages_common/landing_page/ThreeItemBlock";
import VendorSignUpForm from "./VendorSignUpForm";
import {
    Typography,
    Grid,
    makeStyles,
    Paper,
    Container,
    Dialog,
    DialogTitle
} from "@material-ui/core";

export default function VendorSignUp({ invite }) {
    const bigForm = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    const executeScroll = () => window.scrollTo(0, bigForm.current.offsetTop);

    return (
        <React.Fragment>
            <Hero onSubmit={executeScroll} invite={invite} />
            <div style={{ background: "white" }}>
                <Process
                    items={[
                        {
                            image: "po_icon.png",
                            label: "Connect with P.O. Ready PMs",
                            body:
                                "Create a profile to get exposure to the all the property managers in Ontario. Let them come to you for once."
                        },
                        {
                            image: "showcase_icon.png",
                            label: "Showcase Your Business",
                            body:
                                "Collect reviews. Show PM’s why they should hire you over your competition."
                        },
                        {
                            image: "process23.png",
                            label: "Proactive Service Planning & Quote Requests.",
                            body:
                                "Be picky. Choose which jobs you want and manage all RFPs/RFQs in one dashboard."
                        }
                    ]}
                />
                <Container maxWidth={"lg"}>
                    <img
                        src={"/plans.png"}
                        alt=""
                        style={{ width: "100%" }}
                        onClick={() => setOpen(true)}
                    />
                    <Dialog
                        onClose={() => setOpen(false)}
                        fullWidth
                        maxWidth="md"
                        aria-labelledby="customized-dialog-title"
                        open={open}
                    >
                        <DialogTitle
                            style={{ marginBottom: 0 }}
                            id="customized-dialog-title"
                            onClose={() => setOpen(false)}
                        >
                            <Typography variant="h5">
                                Interested in Premium? Book a call with our sales team
                            </Typography>
                        </DialogTitle>
                        <div style={{ height: "100vh" }}>
                            <iframe
                                scrolling="no"
                                frameBorder="0"
                                height="100%"
                                width="100%"
                                src="https://calendly.com/emielvendorpm/15min"
                            />
                        </div>
                    </Dialog>
                </Container>
            </div>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    mainFeaturedPost: {
        position: "relative",
        backgroundColor: theme.palette.grey[800],
        color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        backgroundImage: "url(https://source.unsplash.com/random)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        textShadow: "1px 1px 8px rgba(0,0,0,0.3)"
    },
    overlay: {
        position: "absolute",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,.4)"
    },
    mainFeaturedPostContent: {
        position: "relative",
        padding: theme.spacing(3),
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(6),
            paddingRight: 0,
            overflow: "visible"
        },
        [theme.breakpoints.only("md")]: {
            height: 400
        },
        [theme.breakpoints.up("lg")]: {
            height: 300
        }
    },
    hoverForm: {
        zIndex: 10,
        textShadow: "none",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(0)
        },
        [theme.breakpoints.up("md")]: {
            padding: theme.spacing(3),
            paddingTop: theme.spacing(6),
            overflow: "visible"
        }
    },
    grid: {
        //overflow: "visible",
    }
}));

function Hero({ onSubmit, invite }) {
    const classes = useStyles();

    return (
        <Paper
            className={classes.mainFeaturedPost}
            style={{
                backgroundImage: `url(https://vendorpm.blob.core.windows.net/vendorpm/fallbackBG.jpg)`
            }}
        >
            {/* Increase the priority of the hero background image */}
            {
                <img
                    style={{ display: "none" }}
                    src={"https://vendorpm.blob.core.windows.net/vendorpm/fallbackBG.jpg"}
                    alt=""
                />
            }
            <div className={classes.overlay} />
            <Grid container className={classes.grid} justify="space-between">
                <Grid item xs={12} md={6} style={{ marginTop: 48, marginBottom: 48 }}>
                    <div className={classes.mainFeaturedPostContent}>
                        <Typography
                            component="h1"
                            variant="h3"
                            color="inherit"
                            style={{ fontWeight: "bold" }}
                            gutterBottom
                        >
                            Thousands of Property Managers Trust VendorPM To Find The Best
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={5} className={classes.hoverForm}>
                    <VendorSignUpForm invite={invite} />
                </Grid>
            </Grid>
        </Paper>
    );
}
