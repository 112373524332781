import React from 'react';
import {Theme, createStyles, makeStyles} from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from "@material-ui/core/Container";
import {Paper} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ContentPage from "../components/ContentPage";

const useStyles = makeStyles((theme) =>
    createStyles({
        header: {
            textAlign: "center",
            marginBottom: 15,
            marginTop: 25
        },

        h6: {
            marginBottom: 8,
            padding: "0 24px",
        },

    }),
);

const q_and_a = [
    {
        heading: "Accounts",
        items: [{
            question: "Why should I create an account?",
            answer: "By creating an account, you'll gain the ability to connect with thousands of vendors, join a community of property managers across Canada, bookmark your favourite companies, write reviews and request quotes based on the ratings & reviews of other PMs."
        },
            {
                question: "How do I create a VendorPM account?",
                answer: "Click the \"Sign Up\" button on the top right. Use your email or Google account to create a profile. If you choose to sign up using your email address, you must also create a display name and password. Click \"Join VendorPM” and you are all set.",
            }]
    }, {
        heading: "Reviews",
        items: [{
            question: "How do I write a review for a company?",
            answer: "To write a review; search for the vendor that you are looking for, click ‘write a review’ or go on their profile and simply assign a rating out of 5. You can also write a review to support your rating."
        }, {
            question: "Can I write my review anonymously?",
            answer: "Yes, of course! When writing your review; simply ensure the anonymous option is checked and your rating/review will be posted anonymously."
        }, {
            question: "How do I add a company to review on VendorPM?",
            answer: "To review a company that isn't yet listed on VendorPM, first log in, then click the \"Review A New Company\" link on the Write A Review page or go directly to this page. The company's name, your location, their category of work and phone number are all required information. You may also add an email address to assist with the verification process."
        }]
    }, {
        heading: "VendorPM",
        items: [{
            question: "How do I request quotes?",
            answer: "When searching for a service or even a specific vendor; you can select one or all vendors to request quotes from. You will be prompted to complete the basic info required including scope of work, additional comments, upload attachments and a submission deadline. Upon clicking complete, your quote request will be sent to all selected vendors just like that!"
        }, {
            question: "How Can I get help using VendorPM?",
            answer: <React.Fragment>
                To receive further assistance, simply book a call through the following link <Link to="http://calendly.com/emielvendorpm">calendly.com/emielvendorpm</Link>
            </React.Fragment>
        }]
    }
];

export default function FAQ() {
    const classes = useStyles();

    return (<ContentPage>{q_and_a.map(({heading, items}) => (<React.Fragment key={heading}>
                <Typography variant="h4" className={classes.header}>{heading}</Typography>
                {items.map(({question, answer}) => (
                    <ExpansionPanel key={question}>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon/>}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant="h6" className={classes.heading}>{question}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Typography>
                                {answer}
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                ))}
            </React.Fragment>
        ))}</ContentPage>);
}

