import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import React, {useState} from "react";

export const SnackBarContext = React.createContext((x)=> {});


export default function SnackBarProvider({children}) {
    const messageQueue = [];
    const [severity, setSeverity] = useState("info");
    const [message, setMessage] = useState("");
    const [visible, setVisible] = useState(false);

    const refresh = (showNext) => {
        const msg = messageQueue.shift();
        if (msg && (showNext || !visible || message.severity === "error")){
            setSeverity(msg.severity || "info");
            setMessage(msg.message);
            setVisible(true);
            // setTimeout(() => {
            //     refresh(true);
            // }, 3500);
        } else if (!msg){
            setVisible(false);
        }
    };

    const pushSnackbarMessage = (snack) => {
        messageQueue.push(snack);
        if(messageQueue.length === 1 && !visible) refresh(true)
    };

    return <SnackBarContext.Provider value={pushSnackbarMessage}>
        {children}
        <Snackbar
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            autoHideDuration={3500}
            open={visible}
            onClose={(e, reason) => {refresh(true)}}
        >
            <Alert severity={severity}>
                {message}
            </Alert>
        </Snackbar>
    </SnackBarContext.Provider>

}