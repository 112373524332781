import { TextField } from "@material-ui/core";
import React from "react";
import { useSignUpStyles } from "./sign_up/useSignUpStyles";
import Alert from "@material-ui/lab/Alert";
import firebase from "firebase/app";
import Link from "@material-ui/core/Link";

export default function PasswordField({
    confirm,
    email,
    newPass,
    password,
    onKeyDown,
    setPassword,
    currentPassword,
    setCurrentPassword,
    passwordsMatch = _ => {}
}) {
    const classes = useSignUpStyles();
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [result, setResult] = React.useState(false);

    return (
        <React.Fragment>
            {newPass && (
                <TextField
                    label={`Current Password*`}
                    variant="outlined"
                    className={classes.basicInfoField}
                    fullWidth
                    type="password"
                    value={currentPassword}
                    onChange={e => {
                        setCurrentPassword(e.target.value);
                    }}
                />
            )}

            <TextField
                label={`${newPass ? "New " : ""}Password*`}
                variant="outlined"
                className={classes.basicInfoField}
                fullWidth
                type="password"
                value={password}
                onKeyDown={!confirm ? onKeyDown : null}
                onChange={e => {
                    setPassword(e.target.value);
                    passwordsMatch(!confirm || confirmPassword === e.target.value);
                }}
            />
            {confirm && (
                <TextField
                    label="Confirm Password*"
                    variant="outlined"
                    onKeyDown={onKeyDown}
                    type="password"
                    className={classes.basicInfoField}
                    fullWidth
                    value={confirmPassword}
                    error={password !== confirmPassword}
                    onChange={e => {
                        setConfirmPassword(e.target.value);
                        passwordsMatch(!confirm || password === e.target.value);
                    }}
                />
            )}

            {(!confirm || newPass) && (
                <div
                    style={{
                        textAlign: "left",
                        marginBottom: 6
                    }}
                >
                    <Link
                        style={{ fontSize: 12 }}
                        href="#"
                        onClick={() => {
                            var auth = firebase.auth();
                            auth.sendPasswordResetEmail(email)
                                .then(function() {
                                    setResult(true);
                                })
                                .catch(function(reason) {
                                    console.log(reason);
                                    setResult(reason);
                                });
                        }}
                    >
                        Forgot Password?
                    </Link>
                    {result === true ? (
                        <Alert severity="info" color="info">
                            Reset email sent, go check your inbox!
                        </Alert>
                    ) : (
                        result !== false && (
                            <Alert severity="error" color="error">
                                {result.message}
                            </Alert>
                        )
                    )}
                </div>
            )}
        </React.Fragment>
    );
}
