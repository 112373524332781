import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from "recharts";

const lineColors = [
    { stroke: "rgb(45,91,255)", fill: "rgba(45,91,255, 0.6)" },
    { stroke: "rgb(140,84,255)", fill: "rgba(140,84,255, 0.6)" }
];

const useStyles = makeStyles(theme => ({
    label: {
        textTransform: "uppercase",
        paddingBottom: theme.spacing(2),
        legend: {
            position: "relative",
            left: 100
        }
    }
}));

function ResponsiveLineChart({ label, data, xAxisKey = "name", lineKeys = [] }) {
    const classes = useStyles();

    const lines = lineKeys.map((lineKey, i) => (
        <Area
            type="linear"
            dataKey={lineKey}
            stroke={lineColors[i].stroke}
            strokeWidth={2}
            fill={lineColors[i].fill}
            key={i}
        />
    ));

    return (
        <Typography component="div">
            {label && <Box className={classes.label}>{label}</Box>}
            <Box>
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart margin={{ top: 0, right: 0, left: -20, bottom: 10 }} data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey={xAxisKey} type="category" />
                        <YAxis />
                        <Tooltip />
                        <Legend iconType="circle" />
                        {lines}
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
        </Typography>
    );
}

export default memo(props => <ResponsiveLineChart {...props} />);
