import React from "react";
import {Paper, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ContentPage from "../components/ContentPage";

export default function TermsAndConditions() {
    const classes = useStyles();
    return (
        <ContentPage>
            <Typography variant="h4" className={classes.header}>
                VendorPM Terms & Conditions
            </Typography>
            <Typography variant="subtitle1">
                By consenting to the use of this platform, you confirm that
                you are aware of the business structure provided by VendorPM
                and the non-liability clauses hereinafter:
            </Typography>
            <Typography variant="subtitle1">
                VendorPM does not tamper with information available on the
                website and only displays what is publicly available and
                accessible.
            </Typography>
            <Typography variant="subtitle1">
                VendorPM does not take liability for information displayed
                on the website nor does it claim to be accurate up-to-date
                information.
            </Typography>
            <Typography variant="subtitle1">
                VendorPM takes no liability for changed commercial
                activities and/or business outcomes due to information or
                anonymous ratings available on the website.
            </Typography>
            <Typography variant="subtitle1">
                If you have any further questions, please contact{" "}
                <a
                    href="mailto:legal@vendorpm.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    legal@vendorpm.com
                </a>
            </Typography>
        </ContentPage>
    );
}

const useStyles = makeStyles(theme => ({
    header: {
        textAlign: "center",
        marginBottom: 40,
    },

    h6: {
        marginBottom: 8,
        padding: "0 24px",
    },

}));
