import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Divider, TextField } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    detailsPane: {
        width: 400
    },
    redButton: {
        borderColor: "red",
        color: "red"
    },
    disabled: {
        color: "black"
    }
}));

const FIELDS = [
    { key: "email", label: "Email" },
    { key: "phone", label: "Phone" },
    { key: "location", label: "Location" },
    { key: "bio", label: "Bio" }
];

export default function PMDetails({ pm, closePropertyManagerDetails }) {
    //DATA STITCH
    // get property's etc for the property manager
    const FAKE_DATA = {
        properties: ["300 King St.", "20 Yonge St."]
    };

    const initialPropertiesState = FAKE_DATA.properties
        .map((item, index) => {
            const key = `property${index}`;
            return { [key]: item };
        })
        .reduce((prevVal, currVal) => ({ ...prevVal, ...currVal }));

    const classes = useStyles();
    const { id, role, email, phone, location, bio } = pm;
    const initialFormState = { role, email, phone, location, bio };
    const [form, setState] = useState(initialFormState);
    const [propertyForm, setPropertyFormState] = useState(initialPropertiesState);
    const [editing, setEditing] = useState(false);

    const savePropertyManager = e => {
        e.preventDefault();
        // DATA STITCH
        // send form values to save pm query
        console.log(form);
    };

    const removePropertyManager = () => {
        // DATA STITCH
        // query to remove pm by ID
        console.log(id);
        closePropertyManagerDetails();
    };

    const updateField = e => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const updatePropertyField = e => {
        setPropertyFormState({
            ...propertyForm,
            [e.target.name]: e.target.value
        });
    };

    const toggleEditing = () => {
        setEditing(prevState => !prevState);
    };

    const addProperty = () => {
        const key = `property${Object.entries(propertyForm).length}`;
        console.log(`👨‍🌾 => `, propertyForm);
        setPropertyFormState({
            ...propertyForm,
            [key]: ""
        });
        setEditing(true);
    };

    return (
        <Box className={classes.detailsPane} p={2} pt={5}>
            <Typography variant="h4" align="center">
                {pm.firstName}
            </Typography>
            <form onSubmit={savePropertyManager}>
                <Box flexDirection="row" justifyContent="space-evenly" display="flex" p={2}>
                    <Box display="flex" flex={1} p={1}>
                        <Button
                            p={2}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={toggleEditing}
                            type={editing ? "button" : "submit"}
                        >
                            {editing ? "Save Profile" : "Edit Profile"}
                        </Button>
                    </Box>
                    <Box display="flex" flex={1} p={1}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            className={classes.redButton}
                            fullWidth
                            onClick={removePropertyManager}
                            type="button"
                        >
                            Remove PM
                        </Button>
                    </Box>
                </Box>
                <Divider />
                <Box p={2}>
                    {FIELDS.map(({ key, label }) => (
                        <TextField
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{
                                disableUnderline: !editing,
                                classes: {
                                    disabled: classes.disabled
                                }
                            }}
                            key={key}
                            id={key}
                            margin="normal"
                            disabled={!editing}
                            variant={"standard"}
                            name={key}
                            label={label}
                            value={form[key]}
                            onChange={updateField}
                        />
                    ))}
                    <Box pt={4}>
                        <Typography variant="h5">Properties</Typography>
                        {Object.entries(propertyForm).map((property, index) => {
                            const key = property[0];
                            const label = `Property ${index + 1}`;
                            return (
                                <TextField
                                    fullWidth
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        disableUnderline: !editing,
                                        classes: {
                                            disabled: classes.disabled
                                        }
                                    }}
                                    key={key}
                                    id={key}
                                    margin="normal"
                                    disabled={!editing}
                                    variant={"standard"}
                                    name={key}
                                    label={label}
                                    value={propertyForm[key]}
                                    onChange={updatePropertyField}
                                />
                            );
                        })}
                        <Box display="flex" flex={1} p={1}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={addProperty}
                                type="button"
                            >
                                Add Property
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </form>
        </Box>
    );
}
