import React, { useState } from 'react'
import { GET_ALL_VENDORS } from '../lib/apollo/queries'
import { useQuery } from 'react-apollo'
import Loading from '../components/Loading'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useStyles } from '../styles/Settings'
import DeleteVendorButton from './DeleteVendorButton'

export default function VendorPanel() {
    const [searchText, setSearchText] = useState('');
    const classes = useStyles();
    const keyPress = (e) => { if (e.keyCode == 13) setSearchText(e.target.value) }
    const resetSearch = () => { setSearchText('') }

    const { loading, data: all_vendors, error } = useQuery(GET_ALL_VENDORS);
    if (loading) return <Loading />
    if (error) return 
    return (
        <React.Fragment>
        <TextField id='searchBar' className={classes.textField} onKeyDown={keyPress} label="Search Vendors (case-sensitive)" variant="outlined" fullWidth/>
        {/* <Button onClick={() => resetSearch} color="primary">Reset</Button> */}

        <table>
        <thead>
            <tr>
            <th>Company</th>
            <th>Email</th>
            <th>Website</th>
            <th>Phone</th>
            <th>Services</th>
            <th>Address</th>
            <th>Created</th>
            <th>Claimed</th>
            </tr>
        </thead>
        <tbody>

        {
        all_vendors.getAllVendors
            .filter(a => JSON.stringify(a).toLowerCase().includes(searchText.toLowerCase()))
            .map((vendor, index) => (
            <tr key={index} id={index}>
            <td className={classes.tableItem} align="right">{
                vendor.company_name ?
                <span>{vendor.company_name}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                vendor.email ?
                <a target="__blank" href={"mailto:"+vendor.email}>{vendor.email}</a> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                vendor.website ?
                <span>{vendor.website}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                vendor.phone ?
                <span>{vendor.phone}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                vendor.services ?
                <span>{vendor.services}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                vendor.address ?
                <span>{vendor.address.street_number} {vendor.address.street}, {vendor.address.city}, {vendor.address.province}</span>
                : 'unknown'
            }</td>
            <td className={classes.tableItem}>{
                vendor.created_date ?
                <span>{new Date(parseInt(vendor.created_date)).toISOString().substring(0, 10)}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                vendor.claimed ?
                <span>true</span> : 'false'
            }</td>
            <td className={classes.tableItem}><DeleteVendorButton del={vendor} index={index} /></td>
            </tr>
            ))
        }
        </tbody>
        </table>
        </React.Fragment>
    )
}
