import React from "react";
import ServicePickerModal from "../../components/ServicePickerModal";
import { useMutation } from "react-apollo";
import { SELECT_VENDOR } from "../../lib/apollo/mutations";
import { useHistory } from "react-router-dom";
import {
    Typography,
    Grid,
    Divider,
    Button,
    ListItemIcon,
    ListItem,
    Hidden,
    ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FiExternalLink, FiCornerUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import uglyFeatureFlags from "../../lib/uglyFeatureFlags";

const useStyles = makeStyles(theme => ({
    sidebarContainer: {
        borderRadius: 4,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#eee",
        padding: 12,
        marginLeft: 32,
        marginBottom: 16,
        "& h5": {
            fontWeight: 600
        },
        "@media (max-width: 960px)": {
            "& h5": {
                fontWeight: 400,
                marginBottom: 32
            },
            marginBottom: 0,
            borderStyle: "none",
            padding: 0,
            marginLeft: 16,
            marginTop: 24
        }
    },
    sideBarIcons: {
        marginRight: 4
    }
}));

export default function VendorProfileSidebar({
    website,
    address,
    services: servicesIn,
    availability,
    _id
}) {
    const services = servicesIn || [];
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [pickedService, setPickedService] = React.useState(null);
    const { street, street_number, city } = address || {};
    const reconstituded_address = `${street_number} ${street}, ${city}`;
    const [selectVendor] = useMutation(SELECT_VENDOR, {
        variables: { _id },
        onCompleted: () => history.push(`/vendors-of/${encodeURIComponent(pickedService)}`)
    });

    return (
        <Grid item xs={12} sm={7} md={4}>
            <ServicePickerModal
                {...{ open, setOpen, services }}
                onServicePick={service => {
                    setPickedService(service);
                    selectVendor();
                }}
            />
            <div className={classes.sidebarContainer}>
                <Hidden smDown>
                    <Typography variant="h5" gutterBottom>
                        Request A Quote
                    </Typography>
                </Hidden>
                <Grid container justify="center">
                    <Hidden smDown>
                        <Grid item xs={5} style={{ textAlign: "center" }}>
                            <Typography variant="h6" style={{ textTransform: "capitalize" }}>
                                {availability}
                            </Typography>
                            <Typography variant="caption">Availability</Typography>
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: "center" }}>
                            <Divider orientation="vertical" style={{ margin: "4px auto" }} />
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: "center" }}>
                            <Typography variant="h6">100%</Typography>
                            <Typography variant="caption">Response Rate</Typography>
                        </Grid>
                    </Hidden>

                    <Grid item xs={12}>
                        <Grid container justify="flex-start">
                            <Grid item xs={12} sm={6} md={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{
                                        fontSize: 16,
                                        marginTop: 22,
                                        marginBottom: 12
                                    }}
                                    onClick={() => setOpen(true)}
                                >
                                    Get A Quote
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.sidebarContainer}>
                {uglyFeatureFlags.websiteInVendorProfileSidebar && website && (
                    <React.Fragment>
                        <ListItem style={{ padding: 16 }}>
                            <ListItemIcon>
                                <a
                                    href={website}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        textDecoration: "none",
                                        color: "rgba(0,0,0,0.54)"
                                    }}
                                >
                                    <FiExternalLink
                                        size={24}
                                        style={{
                                            verticalAlign: "bottom",
                                            marginRight: 8
                                        }}
                                    />
                                </a>
                            </ListItemIcon>
                            <ListItemText>
                                <a
                                    href={website}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    style={{ textDecoration: "none", color: "#1da1f2" }}
                                >
                                    {website}
                                </a>
                            </ListItemText>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                )}

                {address.street && (
                    <ListItem style={{ padding: 16 }}>
                        <ListItemIcon>
                            <FiCornerUpRight
                                size={24}
                                style={{ verticalAlign: "bottom", marginRight: 8 }}
                            />
                        </ListItemIcon>
                        <ListItemText>{reconstituded_address}</ListItemText>
                    </ListItem>
                )}
            </div>
        </Grid>
    );
}
