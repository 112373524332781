// cardDraggable: true
// className: "react-trello-card"
// description: "Scope"
// draggable: true
// id: "5e1b882749ebf36fa3d05347"
// index: 0
// label: {$$typeof: Symbol(react.element), type: {…}, key: null, ref: null, props: {…}, …}
// laneId: "1"
// metadata: {_id: "5e1b882749ebf36fa3d05347", service: "Auditing & Accounting", closed: null, submission_date: 1578862631910, deadline_date: 1578862616018, …}
// onClick: e => this.handleCardClick(e, card)
// onDelete: () => this.removeCard(card.id)
// showDeleteButton: false
// style: {fontFamily: "-apple-system,BlinkMacSystemFont,"Segoe UI","Helve…e Color Emoji","Segoe UI Emoji","Segoe UI Symbol"", backgroundColor: "rgba(255,255,255,1)", borderLeftWidth: 4, borderLeftStyle: "solid", borderLeftColor: "#9c27b0", …}
// tagStyle: undefined
// title: "Auditing & Accounting"
// key: undefined
import React from "react";
import {
    CardRightContent,
    CardTitle,
    Detail,
    Footer,
    MovableCardWrapper
} from "react-trello/src/styles/Base";

export default function CustomCard(props) {
    const {
        showDeleteButton,
        style,
        tagStyle,
        onClick,
        onDelete,
        className,
        id,
        title,
        label,
        description,
        tags,
        cardDraggable
    } = props;
    return (
        <MovableCardWrapper data-id={id} onClick={onClick} style={style} className={className}>
            <CardTitle draggable={cardDraggable}>{title}</CardTitle>
            <CardRightContent>{label}</CardRightContent>

            <Detail>{description}</Detail>
        </MovableCardWrapper>
    );
}
