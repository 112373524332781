import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Link } from "@material-ui/core";
import Board from "react-trello";
import TrelloCard from "./TrelloCard";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

const styles = {
    board: {
        backgroundColor: "transparent",
        height: "auto",
        width: "100%"
    },
    lane: {
        backgroundColor: "#f5f5f5",
        width: "400px"
    }
};

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

export default function TrelloBoard({
    data,
    updateData,
    CardForm,
    CustomCard,
    getFormDataFromId,
    onSubmitForm,
    draggable,
    disableAdd
}) {
    const augmentBoardData = boardData => ({
        // functionally adds length of lanes as label
        lanes: boardData.lanes.map(lane => ({ ...lane, label: lane.cards.length }))
    });
    const initialBoardData = augmentBoardData(data);
    const [boardData, setBoardData] = useState(initialBoardData);
    const [openModal, setOpenModal] = useState(false);
    const [editing, setEditing] = useState(null);
    const [initialFormData, setInitialFormData] = useState({});
    const classes = useStyles();

    const openCardFormEdit = async (cardId, laneId) => {
        //  DATA STITCH
        // get this data from server by implementing it and passing to this visual component
        const initialFormData = getFormDataFromId({ cardId, laneId });
        await setInitialFormData(initialFormData);
        await setEditing(true);
        await setOpenModal(true);
    };

    const setupCardFormAdd = async laneId => {
        const initialFormData = getFormDataFromId({ laneId });
        await setInitialFormData(initialFormData);
        await setEditing(false);
    };

    const openCardFormAdd = () => {
        setOpenModal(true);
    };

    const updateBoardData = boardData => {
        // DATA STITCH
        // Send board data to server & overwrite current data with new
        updateData(boardData);
        const augmentedData = augmentBoardData(boardData);
        setBoardData(augmentedData);
    };

    const submitForm = formData => {
        onSubmitForm(formData);
    };

    return (
        <div>
            <Board
                onDataChange={updateBoardData}
                data={boardData}
                style={styles.board}
                laneStyle={styles.lane}
                editable={!disableAdd}
                laneDraggable={false}
                cardDraggable={!!draggable}
                onLaneClick={laneId => !disableAdd && setupCardFormAdd(laneId)}
                onCardClick={(cardId, metadata, laneId) =>
                    !disableAdd && openCardFormEdit(cardId, laneId)
                }
                components={{
                    Card: CustomCard ? CustomCard : TrelloCard,
                    AddCardLink: props => {
                        return (
                            <Link onClick={openCardFormAdd} style={{ cursor: "pointer" }}>
                                Add item
                            </Link>
                        );
                    }
                }}
            />

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={openModal}>
                    <CardForm
                        {...{
                            initialData: initialFormData,
                            edit: editing,
                            submitForm
                        }}
                    />
                </Fade>
            </Modal>
        </div>
    );
}
