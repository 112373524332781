import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import EnterpriseNavigation from "./components/EnterpriseNavigation";
import PreferredVendors from "./pages/preferred_vendors/PreferredVendors";
import PropertyManagers from "./pages/property_managers/PropertyManagers";
import CentralizationOpportunities from "./pages/centralization/CentralizationOpportunities";
import EnterpriseSignUp from "./pages/signup/EnterpriseSignUp";
import EnterpriseSignIn from "./pages/signin/EnterpriseSignIn";
import Dashboard from "./pages/dashboard/Dashboard";
import Threats from "./pages/threats/Threats";
import SpeedRating from "./pages/speed_rating/SpeedRating";
import Settings from "./pages/settings/Settings";
import { useFirebaseUser } from "./lib/hooks";

export default function Routes() {
    const match = useRouteMatch();
    const user = useFirebaseUser();

    return (
        <EnterpriseNavigation>
            <Switch>
                <PrivateRoute user={user} exact path={`${match.path}/vendors`}>
                    <PreferredVendors />
                </PrivateRoute>
                <PrivateRoute user={user} exact path={`${match.path}/pmlist`}>
                    <PropertyManagers />
                </PrivateRoute>
                <PrivateRoute user={user} exact path={`${match.path}/threats`}>
                    <Threats />
                </PrivateRoute>
                <PrivateRoute user={user} exact path={`${match.path}/centralization`}>
                    <CentralizationOpportunities />
                </PrivateRoute>
                <PrivateRoute user={user} exact path={`${match.path}/settings`}>
                    <Settings />
                </PrivateRoute>
                <PrivateRoute user={user} exact path={`${match.path}/speed-rating`}>
                    <SpeedRating />
                </PrivateRoute>
                <PrivateRoute user={user} exact path={match.path}>
                    <Dashboard />
                </PrivateRoute>

                <Route path={`${match.path}/sign-in`}>
                    <EnterpriseSignIn />
                </Route>
                <Route path={`${match.path}/sign-up`}>
                    <EnterpriseSignUp />
                </Route>
            </Switch>
        </EnterpriseNavigation>
    );
}

function PrivateRoute({ children, user, ...rest }) {
    const render = () => user ? children : (<Redirect to="/enterprise/sign-in" />);
    return (<Route {...rest} render={render} />);
}
