import React from "react";
import {
    Grid,
    Container,
    Typography,
    Paper,
    List,
    ListItem,
    ListSubheader,
    ListItemText
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation, useParams } from "react-router-dom";

import { listOfServices, listOfCategories } from "../lib/serviceSearch";
import { useMutation } from "react-apollo";
import { CLEAR_SELECTED_VENDORS } from "../lib/apollo/mutations";

import { useQuery } from "@apollo/react-hooks";
import ServiceList from "../components/ServiceList";

const useStyles = makeStyles(theme => ({
    header: {
        margin: theme.spacing(4),
        marginBottom: 0,
        fontWeight: 900,
        textAlign: "center"
    },

    center: {
        margin: theme.spacing(2, 4),
        marginBottom: theme.spacing(4),
        textAlign: "center"
    },

    reviewLink: {
        textDecoration: "none",
        color: "rgba(0,0,0,0.87)",
        display: "block",
        padding: 2,
        textShadow: "0 0 1px rgba(0,0,0,0.15)",
        transition: "0.15s",
        "&:hover": {
            color: "rgba(0,0,0,0.6)",
            textShadow: "0 0 1px rgba(0,0,0,0)"
        }
    },

    reviewButtonText: {
        fontWeight: 600,
        fontSize: 16
    },

    container: {
        padding: theme.spacing(4, 3),
        marginTop: 0,
        paddingTop: 8
    }
}));

export default function ServiceListPage() {
    const classes = useStyles();
    const location = useLocation();
    const { category } = useParams();
    const headerText = location.pathname === "/categories" ? "Categories" : "Services";
    const [clearSelectedVendors] = useMutation(CLEAR_SELECTED_VENDORS);

    return (
        <div>
            <Container>
                <Typography variant="h4" className={classes.header}>
                    {headerText}
                </Typography>
                <Typography variant="subtitle2" className={classes.center}>
                    Choose from among 150 services provided by vendors in Toronto.
                </Typography>
            </Container>
            <div
                style={{
                    background: "white",
                    paddingTop: 16,
                    paddingBottom: 160
                }}
            >
                <Container maxWidth="lg">
                    <ServiceList
                        {...{ listOfCategories, category }}
                        makeLinkProps={service => ({
                            onClick: clearSelectedVendors,
                            to: `/vendors-of/${encodeURIComponent(service)}`
                        })}
                    />
                </Container>
            </div>
        </div>
    );
}
