import React, { useState } from 'react'
import { GET_ALL_REVIEWS } from '../lib/apollo/queries'
import { useQuery } from 'react-apollo'
import Loading from '../components/Loading'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useStyles } from '../styles/Settings'
import DeleteReviewButton from './DeleteReviewButton'

export default function RFQPanel() {
    const [searchText, setSearchText] = useState('');
    const classes = useStyles();
    const keyPress = (e) => { if (e.keyCode == 13) setSearchText(e.target.value) }
    // const resetSearch = () => { setSearchText('') }

    const { loading, data: all_reviews, error } = useQuery(GET_ALL_REVIEWS);
    if (loading) return <Loading />
    if (error) return error.toString()

    return (
        <React.Fragment>
        <TextField id='searchBar' className={classes.textField} onKeyDown={keyPress} label="Search RFQs (case-sensitive)" variant="outlined" fullWidth/>
        {/* <Button onClick={() => resetSearch} color="primary">Reset</Button> */}

        <table>
        <thead>
            <tr>
            <th>Vendor</th>
            <th>Reviewer ID (User)</th>
            <th>Anonymous</th>
            <th>Rating</th>
            <th>Description</th>
            <th>Date</th>
            </tr>
        </thead>
        <tbody>

        {
        all_reviews.getAllReviews
            .filter(a => JSON.stringify(a).toLowerCase().includes(searchText.toLowerCase()))
            .map((review, index) => (
            <tr key={index} id={index}>
            <td className={classes.tableItem} align="right">{
                review.vendor_company_name ?
                <span>{review.vendor_company_name}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                review.reviewer ?
                <span>{review.reviewer}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                review.anonymous ?
                <span>{review.anonymous}</span> : 'false'
            }</td>
            <td className={classes.tableItem} align="right">{
                review.rating ?
                <span>{review.rating}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                review.description ?
                <span>{review.description}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                review.created_date ?
                <span>{new Date(review.created_date).toString().substring(0, 10)}</span> : 'unknown'
            }</td>

            <td className={classes.tableItem}><DeleteReviewButton del={review} index={index} /></td>
            </tr>
            ))
        }
        </tbody>
        </table>
        </React.Fragment>
    )
}
