import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import CentralizationBoard from "./CentralizationBoard";
import Grid from "@material-ui/core/Grid";
import CentralizationMap from "./CentralizationMap";
import PieChart from "../../../components/PieChart";
import NumberWithLabel from "../../../components/NumberWithLabel";
import GridItem from "../../../components/GridItem";
import CardTitle from "../../../components/CardTitle";

const FAKE_DATA = {
    centralizationOpportunities: 7,
    centralizationStatus: [
        {
            name: "Urgent",
            value: 2400
        },
        {
            name: "Upcoming",
            value: 4567
        },
        {
            name: "Completed",
            value: 1398
        },
        {
            name: "Wishlist",
            value: 9800
        }
    ]
};

export default function CentralizationOpportunities() {
    return (
        <Box>
            <h1>Centralization Opportunities</h1>
            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                <Box p={3} justifyContent="center" display="flex" flex={1}>
                    <CentralizationBoard />
                </Box>
                <GridItem xs={6}>
                    <Box pb={3}>
                        <CardTitle>PROPERTIES TO CENTRALIZE</CardTitle>
                    </Box>
                    <CentralizationMap />
                </GridItem>
                <GridItem xs={6}>
                    <Box pb={3}>
                        <CardTitle>CENTRALIZATION STATUS</CardTitle>
                    </Box>
                    <PieChart data={FAKE_DATA.centralizationStatus} />
                </GridItem>
                <GridItem xs={12}>
                    <NumberWithLabel
                        value={FAKE_DATA.centralizationOpportunities}
                        primaryLabel="Opportunities"
                        secondaryLabel="to Centralize Services"
                        labelColor="green"
                    />
                </GridItem>
            </Grid>
        </Box>
    );
}
