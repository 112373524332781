import React from "react";
import TextField from "@material-ui/core/TextField";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { useSignUpStyles } from "../../components/sign_up/useSignUpStyles";
import { AssociationPicker } from "../../components/AssociationPicker";
import { CompliancePicker } from "../../components/CompliancePicker";
import { ServiceMultiPicker } from "../../components/ServiceMultiPicker";
import { Button } from "@material-ui/core";
import uglyFeatureFlags from "../../lib/uglyFeatureFlags";

export default function AdditionalInfo({ state, setState, children, prefill }) {
    const classes = useSignUpStyles();
    const makeSetStateForProp = prop => value => setState({ ...state, [prop]: value });
    const setDescription = makeSetStateForProp("description");
    const setAvailability = makeSetStateForProp("availability");
    const setAssociations = makeSetStateForProp("associations");
    const setServices = makeSetStateForProp("services");
    const setCompliances = makeSetStateForProp("compliances");
    const setLogo = makeSetStateForProp("logo");
    const setStep = makeSetStateForProp("step");

    React.useEffect(() => {
        if (prefill) setServices(prefill?.getVendor?.services || []);
    }, [prefill]);

    return (
        <React.Fragment>
            <ServiceMultiPicker services={state.services} setServices={setServices} />

            <TextField
                label="Description*"
                variant="outlined"
                className={classes.basicInfoField}
                fullWidth
                value={state.description}
                onChange={e => setDescription(e.target.value)}
                multiline={true}
            />

            {uglyFeatureFlags.AvailabilityPickerInSignUp && (
                <AvailabilityPicker onPick={setAvailability} />
            )}

            <AssociationPicker
                classes={classes}
                associations={state.associations}
                setAssociations={setAssociations}
            />

            <CompliancePicker
                classes={classes}
                compliances={state.compliances}
                setCompliances={setCompliances}
            />

            {state.step !== undefined && (
                <Button
                    fullWidth
                    variant="outlined"
                    className={classes.signUpButton}
                    onClick={() => setStep(2)}
                >
                    Next
                </Button>
            )}
        </React.Fragment>
    );
}

function AvailabilityPicker({ onPick }) {
    const options = ["Tomorrow", "~3 Days", "~1 Week", "~2 Weeks", "~1 Month"];
    React.useEffect(() => onPick(options[0]), []);
    return (
        <React.Fragment>
            <Typography id="discrete-slider-restrict" gutterBottom variant="subtitle2">
                Availability
            </Typography>
            <Slider
                style={{ width: 276, marginLeft: 32, marginRight: 32 }}
                defaultValue={0}
                step={null}
                onChangeCommitted={(event, value) =>
                    onPick(options[Math.round((value * (options.length - 1)) / 100)])
                }
                marks={options.map((opt, i) => ({
                    value: (100 * i) / (options.length - 1),
                    label: opt
                }))}
            />
        </React.Fragment>
    );
}
