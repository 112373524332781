import React from "react";
import { Button, Card, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import { Link } from "react-router-dom";
import { FiCheckCircle } from "react-icons/fi";
import { useQuery } from "react-apollo";
import { GET_USER } from "../lib/apollo/queries";
// import Imgix from "react-imgix";

const image_base = link => `http://vendorpm.imgix.net/${link}?w=288`;

export default function VendorListItem({ item, claimable, actions }) {
    const classes = useStyles();
    const {
        company_name,
        _id,
        claimed,
        address,
        logo,
        selected,
        services: servicesIn,
        reviews_objs
    } = item;
    // TODO ensure vendors have some services
    const services = servicesIn || [];
    const { street, street_number, city } = address || {};

    const { data, loading, error } = useQuery(GET_USER);
    const reconstituded_address =
        (address?.street_number ? `${address?.street_number} ` : "") +
        `${address?.street}, ${address?.city}`;
    // verified field to be added to vendor objects
    const verified = false ? (
        <FiCheckCircle size={18} strokeWidth={3} className={classes.verifiedCheck} />
    ) : (
        false
    );

    const image = logo && logo !== "false" ? image_base(logo) : "/logoplaceholder.png";
    const profileURL = `/vendor-profile/${_id}${claimable ? "/claim" : ""}`;
    return (
        <Grid item xs={12} className={classes.cardContainer}>
            <Card className={classes.card} elevation={selected ? 3 : 0}>
                <Link to={profileURL} style={{ textDecoration: "none" }}>
                    <CardMedia title={company_name} className={classes.cover} image={image} />
                </Link>
                <CardContent className={classes.content}>
                    <Grid container justify="space-between">
                        <Grid item xs={10} sm={8}>
                            <Link to={profileURL} style={{ textDecoration: "none" }}>
                                <Typography variant="subtitle1" className={classes.companyName}>
                                    {company_name}
                                    {verified}
                                </Typography>
                            </Link>
                            <React.Fragment>
                                <Rating
                                    className={classes.rating}
                                    value={
                                        reviews_objs.map(r => r.rating).reduce((a, b) => a + b, 0) /
                                            reviews_objs.length || 0
                                    }
                                    precision={0.1}
                                    readOnly
                                />
                                <Typography
                                    component="p"
                                    variant="caption"
                                    className={classes.reviewCount}
                                >
                                    {/* {`(${reviews.length} review${reviewsPlural})`} */}
                                    {reviews_objs && `(${reviews_objs.length} reviews)`}
                                </Typography>
                            </React.Fragment>
                            {// Hotfix: Some addresses are Strings, in which case, they won't have a street.
                            address.street && (
                                <Typography variant="subtitle1" className={classes.address}>
                                    {address && reconstituded_address}
                                </Typography>
                            )}
                            <Typography
                                variant="subtitle1"
                                color="textSecondary"
                                className={classes.services}
                            >
                                {!!services.length &&
                                    services.slice(0, 4).reduce((p, n) => p + ", " + n)}
                                {services.length > 4 ? ` (${services.length - 4} more)` : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div>
                                <Link to={profileURL} style={{ textDecoration: "none" }}>
                                    <Button variant="outlined" className={classes.cardBtns}>
                                        Reviews
                                    </Button>
                                </Link>
                            </div>
                            {Object.entries(actions).map(([name, onClick]) => {
                                return (
                                    <div>
                                        <Button
                                            variant="outlined"
                                            onClick={onClick}
                                            className={classes.cardBtns}
                                        >
                                            {name}
                                        </Button>
                                    </div>
                                );
                            })}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}

const useStyles = makeStyles(theme => ({
    card: {
        minHeight: 176,
        display: "flex",
        borderRadius: 0,
        padding: theme.spacing(2),
        background: "transparent",
        marginTop: 8,
        borderTop: "solid 1px rgba(0,0,0,0.07)",
        "@media (max-width: 800px)": {
            minHeight: 160
        },
        "@media (max-width: 700px)": {
            minHeight: 144,
            paddingTop: 0,
            paddingBottom: 0
        }
    },

    cardBtns: {
        float: "right",
        marginBottom: 12,
        "@media (max-width: 600px)": {
            float: "left",
            marginTop: 16,
            marginRight: 8
        }
    },

    content: {
        flex: 1,
        paddingRight: 0,
        "@media (max-width: 600px)": {
            paddingBottom: "8px !important"
        }
    },

    cover: {
        width: 144,
        height: 144,
        backgroundSize: "contain",
        marginTop: 16,
        marginBottom: 8,
        marginRight: 16,
        verticalAlign: "top",
        marginLeft: 12,
        "@media (max-width: 800px)": {
            width: 116,
            height: 116
        },
        "@media (max-width: 700px)": {
            width: 88,
            height: 88
        },
        "@media (max-width: 540px)": {
            width: 64,
            height: 64
        },
        "@media (max-width: 460px)": {
            display: "none"
        }
    },

    ///////////////////////////

    cardContainer: {
        marginLeft: "auto",
        marginRight: "auto",
        padding: theme.spacing(1),
        paddingTop: theme.spacing(2)
    },

    companyName: {
        fontWeight: 700,
        color: "#0073bb",
        textDecoration: "none",
        lineHeight: 1.1,
        "&:hover": {
            textDecoration: "underline"
        }
    },

    address: {
        fontSize: 13
    },

    services: {
        fontSize: 11
    },

    rating: {
        marginTop: 4,
        fontSize: 18
    },

    reviewCount: {
        fontSize: 12,
        verticalAlign: "super",
        marginLeft: 6,
        display: "inline-block"
    },

    verifiedCheck: {
        verticalAlign: "middle",
        color: "#1da1f2",
        marginLeft: 8,
        marginBottom: 4
    }
}));
