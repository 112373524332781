import React from "react";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ContentPage from "../components/ContentPage";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme =>
    createStyles({
        contentParent: {
            padding: theme.spacing(3),
            alignContent: "center",
            marginTop: theme.spacing(4),
            "&": {
                textAlign: "center",
            },
        },
        ctaBox: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            marginLeft: "auto",
            marginRight: "auto",
            padding: theme.spacing(2),
            borderColor: "rgba(25,25,25,0.3)",
            //borderStyle: "solid",
            alignContent: "center",
        },

        title: {
            textAlign: "center",
            padding: theme.spacing(3),
        },
        subtitle: {
            textAlign: "center",
            padding: theme.spacing(3),
        },
        button: {
            textAlign: "center",
            padding: theme.spacing(1, 2),
            margin: theme.spacing(3),
        },

        profileLink: {
            textAlign: "center",
            padding: theme.spacing(3),
        },
    })
);
export default function VendorWelcome() {
    const classes = useStyles();

    return (
        <ContentPage size="sm" paper={false}>
            <div className={classes.contentParent}>
                <Typography className={classes.title} variant="h3">
                    Welcome to VendorPM
                </Typography>
                <Typography className={classes.subtitle} variant="subtitle1">
                    An account manager will be in touch soon to help you
                    finalise your profile.
                </Typography>

                <Container maxWidth="sm">
                    <Paper className={classes.ctaBox} elevation={0}>
                        <Typography variant="subtitle2">
                            To get the most out of your VendorPM profile have
                            your previous clients leave a review for you.
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            className={classes.subtitle}
                        >
                            We've got a tool to make the request easy.
                        </Typography>

                        <Link
                            to="/account/invites"
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                className={classes.button}
                            >
                                REQUEST REVIEWS FROM PMS
                            </Button>
                        </Link>

                        <br />

                        <Link
                            to="/account/profile"
                            className={classes.profileLink}
                        >
                            Take me to my profile
                        </Link>
                    </Paper>
                </Container>
            </div>
        </ContentPage>
    );
}
