import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@material-ui/core";
import React from "react";

export function CompliancePicker({ classes, compliances, setCompliances }) {
    function selectCompliance(e) {
        // @ts-ignore
        const value = e.target.value;
        compliances.includes(value)
            ? setCompliances(compliances.filter(a => a !== value))
            : setCompliances([...compliances, value]);
    }

    return (
        <React.Fragment>
            <Typography
                gutterBottom
                variant="subtitle2"
                style={{ marginTop: 16 }}
                className={classes.inputPrompt}
            >
                Compliance
            </Typography>

            <div className={classes.toggleContainer}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compliances.includes("liability_insurance")}
                                color="primary"
                                value="liability_insurance"
                                onClick={selectCompliance}
                            />
                        }
                        label="Liability Insurance"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={compliances.includes("wsib")}
                                color="primary"
                                value="wsib"
                                onClick={selectCompliance}
                            />
                        }
                        label="WSIB"
                    />
                </FormGroup>
            </div>
        </React.Fragment>
    );
}
