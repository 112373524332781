import { useState, useEffect, useRef } from "react";
import { debounce } from 'lodash-es';
import firebase, { user as currentUser } from '../firebase';

export function useFirebaseUser() {
  const [user, setUser] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onIdTokenChanged(() => setUser(currentUser()));
    return () => unsubscribe();
  });

  return user;
}

export function useDebounce(func, dependency, timeout = 500) {
  const debounced = useRef(debounce(func, timeout));
  useEffect(() => { debounced.current(); }, [dependency]);
}
