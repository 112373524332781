import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADMIN_DELETE_REVIEW } from "../lib/apollo/mutations";
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'

export default function DeleteReviewButton ({del, index}) {
    const [adminDeleteReview] = useMutation(ADMIN_DELETE_REVIEW, {variables: {review: del._id}})
    return (
        <Button onClick={async () => await adminDeleteReview().then(document.getElementById(index.toString()).style.display = 'none')}><DeleteIcon color='error' /></Button>
    )
}
