import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Container } from "@material-ui/core";
import GridItem from "../../../components/GridItem";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import TextField from "@material-ui/core/TextField";
import CardTitle from "../../../components/CardTitle";
import Slider from "../../../components/Slider";
import { update } from "lodash-es";

const useStyles = makeStyles(theme => ({
    formMargin: {
        margin: theme.spacing(3)
    }
}));

const FAKE_DATA = {
    qualityThreshold: 4.1,
    centralizationThreshold: 4,
    servicesThreshold: 3.3
};

const FILTERS = [
    {
        key: "email",
        name: "Email"
    },
    {
        key: "firstName",
        name: "First Name"
    },
    {
        key: "lastName",
        name: "Last Name"
    },
    {
        key: "birthDate",
        name: "Birth Date"
    }
];
const PW_FIELDS = [
    {
        key: "current",
        name: "Current Password"
    },
    {
        key: "newPw",
        name: "New Password"
    },
    {
        key: "confirmPw",
        name: "Confirm New Password"
    }
];

export default function Threats() {
    const classes = useStyles();

    const initialFormState = FILTERS.map(({ key }) => ({ [key]: "" }));
    const [form, setState] = useState(initialFormState);

    const initialPwFormState = PW_FIELDS.map(({ key }) => ({ [key]: "" }));
    const [pwForm, setPwState] = useState(initialPwFormState);

    const initialThresholdState = {
        quality: FAKE_DATA.qualityThreshold,
        centralization: FAKE_DATA.centralizationThreshold,
        services: FAKE_DATA.servicesThreshold
    };
    const [thresholdForm, setThresholdState] = useState(initialThresholdState);

    // personal details forms
    const submitForm = e => {
        // DATA STITCH
        e.preventDefault();
        console.log(form);
    };

    const updateField = e => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    // password forms
    const submitPwForm = e => {
        // DATA STITCH
        e.preventDefault();
        console.log(pwForm);
    };

    const updatePwField = e => {
        setPwState({
            ...pwForm,
            [e.target.name]: e.target.value
        });
    };

    // slider form
    const updateSlider = (key, val) => {
        setThresholdState({
            ...form,
            [key]: val
        });
    };

    return (
        <Box>
            <h1>Settings</h1>
            <Container>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    spacing={2}
                >
                    <GridItem xs={6}>
                        <Box p={3}>
                            <CardTitle>Personal Details</CardTitle>
                            <form onSubmit={submitForm} className={classes.formMargin}>
                                {FILTERS.map(({ key, name }) => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id={key}
                                        margin="normal"
                                        name={key}
                                        label={name}
                                        value={form[key]}
                                        onChange={updateField}
                                    />
                                ))}
                                <Box mt={4} justifyContent="center" display="flex">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        label="Submit"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </GridItem>
                    <GridItem xs={6}>
                        <Box p={3}>
                            <CardTitle>Reset Password</CardTitle>
                            <form onSubmit={submitPwForm} className={classes.formMargin}>
                                {PW_FIELDS.map(({ key, name }) => (
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id={key}
                                        margin="normal"
                                        name={key}
                                        label={name}
                                        value={pwForm[key]}
                                        onChange={updatePwField}
                                    />
                                ))}
                                <Box mt={4} justifyContent="center" display="flex">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        label="Submit"
                                        type="submit"
                                    >
                                        Reset
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </GridItem>
                    <GridItem xs={12}>
                        <Box p={3}>
                            <CardTitle>Threshold Adjustment</CardTitle>
                            <form onSubmit={submitPwForm} className={classes.formMargin}>
                                <Slider
                                    {...{
                                        label: "Internal Quality Threshold",
                                        min: 0,
                                        max: 5,
                                        initialValue: thresholdForm.quality,
                                        onChange: val => updateSlider("quality", val)
                                    }}
                                />
                                <Slider
                                    {...{
                                        label: "Poor Services/Property Threshold",
                                        min: 0,
                                        max: 10,
                                        step: 1,
                                        initialValue: thresholdForm.centralization,
                                        onChange: val => updateSlider("centralization", val)
                                    }}
                                />
                                <Slider
                                    {...{
                                        label: "Industry:Internal Rating Threshold",
                                        min: 0,
                                        max: 5,
                                        initialValue: thresholdForm.services,
                                        onChange: val => updateSlider("services", val)
                                    }}
                                />
                                <Box mt={4} justifyContent="center" display="flex">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        label="Submit"
                                        type="submit"
                                    >
                                        Set
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </GridItem>
                </Grid>
            </Container>
        </Box>
    );
}
