import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Slider from "../../../components/Slider";

const useStyles = makeStyles(theme => ({
    filterContainer: {
        width: 400,
        backgroundColor: "white"
    },
    formMargin: {
        margin: theme.spacing(3)
    }
}));

const FILTERS = [
    {
        key: "name",
        label: "Name"
    },
    {
        key: "property",
        label: "Property"
    },
    {
        key: "status",
        label: "Status"
    }
];

const INITIAL_PM_RATING = 3;

export default function PMFilter() {
    const initialTextFormState = FILTERS.map(({ key }) => ({
        [key]: ""
    })).reduce((prevVal, currVal) => ({ ...prevVal, ...currVal }));

    const initialSliderFormState = { slider: INITIAL_PM_RATING };

    const initialFormState = { ...initialTextFormState, ...initialSliderFormState };

    const [form, setState] = useState(initialFormState);

    const onSubmit = e => {
        e.preventDefault();
        // DATA STITCH
        // send form values to refetch query on Preferred Vendors page with filters
        console.log(form);
    };

    const updateField = e => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const updateSlider = val => {
        setState({
            ...form,
            slider: val
        });
    };

    const classes = useStyles();

    return (
        <Box className={classes.filterContainer} m={3} p={1} boxShadow={2}>
            <Box m={3}>
                <Typography variant="subtitle2">Filter Property Managers</Typography>
            </Box>
            <form onSubmit={onSubmit} className={classes.formMargin}>
                <Box pt={2} pb={1}>
                    <Slider
                        {...{
                            label: "Average Rating",
                            min: 0,
                            max: 5,
                            initialValue: INITIAL_PM_RATING,
                            onChange: updateSlider
                        }}
                    />
                </Box>
                {FILTERS.map(({ key, label }) => (
                    <Box pt={2} pb={1}>
                        <TextField
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            key={key}
                            id={key}
                            variant="outlined"
                            name={key}
                            label={label}
                            value={form[key]}
                            onChange={updateField}
                        />
                    </Box>
                ))}
                <Box mt={4} justifyContent="center" display="flex">
                    <Button variant="outlined" label="Submit" type="submit">
                        Search
                    </Button>
                </Box>
            </form>
        </Box>
    );
}
