import React from "react";
import { Paper, Typography } from "@material-ui/core";
import firebase from "firebase/app";
import { useSignUpStyles } from "./useSignUpStyles";

export default function SignUpContainer({ children, vendor }) {
    const classes = useSignUpStyles();

    return (
        <div className={classes.container}>
            <Paper className={classes.paper}>
                <Paper className={classes.paperForText} elevation={0}>
                    <Typography variant="h4" gutterBottom>
                        Connecting Property Managers With Trusted Vendors.
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                        {vendor
                            ? "Property Managers are looking for you, preregister and grow your business with us."
                            : "Join VendorPM and find vendors for all your building service needs with confidence."}
                    </Typography>
                    <Typography variant="subtitle2">
                        By signing up for VendorPM, you agree to our{" "}
                        <a
                            href="/terms-and-conditions"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={classes.termsAndConditionsLink}
                        >
                            Terms of use.
                        </a>
                    </Typography>
                </Paper>
                {children}
            </Paper>
        </div>
    );
}
