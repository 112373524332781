import { makeStyles } from "@material-ui/core/styles";

export const useSignUpStyles = makeStyles(theme => ({
    container: {
        background:
            "linear-gradient(135deg, rgb(179, 238, 255) 0%, rgb(120, 200, 235) 15%, #0a7ae0 75%, #0974e2 100%)",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        minHeight: "100%",
        paddingTop: 40,
        position: "static",
        textAlign: "center",
        paddingBottom: 40,
    },

    toggleContainer: {
        paddingLeft: 16,
        marginBottom: 16,
        "& span": {
            fontWeight: 600,
        },
    },

    paper: {
        display: "flex",
        flexDirection: "row",
        width: 900,
        minHeight: 680,
        margin: "0 auto",
        borderRadius: 0,
        padding: 0,
        "@media (orientation: portrait) ": {
            width: "100%",
        },
        "@media(max-width: 500px)": {
            width: "100%",
            minWidth: 320,
            height: "auto",
            display: "block",
        },
    },

    paperForText: {
        backgroundColor: theme.palette.primary.dark,
        color: "#fff",
        textAlign: "left",
        padding: 64,
        paddingRight: 80,
        borderRadius: 0,
        width: 440,
        "@media(max-width: 500px)": {
            width: "100%",
            minWidth: 320,
            display: "inline-block",
        },
        "& h4": {
            fontWeight: 600,
        },
        "& h6": {
            marginBottom: 24,
        },
    },

    paperForFields: {
        padding: "64px 94px",
        width: 460,
        "@media (orientation: portrait) ": {
            width: 400,
            padding: "64px 64px",
        },
        "@media(max-width: 500px)": {
            width: "100%",
            paddingLeft: 16,
            paddingRight: 16,
            maxWidth: 280,
            margin: "0 auto",
            display: "inline-block",
        },
    },

    inputPrompt: {
        textAlign: "left",
        display: "block",
        width: "100%",
    },

    pickTypeTabs: {
        borderRadius: 3,
        overflow: "hidden",
        marginBottom: 16,
        color: theme.palette.primary.main,
    },

    pickTypeButton: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
    },

    selectedPickTypeButton: {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        "&:hover": {
            backgroundColor: theme.palette.primary.main,
        },
    },

    orDivider: {
        display: "inline-block",
        width: 54,
        verticalAlign: "middle",
        marginTop: 24,
        marginBottom: 24,
        marginLeft: 8,
        marginRight: 8,
    },

    basicInfoField: {
        marginBottom: 12,
    },

    signInWithGoogleButton: {
        marginTop: 8,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,

        "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
        },
    },

    signUpButton: {
        marginTop: 16,
        borderColor: theme.palette.primary.main,
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        marginBottom: 8,
        "&:hover": {
            backgroundColor: theme.palette.primary.dark,
        },
    },

    termsAndConditionsLink: {
        color: "#fff",
        "&:hover": {
            color: "rgba(255,255,255,0.5)",
        },
    },
}));
