import { loginWithGoogle, signUpWithEmail } from "../../lib/signupHelpers";
import React from "react";
import { makeSetterForProperty } from "../../lib/helpers";
import CredentialsView from "./CredentialsView";
import PasswordField from "../PasswordField";
import firebase from "firebase";

export default function SignupCredentials({ state, setState, children, onSignUp, ...props }) {
    const [passwordsMatch, setPasswordsMatch] = React.useState(false);
    const setError = makeSetterForProperty(state, setState, "ErrorMsg");
    const setEmail = makeSetterForProperty(state, setState, "email");
    const currentUser = firebase.auth().currentUser;

    const doSubmitAction = () => {
        if (passwordsMatch) {
            signUpWithEmail(
                state.email,
                state.password,
                user => onSignUp(user.uid),
                e => setError(e.message)
            );
        }
    };

    const onGoogleSignIn = () => {
        loginWithGoogle(async user => {
            setEmail(user.email);
            try {
                await onSignUp(user.uid);
            } catch (e) {
                setError("Registration Failed");
            }
        });
    };

    return (
        <CredentialsView
            {...{ state, setEmail, doSubmitAction, onGoogleSignIn, ...props }}
            submitText="Sign Up"
        >
            <PasswordField
                confirm={true}
                passwordsMatch={setPasswordsMatch}
                onKeyDown={async e => {
                    if (e.keyCode === 13) {
                        if (currentUser) {
                            window.location.href = "/account";
                        } else {
                            doSubmitAction();
                        }
                    }
                }}
                {...{
                    password: state.password,
                    setPassword: makeSetterForProperty(state, setState, "password"),
                    email: state.email
                }}
            />
        </CredentialsView>
    );
}
