import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default ({ high }) => (
    <CircularProgress
        style={{
            marginTop: high ? 60 : 240,
            marginBottom: 300,
            marginLeft: "calc(50% - 32px)"
        }}
    />
);
