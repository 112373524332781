const uglyFeatureFlags = {
    showSearchSidebar: true,
    AssociationPicker: false,
    showAccountSettings: false,
    showAccountSettingsNoticeCard: false,
    areYouAVendorButton: true,
    AvailabilityPickerInSignUp: false,
    websiteInVendorProfileSidebar: true,
    claimedBanner: false,
    editReviewButton: false,
    requireThreeVendorSelectionsForQuoteRequests: false,
    profile: {
        share: false,
        save: false
    },
    pmsCanAddVendor: false
};

export default uglyFeatureFlags;
