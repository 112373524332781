import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/Button";
import Dialog from "@mui/Dialog";
import DialogActions from "@mui/DialogActions";
import DialogContent from "@mui/DialogContent";
import DialogTitle from "@mui/DialogTitle";
import { listOfCategories } from "lib/serviceSearch";
import ServiceList from "./ServiceList";

const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "fit-content"
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120
    },
    formControlLabel: {
        marginTop: theme.spacing(1)
    }
}));

export default function ServicePickerModal({ open, setOpen, services, onServicePick }) {
    const classes = useStyles();
    const [] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title">
                    What service would you like to get quotes for?
                </DialogTitle>
                <DialogContent>
                    <ServiceList
                        listOfCategories={Object.fromEntries(
                            Object.entries(listOfCategories).map(([c, cServices]) => [
                                c,
                                cServices.filter(s => services.indexOf(s) !== -1)
                            ])
                        )}
                        makeLinkProps={service => ({
                            onClick: () => {
                                console.log(`picked ${service}`);
                                onServicePick(service);
                            }
                        })}
                        flowCategories
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
