import React, { useState, useEffect } from "react";
import { Grid, Paper, Container, Typography, TextField, Button } from "@material-ui/core";
// import Pagination from "@material-ui/lab/Pagination";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";

import { useQuery } from "@apollo/react-hooks";
import { FIND_VENDORS } from "../../lib/apollo/queries";
import { useVendorSearchStyles } from "./vendorList/useVendorSearchStyles";
import VendorSearchControls from "./vendorList/VendorSearchControls";
import uglyFeatureFlags from "../../lib/uglyFeatureFlags";
import Loading from "../../components/Loading";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { CategorySearchVendorListItem } from "./vendorList/CategorySearchVendorListItem";
import { AllSearchVendorListItem } from "./vendorList/AllSearchVendorListItem";

const SEARCH_DEBOUNCE_MS = 400;

// TODO: also needs to sort selected vendor to top if it came from get quote before selecting a service
// also pagination
export default function VendorsSearch() {
    const { service_id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const [showAll, setShowAll] = useState(false);

    const service = decodeURIComponent(service_id);
    const classes = useVendorSearchStyles();
    const [doSearchTimeout, setDoSearchTimeout] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [vendorsInState, setVendors] = useState([]);

    const queryVariables = {
        variables: {
            find: {
                company_name: searchText,
                service: service_id ? service : undefined
            },
            pageNum: 0
        }
        // onCompleted: data => {
        //     // setVendorList(
        //     //     data.findVendors.sort((v1, v2) => {
        //     //         const reviewDelta =
        //     //             v2.reviews_objs.length - v1.reviews_objs.length;
        //     //         if (reviewDelta !== 0) return reviewDelta;
        //     //         return v2.logo === "false" ? -1 : 1;
        //     //     })
        //     // );
        // }
    };

    const { data, loading, error, fetchMore } = useQuery(FIND_VENDORS, queryVariables);
    const vendorList = data?.findVendors?.vendorResults;

    // React.useEffect(() => {
    //     if (doSearchTimeout) {
    //         clearTimeout(doSearchTimeout);
    //     }
    //     const variables = {
    //         ...queryVariables,
    //         variables: {
    //             find: {
    //                 company_name: searchText,
    //                 service: service_id ? service : undefined,
    //             },
    //         },
    //     };
    //     const id = setTimeout(() => {
    //         doSearch(variables);
    //         setDoSearchTimeout(null);
    //     }, SEARCH_DEBOUNCE_MS);
    //     setDoSearchTimeout(id);
    //     return () => clearTimeout(id);
    // }, [searchText, service]);

    const searchResultSize = uglyFeatureFlags.showSearchSidebar
        ? { xs: 12, sm: 11, md: 8, lg: 8 }
        : { xs: 12 };
    const searchingVendors = location && location.pathname === "/search-vendors";
    const viewingByService = !searchingVendors;

    return (
        <React.Fragment>
            <Container maxWidth="lg">
                <Typography variant="h4" component="div" className={classes.header}>
                    Vendors In Toronto, Ontario
                </Typography>
                {viewingByService && (
                    <Typography variant="h6" component="div" className={classes.subheader}>
                        Find vendors providing {service}
                    </Typography>
                )}
                <Grid container justify="center">
                    <Grid item xs={12} sm={11} md={8} lg={7} style={{ marginTop: 28 }}>
                        <Paper elevation={0}>
                            <TextField
                                id="searchText"
                                variant="outlined"
                                className={classes.textField}
                                margin="normal"
                                fullWidth
                                label="Search All Vendors"
                                value={searchText}
                                onChange={e => {
                                    setSearchText(e.target.value);
                                }}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            <div
                style={{
                    background: "white",
                    marginTop: 16,
                    paddingBottom: 40,
                    paddingTop: 8
                }}
            >
                <Container maxWidth="lg">
                    <Grid container justify="center">
                        <Grid item xs={12} lg={10} md={11}>
                            <Grid container direction="row-reverse" justify="center">
                                {uglyFeatureFlags.showSearchSidebar && (
                                    <Grid item xs={12} sm={4} md={4} lg={4}>
                                        <VendorSearchControls
                                            viewingByService={viewingByService}
                                            pickedVendors={vendorList?.filter(v => v.selected)}
                                            vendorListLength={vendorList?.length}
                                            service={service}
                                            searchText={searchText}
                                            loading={loading}
                                        />
                                    </Grid>
                                )}

                                <Grid
                                    item
                                    {...searchResultSize}
                                    style={{
                                        position: "relative",
                                        justifyContent: "flex-start",
                                        // -8pm marginTop so it matches the margin between
                                        // the search bar and end of the colored background portion
                                        marginTop: -8
                                    }}
                                >
                                    {loading && <Loading high />}
                                    {(!!vendorList?.length || loading) && (
                                        <Grid container style={{ minHeight: 160 }}>
                                            {vendorList
                                                ?.slice(0, showAll ? vendorList.length : 15)
                                                .map((item, index) => {
                                                    const props = {
                                                        claimable:
                                                            !item.user && vendorList.length < 5,
                                                        key: item._id,
                                                        item
                                                    };

                                                    return searchingVendors ? (
                                                        <AllSearchVendorListItem
                                                            {...props}
                                                            refetchQueries={[
                                                                {
                                                                    query: FIND_VENDORS,
                                                                    variables:
                                                                        queryVariables.variables
                                                                }
                                                            ]}
                                                        />
                                                    ) : (
                                                        <CategorySearchVendorListItem {...props} />
                                                    );
                                                })}

                                            {!vendorList?.length &&
                                                searchingVendors &&
                                                false &&
                                                !loading && (
                                                    <Link
                                                        className={classes.floatingButtonContainer}
                                                        to={`/vendor-profile/${searchText}/review/new`}
                                                    >
                                                        <Typography color="primary">
                                                            Add A New Company To Review
                                                        </Typography>
                                                    </Link>
                                                )}
                                        </Grid>
                                    )}

                                    <div
                                        style={{ display: "flex", justifyContent: "space-between" }}
                                    >
                                        <Button
                                            color="primary"
                                            style={{
                                                marginLeft: "auto",
                                                textAlign: "right",
                                                margin: 12
                                            }}
                                            startIcon={<FiArrowLeft size={16} />}
                                            size="small"
                                            disabled={!data?.findVendors?.currentPage || loading}
                                            onClick={() =>
                                                fetchMore({
                                                    variables: {
                                                        pageNum:
                                                            -1 +
                                                            (data?.findVendors?.currentPage || 0)
                                                    },
                                                    updateQuery: (
                                                        prev,
                                                        { fetchMoreResult, ...rest }
                                                    ) => {
                                                        if (!fetchMoreResult) return prev;
                                                        if (
                                                            !fetchMoreResult?.findVendors
                                                                ?.vendorResults
                                                        )
                                                            return prev;

                                                        return {
                                                            ...fetchMoreResult,
                                                            vendorResults: [
                                                                ...prev.findVendors.vendorResults,
                                                                ...fetchMoreResult.findVendors
                                                                    .vendorResults
                                                            ]
                                                        };
                                                    }
                                                })
                                            }
                                        >
                                            Previous Page
                                        </Button>
                                        <Typography
                                            variant="caption"
                                            style={{
                                                margin: 12,
                                                display: "inline-block",
                                                paddingTop: 8
                                            }}
                                        >
                                            Page {(data?.findVendors?.currentPage || 0) + 1}
                                        </Typography>
                                        <Button
                                            color="primary"
                                            style={{
                                                marginLeft: "auto",
                                                textAlign: "right",
                                                margin: 12
                                            }}
                                            endIcon={<FiArrowRight size={16} />}
                                            disabled={loading}
                                            size="small"
                                            onClick={() =>
                                                fetchMore({
                                                    variables: {
                                                        pageNum:
                                                            1 +
                                                            (data?.findVendors?.currentPage || 0)
                                                    },
                                                    updateQuery: (
                                                        prev,
                                                        { fetchMoreResult, ...rest }
                                                    ) => {
                                                        if (!fetchMoreResult) return prev;

                                                        return {
                                                            ...fetchMoreResult,
                                                            vendorResults: [
                                                                ...prev.findVendors.vendorResults,
                                                                ...fetchMoreResult.findVendors
                                                                    .vendorResults
                                                            ]
                                                        };
                                                    }
                                                })
                                            }
                                        >
                                            Next Page
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </React.Fragment>
    );
}
