import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import camelCase from "lodash-es/camelCase";
import CSVReader from "react-csv-reader";
import InputLabel from "@material-ui/core/InputLabel";
import EmailValidator from "email-validator";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles(theme => ({
    formElement: {
        marginTop: theme.spacing(2)
    }
}));

const defaultData = {
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    phoneNumber: "",
    vendors: [],
    pms: []
};

function CsvUpload({ fieldName, onUpload }) {
    return (
        <Box p={1} mt={2} mb={2}>
            <Box pb={2}>
                <InputLabel>{fieldName}</InputLabel>
            </Box>
            <CSVReader parserOptions={{ header: true }} onFileLoaded={data => onUpload(data)} />
        </Box>
    );
}

export default function EnterpriseEnrollment() {
    const classes = useStyles();
    const [data, setData] = useState(defaultData);
    const [error, setError] = useState(null);

    const handleSubmit = event => {
        setError(null);
        event.preventDefault();
        console.log("Form submitted!", data);
    };

    const formPropsFor = (label, type = "text") => {
        const valueName = camelCase(label);
        return {
            label,
            type,
            required: true,
            fullWidth: true,
            variant: "outlined",
            value: data[valueName],
            onChange: event => setData({ ...data, [valueName]: event.target.value }),
            className: classes.formElement
        };
    };

    const setVendorData = data => {
        const isClean = data.map(({ email }) => EmailValidator.validate(email)).every(item => item);
        if (isClean) {
            setError(null);
            setData({ ...data, vendors: data });
        } else {
            setError("All emails need to be valid in Vendors CSV. Please re-upload.");
        }
    };

    const setPmData = data => {
        const isClean = data.map(({ email }) => EmailValidator.validate(email)).every(item => item);
        if (isClean) {
            setError(null);
            setData({ ...data, pms: data });
        } else {
            setError("All emails need to be valid in Property Managers CSV. Please re-upload.");
        }
    };

    return (
        <Box m={4}>
            <Container maxWidth="md">
                <Paper elevation={3}>
                    <Box p={5}>
                        <Typography variant="h4">Enroll an Enterprise</Typography>
                        <Typography variant="subtitle1">
                            Please provide all the required details to register your business with
                            us.
                        </Typography>
                        <form onSubmit={handleSubmit} className={classes.form}>
                            <TextField {...formPropsFor("Enterprise Name")} />
                            <TextField {...formPropsFor("First Name")} />
                            <TextField {...formPropsFor("Last Name")} />
                            <TextField {...formPropsFor("Email")} />
                            <TextField {...formPropsFor("Phone Number", "tel")} />
                            <CsvUpload fieldName="Vendors CSV" onUpload={setVendorData} />
                            <CsvUpload fieldName="Property Managers CSV" onUpload={setPmData} />
                            {error && <FormHelperText error>{error}</FormHelperText>}
                            <Button
                                variant="contained"
                                type="submit"
                                color="primary"
                                fullWidth
                                className={classes.formElement}
                            >
                                Submit
                            </Button>
                        </form>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
}
