import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Button, Divider, TextField } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    detailsPane: {
        width: 400
    },
    redButton: {
        borderColor: "red",
        color: "red"
    },
    disabled: {
        color: "black"
    }
}));

const FIELDS = [
    { key: "role", label: "Role" },
    { key: "email", label: "Email" },
    { key: "phone", label: "Phone" },
    { key: "twitter", label: "Twitter" },
    { key: "location", label: "Location" },
    { key: "bio", label: "Bio" }
];

export default function VendorDetails({ vendor, closeVendorDetails }) {
    const classes = useStyles();
    const { id, role, email, phone, twitter, location, bio } = vendor;
    const initialFormState = { role, email, phone, twitter, location, bio };
    const [form, setState] = useState(initialFormState);
    const [editing, setEditing] = useState(false);

    const saveVendor = e => {
        e.preventDefault();
        // DATA STITCH
        // send form values to save vendor query
        console.log(form);
    };

    const removeVendor = () => {
        // DATA STITCH
        // query to remove vendor by ID
        console.log(id);
        closeVendorDetails();
    };

    const updateField = e => {
        setState({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const toggleEditing = () => {
        setEditing(prevState => !prevState);
    };

    return (
        <Box className={classes.detailsPane} p={2} pt={5}>
            <Typography variant="h4" align="center">
                {vendor.firstName}
            </Typography>
            <Typography variant="subtitle1" align="center">
                {vendor.role}
            </Typography>
            <form onSubmit={saveVendor}>
                <Box flexDirection="row" justifyContent="space-evenly" display="flex" p={2}>
                    <Box display="flex" flex={1} p={1}>
                        <Button
                            p={2}
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={toggleEditing}
                            type={editing ? "button" : "submit"}
                        >
                            {editing ? "Save Profile" : "Edit Profile"}
                        </Button>
                    </Box>
                    <Box display="flex" flex={1} p={1}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            className={classes.redButton}
                            fullWidth
                            onClick={removeVendor}
                            type="button"
                        >
                            Remove Vendor
                        </Button>
                    </Box>
                </Box>
                <Divider />
                <Box p={2}>
                    {FIELDS.map(({ key, label }) => (
                        <TextField
                            fullWidth
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{
                                disableUnderline: !editing,
                                classes: {
                                    disabled: classes.disabled
                                }
                            }}
                            key={key}
                            id={key}
                            margin="normal"
                            disabled={!editing}
                            variant={"standard"}
                            name={key}
                            label={label}
                            value={form[key]}
                            onChange={updateField}
                        />
                    ))}
                </Box>
            </form>
        </Box>
    );
}
