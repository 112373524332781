import gql from "graphql-tag";

// @client get from localState cache
// @connection get from server

// @include takes 'if' argument
// @skip takes if argument
// exaample:
// query Hero($episode: Episode, $withFriends: Boolean!) {
//     hero(episode: $episode) {
//       name
//       friends @include(if: $withFriends) {
//         name
//       }
//     }
//   }

export const GET_USER_FROM_TOKEN = gql`
    query getUserFromToken($token: String!) {
        getUserFromToken(token: $token) {
            _id
            phone_number
            email
        }
    }
`;

export const GET_VENDOR = gql`
    query getVendor($vendor_id: String) {
        getVendor(_id: $vendor_id) {
            _id
            selected @client
            company_name
            website
            availability
            address {
                postal_code
                street
                street_number
                city
                province
            }
            description
            services
            associations
            compliances
            reviews_objs {
                _id
                rating
                created_date
                anonymous
                description
                reviewer_obj {
                    _id
                    email
                    reviews {
                        _id
                    }
                    name {
                        first
                        last
                    }
                    photo_url
                }
            }
            rfq_objs {
                _id
                service
                submission_date
                deadline_date
                priority
                scope_of_work
                notes
                closed
                attachments
                my_response {
                    status
                }
                pm {
                    email
                    name {
                        first
                        last
                    }
                    photo_url
                    phone_number
                }
            }
            employee_count
            price
            claimed
        }
    }
`;

export const FIND_VENDORS = gql`
    query findVendors($find: VendorQueryInput, $pageNum: Int) {
        findVendors(find: $find, pageNum: $pageNum) {
            vendorResults {
                _id
                selected @client
                company_name
                website
                logo
                services
                user
                description
                reviews_objs {
                    _id
                    rating
                    description
                }
                associations
                compliances
                address {
                    postal_code
                    street
                    street_number
                    city
                    province
                }
            }
            currentPage
        }
    }
`;

export const GET_ALL_VENDORS = gql`
    query getAllVendors {
        getAllVendors {
            _id
            created_date
            email
            phone
            company_name
            services
            website
            claimed
            address {
                postal_code
                street_number
                street
                city
                province
            }
        }
    }
`;

export const GET_ALL_REVIEWS = gql`
    query getAllReviews {
        getAllReviews {
            _id
            vendor_company_name
            anonymous
            rating
            description
            created_date
            reviewer
        }
    }
`;

export const GET_ALL_RFQS = gql`
    query getAllRfqs {
        getAllRfqs {
            _id
            service
            submission_date
            deadline_date
            priority
            scope_of_work
            notes
            attachments
            pm_email
            pm_phone_number
            pm {
                email
                phone_number
            }
        }
    }
`;

export const GET_SELECTED_VENDORS = gql`
    query getSelectedVendors {
        getSelectedVendors @client
    }
`;

export const GET_ALL_USERS = gql`
    query getAllUsers {
        getAllUsers {
            _id
            uid
            join_date
            email
            phone_number
            is_vendor
            company_name
            name {
                first
                last
            }
            address {
                postal_code
                street_number
                street
                city
                province
            }
            reviews {
                _id
            }
        }
    }
`;
export const GET_USER = gql`
    query getUser {
        getUser {
            _id
            uid
            email
            email_verified
            admin
            join_date
            name {
                first
                last
            }
            phone_number
            photo_url
            is_vendor
            vendor
            reviews {
                _id
                rating
                created_date
                anonymous
                description
                vendor_obj {
                    company_name
                }
                reviewer_obj {
                    _id
                    email
                    reviews {
                        _id
                    }
                    name {
                        first
                        last
                    }
                    photo_url
                }
            }
            rfqs {
                _id
                service
                closed
                submission_date
                deadline_date
                scope_of_work
                notes
                priority
                attachments
                responses {
                    vendor_id
                    status
                    responded_date
                }
                pm {
                    email
                    email_verified
                    name {
                        first
                        last
                    }
                    photo_url
                    phone_number
                }
                vendors {
                    _id
                    company_name
                }
            }
            invitees {
                email
                phone
                joined
            }
        }
    }
`;

export const GET_ACTIVITY = gql`
    query getAllActivity {
        getAllActivity {
            _id
            action
            review {
                anonymous
                description
                rating
                created_date
                vendor_obj {
                    company_name
                    user
                }
                reviewer_obj {
                    email
                }
            }
            rfq {
                vendors {
                    company_name
                }
                service
                deadline_date
                submission_date
                notes
                priority
                scope_of_work
                pm {
                    email
                }
            }
            user {
                email
                name {
                    first
                    last
                }
                phone_number
            }
        }
    }
`;
// loggedIn @client
