import React, { useState } from "react";
import TrelloBoard from "../../../components/TrelloBoard";

const FAKE_DATA = {
    lanes: [
        {
            id: "vendors",
            title: "Vendors",
            cards: [
                {
                    id: "0",
                    title: "A+ Window Cleaning",
                    description: "3.74",
                    label: "Average Rating"
                },
                {
                    id: "1",
                    title: "Hulk's Caulking",
                    description: "3.11",
                    label: "Average Rating"
                },
                {
                    id: "2",
                    title: "Toronto Garage Cleaners",
                    description: "4.74",
                    label: "Average Rating"
                }
            ]
        },
        {
            id: "properties",
            title: "Properties",
            cards: [
                {
                    id: "4",
                    title: "123 Front St",
                    description: "5 Services",
                    label: "Under Threshold"
                },
                {
                    id: "5",
                    title: "80 Bay St",
                    description: "3 Services",
                    label: "Under Threshold"
                },
                {
                    id: "6",
                    title: "40 Hello St.",
                    description: "4 Services",
                    label: "Under Threshold"
                }
            ]
        },
        {
            id: "pms",
            title: "Property Managers",
            cards: [
                {
                    id: "7",
                    title: "Brett Johnson",
                    description: "3 Services",
                    label: "Industry Discrepancy"
                },
                {
                    id: "8",
                    title: "Hillary Smith",
                    description: "6 Services",
                    label: "Industry Discrepancy"
                }
            ]
        }
    ]
};

export default function ThreatsBoard() {
    const updateBoardData = newBoardData => {
        // DATA STITCH
        // update centralization board data
        console.log(`👨‍🌾 => `, newBoardData);
    };

    return (
        <TrelloBoard
            disableAdd
            {...{
                data: FAKE_DATA,
                updateData: updateBoardData
            }}
        />
    );
}
