import React from "react";
import { Container, Grid } from "@material-ui/core";

export default function VendorProfileWrapper({ children }) {
    return (
        <div
            style={{ background: "white", paddingTop: 20, paddingBottom: 160 }}
        >
            <Container maxWidth="lg">
                <Grid container justify="flex-start">
                    {children}
                </Grid>
            </Container>
        </div>
    );
}
