import {useMutation} from "react-apollo";
import {SELECT_VENDOR} from "../../../lib/apollo/mutations";
import React from "react";
import VendorListItem from "../../../components/VendorListItem";

export function CategorySearchVendorListItem(props) {
    const [selectVendor] = useMutation(SELECT_VENDOR, {
        variables: {_id: props.item._id}
    });
    return (
        <React.Fragment>
            <VendorListItem
                {...props}
                actions={{
                    [props.item.selected ? "Deselect" : "Get Quote"]: selectVendor
                }}
            />
        </React.Fragment>
    );
}