import camelCase from 'lodash-es/camelCase';

export const fieldProps = (label, type, fieldClass, data, setData) => {
  const valueName = camelCase(label);
  return {
    label,
    type,
    required: true,
    fullWidth: true,
    variant: 'outlined',
    value: data[valueName],
    onChange: (event => setData({ ...data, [valueName]: event.target.value })),
    className: fieldClass
  }
};