import React from "react";
import { Redirect } from "react-router-dom";
import firebase from "firebase/app";
import { SIGN_OUT } from "./lib/apollo/mutations";

export default function SignOutAndRedirect() {
    // TODO: reset the cache instead of reloading
    firebase
        .auth()
        .signOut()
        .then(() => (window.location.href = "/"));
    return <Redirect to={"/"} />;
}
