import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { ADMIN_DELETE_VENDOR } from "../lib/apollo/mutations";
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'

export default function DeleteVendorButton ({del, index}) {
    const [adminDeleteVendor] = useMutation(ADMIN_DELETE_VENDOR, {variables: {vendor: del._id}})
    return (
        <Button onClick={async () => await adminDeleteVendor().then(document.getElementById(index.toString()).style.display = 'none')}><DeleteIcon color='error' /></Button>
    )
}
