import React from 'react'
import {
    Paper,
    Typography,
    Container,
    Box,
    Grid,
    makeStyles,
    Tab,
    Tabs,
    withStyles,
    createStyles
} from "@material-ui/core";

export function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={3}>{children}</Box>}
        </Typography>
    );
}

export const StyledTab = withStyles(theme =>
    createStyles({
        root: {
            textTransform: "none",
            fontWeight: theme.typography.fontWeightBold,
            fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            "&:focus": {
                opacity: 1
            }
        }
    })
)(props => <Tab {...props} />);


export const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingTop: 80,
        paddingBottom: 200,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        "@media (max-width: 600px)": {
            paddingTop: 40
        }
    },

    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
        paddingBottom: 160,
        marginTop: 16,
        "& span": {
            alignItems: "flex-start"
        },
        "@media (max-width: 600px)": {
            paddingBottom: 80
        }
    },

    stepBox: {
        display: "inline-block",
        maxWidth: 240,
        marginTop: 72,
        marginLeft: 32,
        verticalAlign: "top",
        padding: 40
    },

    tableItem: {
        border: '1px solid #dddddd',
        fontSize: '1rem'
    }
}));

export function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        "aria-controls": `scrollable-auto-tabpanel-${index}`
    };
}