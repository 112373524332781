import request from "superagent";

const addressSearchUrl = `https://atlas.microsoft.com/search/fuzzy/json`;
const searchAddress = async address => {
    return request
        .get(addressSearchUrl)
        .set("x-ms-client-id", "")
        .query({
            query: address,
            "api-version": "1.0",
            "subscription-key": "hP7o-tSKZUCmacxrGL0HjpIifj-7SrqHauXWRS3AsAU",
            format: "json",
            countrySet: "CA",
            limit: 1,
        });
};

// TODO how do we han handle error cases?
export async function getAddress(address) {
    try {
        const response = await searchAddress(address);
        if (response.body.results && response.body.results.length) {
            return response.body.results[0];
        }
    } catch (e) {
        console.log({
            source: "mapboxApi searchAddress get",
            e,
        });
    }
}

export async function getFormattedAddress(unfrormattedAddress) {
    const fuzzySearchResult = await getAddress(unfrormattedAddress);
    if (!fuzzySearchResult) throw Error("No address returned");

    const {
        address: {
            extendedPostalCode: postal_code,
            streetName: street,
            streetNumber: street_number,
            municipality: city,
            countrySubdivisionName: province,
        },
    } = fuzzySearchResult;
    if (!(street && street_number && postal_code && city && province))
        return null;
    if (postal_code.includes(",")) return null;
    return { postal_code, street, street_number, city, province };
}

export function asReadableString(address) {
    if (Object.keys(address).length === 0) return "";
    const a = address;
    return `${a.street_number} ${a.street}, ${a.city}, ${a.province}, ${a.postal_code}`;
}
