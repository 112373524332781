import React, {useState} from "react";
import {useMutation} from "react-apollo";
import {UPDATE_VENDOR} from "../lib/apollo/mutations";
import Grid from "@material-ui/core/Grid";
import BasicInfo from "./sign_up/BasicInfo";
import {VerifiedAddressPicker} from "./VerifiedAddressPicker";
import AdditionalInfo from "../pages_vendor/vendor_sign_up/AdditionalInfo";
import Button from "@material-ui/core/Button";

export function EditVendorProfilePane({vendor, refetchQueries, onCompleted}) {
    const [state, setState] = useState({
        email: "",
        website: vendor.website || "",
        password: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        companyName: vendor.company_name,
        displayedFormattedAddress: vendor.address,
        phoneNumber: vendor.phone_number,
        availability: "Soon",
        services: vendor.services || [],
        associations: vendor.associations || [],
        compliances: vendor.compliances || [],
        description: vendor.description || "",
        employee_count: vendor.employee_count || "",
        logo: null
    });
    /*

     */
    const makeSetStateForProp = prop => value => setState({...state, [prop]: value});
    const setDisplayedFormattedAddress = makeSetStateForProp("displayedFormattedAddress");

    const [updateVendor] = useMutation(UPDATE_VENDOR, {
        variables: {
            vendor: {
                _id: vendor._id,
                address:
                    state.displayedFormattedAddress &&
                    Object.entries(state.displayedFormattedAddress).length
                        ? state.displayedFormattedAddress
                        : undefined,
                company_name: state.companyName,
                availability: state.availability,
                services: state.services,
                associations: state.associations,
                compliances: state.compliances,
                description: state.description,
                employee_count: state.employee_count,
                logo: state.logo
            }
        },
        refetchQueries,
        awaitRefetchQueries: true,
        onCompleted
    });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <BasicInfo {...{state, setState}} edit_page={true}>
                    <VerifiedAddressPicker
                        label={"Address*"}
                        address={vendor.address}
                        classes={{}}
                        addressPredicate={address => address.province === "Ontario"}
                        onAddress={(x) => {setDisplayedFormattedAddress(x)}}
                    />
                </BasicInfo>
            </Grid>
            <Grid item xs={12} md={6}>
                <AdditionalInfo {...{state, setState}} />
            </Grid>
            <Grid item xs={11}/>
            <Grid item xs={1}>
                <Button onClick={updateVendor} color="primary">
                    Save
                </Button>
            </Grid>
        </Grid>
    );
}