import React from "react";
import { Button, Divider, Typography, Grid } from "@material-ui/core";
import { Link, useParams, useLocation } from "react-router-dom";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/Error";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";
import { FiEdit, FiMessageSquare } from "react-icons/fi";
import uglyFeatureFlags from "../../lib/uglyFeatureFlags";
import ReviewSection from "./ReviewSection/";
import { useMutation } from "react-apollo";
import { CLEAR_SELECTED_VENDORS } from "../../lib/apollo/mutations";
import { Compliances } from "../../components/Compliances";
import { Associations } from "../../components/Associations";

const useStyles = makeStyles(theme => ({
    cardContainer: {
        marginRight: "auto",
        marginLeft: theme.spacing(2),
        paddingTop: 16,
        marginBottom: 20
    },

    companyName: {
        fontWeight: 600
    },

    claimed: {
        color: "#0073bb",
        cursor: "pointer"
    },

    serviceLink: {
        color: "#0073bb",
        textDecoration: "none",
        display: "inline-block",
        "&:hover": {
            color: "#1da1f2",
            textDecoration: "underline"
        }
    },

    services: {
        verticalAlign: "top",
        marginRight: 48,
        display: "inline-block"
    },

    verifiedCheck: {
        color: "#1da1f2"
    }
}));

export default function ProfileSection({
    company_name,
    _id,
    claimed,
    address,
    logo,
    selected,
    description,
    website,
    associations,
    compliances,
    services: servicesIn,
    user,
    reviews_objs,
    onClaimButtonPress,
    price,
    employee_count
}) {
    // TODO ensure vendors have some services
    const services = servicesIn || [];
    const reconstituded_address =
        (address?.street_number ? `${address?.street_number} ` : "") +
        `${address?.street}, ${address?.city}`;

    const classes = useStyles();
    const { vendor_id, token } = useParams();
    const location = useLocation();
    const claim = location.pathname.includes("claim");
    const [clearSelectedVendors] = useMutation(CLEAR_SELECTED_VENDORS);

    const image =
        logo && !(logo?.includes("null") || logo.includes("false")) ? logo : "/logoplaceholder.png";

    return (
        <Grid item sm={12} md={8}>
            <div className={classes.cardContainer}>
                <Grid container justify="space-between">
                    <Grid item sm={9}>
                        <Typography component="h4" variant="h4" className={classes.companyName}>
                            {company_name}
                        </Typography>
                    </Grid>
                    {claim && !claimed && (
                        <Grid
                            item
                            sm={3}
                            style={{
                                textAlign: "right",
                                display: "flex",
                                direction: "column",
                                alignItems: "flex-end"
                            }}
                        >
                            {onClaimButtonPress && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: "auto" }}
                                    onClick={onClaimButtonPress}
                                >
                                    Claim Your Business
                                </Button>
                            )}
                        </Grid>
                    )}
                    {claim && claimed && (
                        <Grid
                            item
                            sm={3}
                            style={{
                                textAlign: "right",
                                display: "flex",
                                direction: "column",
                                alignItems: "flex-end",
                                color:"red"
                            }}
                        >
                            <Typography>This business is already claimed</Typography>
                        </Grid>
                    )}
                </Grid>

                <div style={{ marginTop: 16 }}>
                    <Rating
                        value={
                            reviews_objs.map(r => r.rating).reduce((a, b) => a + b, 0) /
                                reviews_objs.length || 0
                        }
                        precision={0.1}
                        readOnly
                        style={{ verticalAlign: "text-bottom", marginRight: 8 }}
                    />
                    <Typography
                        variant="subtitle1"
                        style={{
                            display: "inline-block"
                        }}
                    >
                        ({reviews_objs.length} reviews)
                    </Typography>
                </div>
                <div style={{ marginTop: 8 }}>
                    <Typography variant="h5">Description</Typography>
                    <Typography variant="body1">
                    {description}
                    </Typography>
                </div>
                <div style={{ marginTop: 8 }}>
                    {/* <FiCheckCircle
                        size={18}
                        strokeWidth={3}
                        className={classes.verifiedCheck}
                    />
                    <Typography
                        variant="button"
                        style={{
                            display: "inline-block",
                            marginLeft: 8,
                            fontSize: 14,
                            color: "#1da1f2",
                            verticalAlign: "text-bottom",
                            fontWeight: 700,
                            textTransform: "capitalize",
                        }}
                    >
                        Verified WSIB &nbsp;|&nbsp; Verified Insurance &nbsp;
                    </Typography> */}
                    <Typography variant="body1" color="textSecondary" className={classes.services}>
                        {services.map((s, i) => (
                            <span key={s + i}>
                                <Link
                                    onClick={clearSelectedVendors}
                                    to={`/vendors-of/${encodeURIComponent(s)}`}
                                    className={classes.serviceLink}
                                >
                                    {s}
                                </Link>
                                {i === services.length - 1 ? "" : ", "}
                            </span>
                        ))}
                    </Typography>
                </div>
                <div style={{ marginTop: 8 }}>
                    {
                        address.street &&
                        <Typography variant="body1" className={classes.services}>
                            {address && reconstituded_address}
                        </Typography>
                    }
                </div>
                {
                employee_count &&
                <div style={{ marginTop: 8 }}>
                    <Typography variant="body1" className={classes.services}>
                        Company Size: {employee_count}
                    </Typography>
                </div>
                }
                {
                price && 
                <div style={{ marginTop: 8 }}>
                    <Typography variant="body1" className={classes.services}>
                        Price: {price}
                    </Typography>
                </div>
                }
                <div style={{ marginTop: 8 }}>
                    <Associations association_objs={associations || []} className={classes.services}/>
                </div>
                <div style={{ marginTop: 8 }}>
                    <Compliances compliance_objs={compliances || []} className={classes.services}/>
                </div>
                <div style={{ marginTop: 8 }}>
                    {!user?.is_vendor &&
                        !reviews_objs.filter(review => review.reviewer_obj._id === user?._id)
                            .length && (
                            <Link
                                to={`/vendor-profile/${vendor_id}/review`}
                                style={{ textDecoration: "none" }}
                            >
                                <Button
                                    variant="outlined"
                                    style={{
                                        color: "#000",
                                        marginRight: 8,
                                        marginTop: 8
                                    }}
                                >
                                    <FiMessageSquare size={20} />
                                    &nbsp; Write A Review
                                </Button>
                            </Link>
                        )}

                    {uglyFeatureFlags.editReviewButton &&
                        !user?.is_vendor &&
                        !!reviews_objs.filter(review => review.reviewer_obj._id === user?._id)
                            .length && (
                            <Button
                                variant="outlined"
                                disabled
                                style={{
                                    marginRight: 8,
                                    marginTop: 8
                                }}
                            >
                                <FiEdit size={20} />
                                &nbsp; Edit Review
                            </Button>
                        )}

                    {/* <Button
                        variant="outlined"
                        style={{
                            marginRight: 8,
                            marginTop: 8,
                        }}
                    >
                        <FiShare />
                        &nbsp; Share
                    </Button>
                    <Button
                        variant="outlined"
                        style={{
                            marginRight: 8,
                            marginTop: 8,
                        }}
                    >
                        <FiFlag />
                        &nbsp; Save
                    </Button> */}
                </div>
                <Divider style={{ marginBottom: 12, marginTop: 12 }} />
            </div>

            <ReviewSection reviews={reviews_objs || []} />
        </Grid>
    );
}
