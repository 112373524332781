import React, { useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { Typography, Box, Button } from "@mui";
import TrelloCard from "components/TrelloCard";

const FAKE_DATA = {
    properties: ["300 King St.", "20 Yonge St."]
};

function OnHoverContent({ id }) {
    // DATA STITCH
    // use ID to populate properties
    return (
        <React.Fragment>
            <Typography color="inherit">Properties</Typography>
            {FAKE_DATA.properties.map((property, index) => (
                <div>
                    <b>{`Property ${index + 1} - `}</b>
                    <em>{property}</em>
                </div>
            ))}
        </React.Fragment>
    );
}

export default function CentralizationCard(props) {
    return <TrelloCard {...props} OnHoverContent={OnHoverContent} />;
}
