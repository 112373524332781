// @flow
import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link, useRouteMatch, withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import AppsIcon from "@material-ui/icons/Apps";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import AirportShuttleIcon from "@material-ui/icons/AirportShuttle";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import SettingsIcon from "@material-ui/icons/Settings";
import SpeedIcon from '@material-ui/icons/Speed';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { signOut } from '../lib/firebase';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginRight: 36
    },
    hide: {
        display: "none"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerClose: {
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: "hidden",
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9) + 1
        }
    },
    toolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    link: {
        textDecoration: "none",
        color: "inherit"
    }
}));

const FAKE_DATA = {
    enterpriseName: "Cadillac Fairview"
};

function EnterpriseNavigation({ children }) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const match = useRouteMatch();

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open
                        })}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        {FAKE_DATA.enterpriseName}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    })
                }}
            >
                <div className={classes.toolbar}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <Link to={match.path} className={classes.link}>
                        <ListItem button key={"Dashboard"}>
                            <ListItemIcon>
                                <AppsIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Dashboard"} />
                        </ListItem>
                    </Link>
                    <Link to={`${match.path}/pmlist`} className={classes.link}>
                        <ListItem button key={"Property Managers"}>
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Property Managers"} />
                        </ListItem>
                    </Link>
                    <Link to={`${match.path}/vendors`} className={classes.link}>
                        <ListItem button key={"Preferred Vendors"}>
                            <ListItemIcon>
                                <EqualizerIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Preferred Vendors"} />
                        </ListItem>
                    </Link>
                    <Link to={`${match.path}/centralization`} className={classes.link}>
                        <ListItem button key={"Centralization Opportunities"}>
                            <ListItemIcon>
                                <AirportShuttleIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Centralization"} />
                        </ListItem>
                    </Link>
                    <Link to={`${match.path}/threats`} className={classes.link}>
                        <ListItem button key={"Threats and Weaknesses"}>
                            <ListItemIcon>
                                <EmojiTransportationIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Threats"} />
                        </ListItem>
                    </Link>
                    <Link to={`${match.path}/settings`} className={classes.link}>
                        <ListItem button key={"Settings"}>
                            <ListItemIcon>
                                <SettingsIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Settings"} />
                        </ListItem>
                    </Link>
                    <Link to={`${match.path}/speed-rating`} className={classes.link}>
                        <ListItem button key={"Speed Rating"}>
                            <ListItemIcon>
                                <SpeedIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Speed Rating"} />
                        </ListItem>
                    </Link>
                    <Link to={`${match.path}/sign-in`} className={classes.link}>
                        <ListItem button key={"Sign In"}>
                            <ListItemIcon>
                                <LockOpenIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Sign In"} />
                        </ListItem>
                    </Link>
                    <Link onClick={signOut} className={classes.link}>
                        <ListItem button key={"Sign Out"}>
                            <ListItemIcon>
                                <ExitToAppIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Sign Out"} />
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
            <main className={classes.content}>{children}</main>
        </div>
    );
}

export default withRouter(EnterpriseNavigation);
