import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {
    ResponsiveContainer,
    ComposedChart,
    Bar,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Line,
    Legend
} from "recharts";

function ResponsiveBarChart({ data, bars, lines }) {
    return (
        <Box>
            <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                    data={data}
                    width="100%"
                    height="100%"
                    margin={{ top: 10, right: 30, left: 0, bottom: 10 }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={bars[0].key} />
                    <YAxis />
                    <Tooltip />
                    <Legend
                        iconType="circle"
                        // layout="vertical"
                        align="center"
                        wrapperStyle={{ paddingTop: 10 }}
                        // verticalAlign="middle"
                    />
                    <Legend />
                    {bars.map(({ key, fill }) => (
                        <Bar {...{ key, fill, dataKey: key, barSize: 10 }} />
                    ))}
                    {lines.map(({ key, fill }) => (
                        <Line type="monotone" key={key} dataKey={key} stroke={fill} />
                    ))}
                </ComposedChart>
            </ResponsiveContainer>
        </Box>
    );
}

export default memo(props => <ResponsiveBarChart {...props} />);
