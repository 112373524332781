import React from "react";

import CallToSearch from "./CallToSearch";
import Process from "./ThreeItemBlock";
import PopularCategories from "./PopularCategories";

function LandingPage({ pickView, render, setRender }) {
    return (
        <React.Fragment>
            <CallToSearch
                pickView={pickView}
                render={render}
                setRender={setRender}
            />{" "}
            <div style={{ background: "white", paddingBottom: 160 }}>
                <Process
                    items={
                        [
                            {
                                image: "process21.png",
                                label:
                                    "Thousands of Vendors, Ranked, Rated & Reviewed by Local Property Managers.",
                            },
                            {
                                image: "process22.png",
                                label: "We Connect Property Managers with Reputable Vendors.",
                            },
                            {
                                image: "process23.png",
                                label: "Proactive Service Planning & Quote Requests.",
                            },
                        ]
                    }
                />
                <PopularCategories />
            </div>
        </React.Fragment>
    );
}

export default LandingPage;
