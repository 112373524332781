import React, { useState } from "react";
import {
    Container,
    TextField,
    Typography,
    Button,
    Switch,
    FormGroup,
    FormControlLabel
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, Link, Redirect, useHistory } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CREATE_REVIEW } from "../lib/apollo/mutations";
import { GET_VENDOR, GET_USER } from "../lib/apollo/queries";
import Loading from "../components/Loading";
import ErrorMessage from "../components/Error";
import { ServiceMultiPicker } from "../components/ServiceMultiPicker";
import { login } from "../lib/apollo";
import { SnackBarContext } from "../components/SnackBarProvider";

const useStyles = makeStyles(theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "calc(100% - 16px)",
        maxWidth: 600
    },
    goddamnLinks: {
        textDecoration: "none",
        color: "#0073bb",
        "&:hover": {
            textDecoration: "underline"
        }
    },
    container: {
        marginTop: 64,
        maxWidth: 900,
        "@media (max-width: 500px)": {
            marginTop: 32
        }
    }
}));

export default function ReviewForm({ embedded = false }) {
    const classes = useStyles();
    const history = useHistory();
    const pushSnackBar = React.useContext(SnackBarContext);
    const { vendor_id, vendor_name } = useParams();
    const _id = decodeURIComponent(vendor_id);

    const userQuery = useQuery(GET_USER, {});

    const { data, loading, error } = useQuery(GET_VENDOR, {
        variables: {
            vendor_id
        },
        // Don't run the query if ReviewForm has a name instead of an id
        skip: !vendor_id
    });

    const [rating, setRating] = useState(0);
    const [description, setDescription] = useState("");
    const [anonymous, setAnonymous] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [vendorName, setVendorName] = React.useState(vendor_name);
    const [services, setServices] = React.useState([]);

    const variables = {
        variables: {
            review: {
                rating,
                description,
                vendor_id: _id,
                vendor_company_name: vendorName || (data && data.getVendor.company_name) || null,
                services,
                anonymous: anonymous
            }
        },

        onCompleted: data => {
            history.push(`/vendor-profile/${data.createReview._id}`);
        },
        onError: (err) => {pushSnackBar({severity: "error", message: "Error submitting review"})},
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: GET_USER
            },
            {
                query: GET_VENDOR,
                variables: {
                    vendor_id: _id
                }
            }
        ]
    };

    const [postReview, postReviewResponse] = useMutation(CREATE_REVIEW, variables);

    if (loading) return <Loading />;
    if (error) return <ErrorMessage message={error} />;

    if (userQuery?.data?.getUser?.is_vendor) return <Redirect to={`/vendor-profile/${_id}`} />;

    if (userQuery.data && !userQuery.data?.getUser) {
        console.log("Redirecting");
        return <Redirect to={`/sign-up/return`} />;
    }

    const body = (
        <React.Fragment>
            <div
                style={{
                    display: "inline-block",
                    marginLeft: 16
                }}
            >
                {embedded ? (
                    <Typography variant="h5" gutterBottom>
                        Leave a review
                    </Typography>
                ) : data && data.getVendor ? (
                    <Link to={`/vendor-profile/${vendor_id}`} className={classes.goddamnLinks}>
                        <Typography variant="h4" gutterBottom>
                            {vendor_name || data.getVendor.company_name}
                        </Typography>
                    </Link>
                ) : (
                    <TextField
                        id="vendorName"
                        variant="outlined"
                        margin="normal"
                        label="Vendor Business Name"
                        value={vendorName}
                        onChange={e => setVendorName(e.target.value)}
                    />
                )}
                {!vendor_id && (
                    <ServiceMultiPicker services={services} findOnly setServices={setServices} />
                )}
            </div>
            <div>
                <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                    <Typography
                        variant="subtitle2"
                        style={{
                            marginLeft: 16,
                            marginBottom: 16,
                            marginTop: 16
                        }}
                    >
                        Rate your Experience
                    </Typography>
                </div>
                <div style={{ display: "inline-block", verticalAlign: "middle" }}>
                    <Rating
                        value={rating}
                        onChange={(e, value) => {
                            setRating(value);
                        }}
                        precision={1}
                        defaultValue={0}
                        name="review-rating-form"
                        style={{
                            marginLeft: 16,
                            marginTop: 16,
                            marginBottom: 16
                        }}
                    />
                </div>
                <Typography style={{ marginLeft: 16 }} color="error">
                    {errorMsg}
                </Typography>
            </div>
            <div>
                <FormControlLabel
                    style={{ marginLeft: 4 }}
                    control={
                        <Switch
                            checked={anonymous}
                            onChange={() => setAnonymous(!anonymous)}
                            value="Anonymous"
                        />
                    }
                    label="Anonymous"
                />
            </div>
            <div>
                <TextField
                    rows={8}
                    multiline
                    id="review-description"
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    className={classes.textField}
                    placeholder="Describe Your Experience and Save Your Colleagues Even More Time and Stress"
                    margin="normal"
                    variant="outlined"
                />
            </div>{" "}
            <div>
                <Button
                    style={{ marginLeft: 8, marginTop: 16 }}
                    variant="outlined"
                    disabled={postReviewResponse.called}
                    onClick={() => {
                        if (rating !== 0 && !postReviewResponse.called) {
                            postReview();
                            pushSnackBar({ message: "Posting review", severity: "info" });
                        } else setErrorMsg("Please select a rating");
                    }}
                >
                    Submit Review
                </Button>
            </div>
        </React.Fragment>
    );

    if (embedded) return body;
    return <Container className={classes.container}>{body}</Container>;
}
