import React from "react";
import { Typography, Grid } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { makeStyles } from "@material-ui/core/styles";
import { format, formatDistance, formatRelative, subDays } from "date-fns";

const useStyles = makeStyles(theme => ({}));

export default function VendorProfileReviewItem({
    reviewer_obj,
    rating,
    anonymous,
    created_date,
    description,
    vendor_obj,
    _id,
    reviewerView
}) {
    return (
        <Grid container direction={"row"} style={{ paddingTop: 12, width: "100%" }}>
            <Grid
                item
                xs={12}
                sm={12}
                style={{ paddingBottom: 8, position: "relative", width: "100%" }}
            >
                <Typography variant="h6" display="inline">
                    {reviewerView
                        ? vendor_obj?.company_name
                        : anonymous
                        ? "Anonymous"
                        : reviewer_obj.name.first}
                </Typography>
                <Typography
                    style={{
                        marginLeft: 12,
                        position: "absolute",
                        right: 16,
                        top: 0
                    }}
                    display="inline"
                    variant="caption"
                >
                    {formatDistance(Date.parse(created_date), new Date())}
                </Typography>
                {anonymous || reviewerView ? null : (
                    <Typography variant="subtitle2" color="textSecondary" display="inline">
                        {" "}
                        &nbsp;&nbsp;&nbsp;
                        {reviewer_obj.reviews?.length} review
                        {reviewer_obj.reviews?.length > 1 && "s"}
                    </Typography>
                )}
            </Grid>
            <Grid item xs={12}>
                <Rating
                    name={"rating" + _id}
                    value={rating}
                    style={{ marginLeft: -4 }}
                    contentEditable={false}
                    precision={0.5}
                />
            </Grid>
            <Grid item xs={12} style={{ marginTop: 4 }}>
                <Typography variant="body1">{description}</Typography>
            </Grid>
        </Grid>
    );
}
