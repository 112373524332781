import React, { Fragment } from "react";
import Wrapper from "./Wrapper";
import ClaimBanner from "./ClaimBanner";
import ClaimSignUp from "./ClaimSignUp";
import ProfileSection from "./ProfileSection";
import Sidebar from "./Sidebar";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/Error";

import { useQuery } from "react-apollo";
import { GET_VENDOR, GET_USER } from "../../lib/apollo/queries";
import { useParams } from "react-router-dom";
import uglyFeatureFlags from "../../lib/uglyFeatureFlags";

export default function VendorProfile({ claim }) {
    const { vendor_id, token } = useParams();
    const [claimOpen, setClaimOpen] = React.useState(!!token);
    const variables = {
        variables: {
            vendor_id
        }
    };

    const { data, loading, error } = useQuery(GET_VENDOR, variables);
    const { data: userData } = useQuery(GET_USER);

    if (loading) return <Loading />;
    if (error) return <ErrorMessage message={error} />;

    return (
        <Fragment>
            {uglyFeatureFlags.claimBanner && <ClaimBanner claim />}
            <ClaimSignUp data={data} open={claimOpen} onClose={() => setClaimOpen(false)} />
            <Wrapper>
                <ProfileSection
                    {...data.getVendor}
                    user={userData?.getUser}
                    onClaimButtonPress={claim && (() => setClaimOpen(true))}
                />
                <Sidebar {...data.getVendor} />
            </Wrapper>
        </Fragment>
    );
}
