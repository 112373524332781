import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Paper } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    formContainer: {
        width: 600
    },
    formMargin: {
        margin: theme.spacing(3)
    },
    formControl: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        display: "flex",
        flex: 1
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    }
}));

export const PRIORITIES = [
    { key: "urgent", label: "Urgent" },
    { key: "upcoming", label: "Upcoming" },
    { key: "wishlist", label: "Wishlist" }
];

export const SERVICES = [
    { key: "balcony", label: "Balcony Glazing" },
    { key: "boiler", label: "Boiler" },
    { key: "caulking", label: "Caulking" }
];

const DEFAULT_PRIORITY = PRIORITIES[0];
const DEFAULT_SERVICE = SERVICES[0];

export default function AddServicePlanForm({ initialData, edit, submitForm }) {
    const { priority: initialPriority, service: initialService } = initialData;
    const classes = useStyles();
    const [formData, setFormData] = useState({
        priority: initialPriority ? initialPriority : DEFAULT_PRIORITY.key,
        service: initialService ? initialService : DEFAULT_SERVICE.key
    });
    const { priority, service } = formData;

    const onSubmit = e => {
        e.preventDefault();
        submitForm(formData);
    };

    const updateField = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Paper>
            <Box className={classes.formContainer}>
                <form onSubmit={onSubmit} className={classes.formMargin}>
                    <h1>{edit ? "Edit" : "Add"} a Service Plan</h1>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Priority</InputLabel>
                        <Select
                            labelId="priority"
                            id="priority"
                            key="priority"
                            name="priority"
                            value={priority}
                            onChange={updateField}
                        >
                            {PRIORITIES.map(({ key, label }) => (
                                <MenuItem value={key} key={key}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Service</InputLabel>
                        <Select
                            labelId="service"
                            id="service"
                            key="service"
                            name="service"
                            value={service}
                            onChange={updateField}
                        >
                            {SERVICES.map(({ key, label }) => (
                                <MenuItem value={key} key={key}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box mt={4} justifyContent="center" display="flex">
                        <Button variant="outlined" label="Submit" type="submit">
                            {edit ? "Save" : "Add"}
                        </Button>
                    </Box>
                </form>
            </Box>
        </Paper>
    );
}
