import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { setContext } from "apollo-link-context";
import { createHttpLink } from 'apollo-link-http';
import { useQuery as baseUseQuery, useMutation as baseUseMutation } from '@apollo/react-hooks';
import { default as baseGql } from 'graphql-tag';
import { idToken } from '../firebase';
import { SERVER_URI, DEVELOPMENT } from '../constants';

const cache = new InMemoryCache();

const authLink = setContext((operation, { headers }) => ({ headers: { ...headers, authorization: idToken() } }));

const httpLink = createHttpLink({ uri: SERVER_URI });
const link = ApolloLink.from([authLink, httpLink]);

const client = new ApolloClient({
  cache,
  link,
  connectToDevTools: DEVELOPMENT,
});

export const useQuery = (query, options) => baseUseQuery(query, { ...options, client });
export const useMutation = (mutation, options) => baseUseMutation(mutation, { ...options, client });
export const gql = baseGql;

export default client;