import React from "react";
import {
    Grid,
    Button,
    InputAdornment,
    TextField,
    Divider,
    Typography,
    CardMedia
} from "@material-ui/core";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_USER } from "../../lib/apollo/mutations";
import { GET_USER } from "../../lib/apollo/queries";
import { imgixUrlFrom } from "../../lib/helpers";
// import uuidv4 from "uuid/v4";
// var azure = require("azure-storage");

export default function ProfileInformationEditor({ user, setUser }) {
    const [localUser, setLocalUser] = React.useState(user);

    const updateUserVariables = {
        variables: {
            user: {
                name: {
                    first: localUser.name.first,
                    last: localUser.name.last
                },
                phone_number: localUser.phone_number
            }
        },
        refetchQueries: [
            {
                query: GET_USER
            }
        ]
    };

    const [updateUser] = useMutation(UPDATE_USER, updateUserVariables);

    const handleFileUpload = async e => {
        // const file = e.target.files[0];
        // const name = file.name;
        // const path = encodeURIComponent(uuidv4() + "xz-b" + name);
        // var blobService = azure.createBlobService(
        //     "DefaultEndpointsProtocol=https;AccountName=vendorpm;AccountKey=9wB1PYpZajjXqAypHk5IOgU1U6gEZclSl4Wp3YF+tut0gbcY9x95aOqgi8zA7E6Aa1m+6MajthdRoD9P2GWUSg==;EndpointSuffix=core.windows.net"
        // );
        // console.log(file);
        // blobService.createBlockBlobFromBrowserFile(
        //     "vendorpm",
        //     path,
        //     file,
        //     { blockSize: file.size },
        //     (error, result) => {
        //         console.log(result);
        //         if (error) {
        //             console.log("File upload failed");
        //             console.log(error);
        //         } else {
        //             updateUserVariables.variables.user.photo_url = result.name;
        //             updateUser(updateUserVariables);
        //         }
        //     }
        // );
        // blobService.createBlockBlobFromText(
        //     "vendorpm",
        //     path,
        //     await file.text(),
        //     { blockSize: file.size, contentSettings: { contentType: file.type } },
        //     (error, result) => {
        //         console.log(result);
        //         if (error) {
        //             console.log("File upload failed");
        //             console.log(error);
        //         } else {
        //             updateUserVariables.variables.user.photo_url = result.name;
        //             updateUser(updateUserVariables);
        //         }
        //     }
        // );
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h5" style={{ marginBottom: 24 }}>
                        Public Profile
                    </Typography>
                    <Divider style={{ marginBottom: 12 }} />
                </Grid>

                {localUser.is_vendor && (
                    <Grid item xs={12}>
                        <div
                            style={{ backgroundColor: "rgba(0,0,0,0.07)", display: "inline-block" }}
                        >
                            <CardMedia
                                title={"Company Logo"}
                                style={{
                                    width: 300,
                                    height: 260,
                                    backgroundSize: "cover",
                                    borderStyle: "solid",
                                    borderColor: "#eee"
                                }}
                                image={imgixUrlFrom(user.photo_url)}
                            />
                            <div style={{ backgroundColor: "white" }}>
                                <input
                                    style={{ display: "none" }}
                                    id="raised-button-file"
                                    onChange={handleFileUpload}
                                    type="file"
                                />
                                <label htmlFor="raised-button-file">
                                    <Button
                                        style={{ marginTop: 8 }}
                                        size="small"
                                        component="span"
                                        color="primary"
                                    >
                                        Upload New Logo
                                    </Button>
                                </label>
                                {/* <ButtononClick={}>
                                
                            </Button> */}
                            </div>
                        </div>
                    </Grid>
                )}
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="firstName"
                        label="First Name"
                        fullWidth
                        value={localUser.name.first}
                        onChange={e =>
                            setLocalUser({
                                ...localUser,
                                name: {
                                    ...localUser.name,
                                    first: e.target.value
                                }
                            })
                        }
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="lastName"
                        label="Last Name"
                        fullWidth
                        value={localUser.name.last}
                        onChange={e =>
                            setLocalUser({
                                ...localUser,
                                name: {
                                    ...localUser.name,
                                    last: e.target.value
                                }
                            })
                        }
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        id="phone"
                        label="Phone Number"
                        fullWidth
                        value={localUser.phone_number}
                        onChange={e =>
                            setLocalUser({
                                ...localUser,
                                phone_number: e.target.value
                            })
                        }
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        id="email"
                        label="Email"
                        fullWidth
                        value={localUser.email}
                        onChange={e =>
                            setLocalUser({
                                ...localUser,
                                email: e.target.value
                            })
                        }
                        InputProps={
                            localUser.email_verified
                                ? {
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <VerifiedUserIcon />
                                          </InputAdornment>
                                      )
                                  }
                                : {}
                        }
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setUser(localUser);
                            updateUser();
                        }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
