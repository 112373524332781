import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Table from "../../../components/Table";
import PMDetails from "./PMDetails";
import PMFilter from "./PMFilter";
import { Typography, Box, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddPropertyManagerForm from "./AddPropertyManagerForm";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const FAKE_DATA = {
    totalPM: 43,
    pms: [
        {
            firstName: "Bob",
            id: "12322",
            properties: 7,
            averageRating: 3.2,
            status: "A",
            email: "anna@email.com",
            phone: "+16479380024",
            twitter: "@gdi",
            location: "Toronto, Canada",
            bio: "this is a space for a bio."
        },
        {
            firstName: "Heather",
            id: "12323",
            properties: 10,
            averageRating: 4.1,
            status: "AAA",
            email: "anna@email.com",
            phone: "+16479380024",
            twitter: "@gdi",
            location: "Toronto, Canada",
            bio: "this is a space for a bio."
        },
        {
            firstName: "Eagle",
            id: "12324",
            properties: 12,
            averageRating: 3.7,
            status: "A",
            email: "anna@email.com",
            phone: "+16479380024",
            twitter: "@gdi",
            location: "Toronto, Canada",
            bio: "this is a space for a bio."
        },
        {
            firstName: "Emily",
            id: "12325",
            properties: 4,
            averageRating: 4.2,
            status: "AA",
            email: "anna@email.com",
            phone: "+16479380024",
            twitter: "@gdi",
            location: "Toronto, Canada",
            bio: "this is a space for a bio."
        }
    ]
};

const COLUMNS = [
    { key: "firstName", name: "Contact", align: "left" },
    { key: "properties", name: "Properties", align: "left" },
    { key: "averageRating", name: "Average Rating", align: "center" },
    { key: "status", name: "Status", align: "center" }
];

export default function PropertyManagers() {
    const [showDetails, setShowDetails] = useState(false);
    const [selected, setSelected] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const classes = useStyles();

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const openPropertyManagerDetails = id => {
        setSelected(id);
        setShowDetails(true);
    };
    const closePropertyManagerDetails = () => {
        setSelected(null);
        setShowDetails(false);
    };

    const data = FAKE_DATA.pms.map(({ id, ...rest }) => ({
        id,
        onClick: () => openPropertyManagerDetails(id),
        ...rest
    }));

    const selectedData = data.find(({ id }) => id === selected);
    return (
        <Box>
            <Box display="flex" flexDirection="row" flex={1}>
                <Box flex={1}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <h1>Property Managers</h1>
                        <Box pt={1} pl={2} flex={1}>
                            <Typography variant="subtitle2" m={2}>
                                {FAKE_DATA.totalPM} total
                            </Typography>
                        </Box>
                        <Button variant="contained" color="primary" onClick={handleOpen}>
                            Add PM
                        </Button>
                    </Box>
                    <Table {...{ columns: COLUMNS, data, selectedId: selected }} />
                </Box>
                <Box>
                    <PMFilter />
                </Box>
            </Box>
            <SwipeableDrawer
                anchor="right"
                open={showDetails}
                onClose={() => closePropertyManagerDetails()}
            >
                {selectedData && (
                    <PMDetails {...{ pm: selectedData, closePropertyManagerDetails }} />
                )}
            </SwipeableDrawer>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={openModal}>
                    <AddPropertyManagerForm />
                </Fade>
            </Modal>
        </Box>
    );
}
