import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import { pick } from 'lodash-es';
import RatingInput from './RatingInput';
import { gql, useMutation, useQuery } from '../../lib/apollo';
import { useDebounce } from '../../lib/hooks';

const useStyles = makeStyles(theme => ({
    formGroup: {
        width: '100%',
        marginTop: theme.spacing(2)
    },
    formElement: {
        marginTop: theme.spacing()
    }
}));

const defaultState = {
    rating: 0,
    description: '',
};

export default function VendorRating({ vendor }) {
    const classes = useStyles();

    const [state, setState] = useState(defaultState);
    const setRating = rating => setState({ ...state, rating });
    const setDescription = event => setState({ ...state, description: event.target.value });

    const { _id, company_name } = vendor || {};
    const vendor_id = _id;
    const review = { ...state, vendor_id };

    const onCompleted = data => setState(getStateFromData(data));
    const onError = err => console.error(err.message);
    const { loading } = useQuery(GET_ENTERPRISE_REVIEW, { variables: { vendor_id }, onCompleted, onError });
    const [upsert] = useMutation(UPSERT_ENTERPRISE_REVIEW, { variables: ({ review }), onError });
    useDebounce(upsert, state);
    
    if (loading) return (<div />);

    return (
        <FormGroup className={classes.formGroup}>
            <Typography variant="subtitle1">{company_name}</Typography>
            <RatingInput rating={state.rating} setRating={setRating} className={classes.formElement} />
            <TextField
                label="Review (optional)"
                value={state.description}
                onChange={setDescription}
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                className={classes.formElement}
            />
        </FormGroup>
    );
}

const GET_ENTERPRISE_REVIEW = gql`
    query getEnterpriseReviewByUserAndVendor($vendor_id: ID) {
        getEnterpriseReviewByUserAndVendor(vendor_id: $vendor_id) {
            rating
            description
        }
    }
`;

const UPSERT_ENTERPRISE_REVIEW = gql`
    mutation upsertEnterpriseReview($review: ReviewInput!) { 
        upsertEnterpriseReview(review: $review) { _id }
    }
`;

const getStateFromData = data => data
    ? pick(data.getEnterpriseReviewByUserAndVendor, ['rating', 'description'])
    : defaultState;