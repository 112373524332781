import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Star from "@material-ui/icons/Star";
import StarBorder from "@material-ui/icons/StarBorder";

export default function RatingInput({ rating, setRating }) {
    const stars = [1, 2, 3, 4, 5].map(num => (
        <IconButton color="primary" onClick={() => setRating(num)} key={num}>
            {num <= rating ? <Star /> : <StarBorder />}
        </IconButton>
    ));
    return <div>{stars}</div>;
}
