import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650
    },
    tableRow: {
        backgroundColor: "white"
    },
    tableCell: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    tableHead: {
        backgroundColor: "white"
    }
}));

export default function Table({ columns, data, selectedId }) {
    const classes = useStyles();
    return (
        <TableContainer>
            <MaterialTable className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        {columns.map(({ name, align }) => (
                            <TableCell {...{ align }}>{name}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(row => (
                        <TableRow
                            key={row.id}
                            className={classes.tableRow}
                            onClick={row.onClick}
                            selected={selectedId === row.id}
                        >
                            <TableCell component="th" scope="row" className={classes.tableCell}>
                                {row.id}
                            </TableCell>
                            {columns.map(({ key, align }) => (
                                <TableCell {...{ align }} className={classes.tableCell}>
                                    {row[key]}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </MaterialTable>
        </TableContainer>
    );
}
