import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import GaugeChart from "./GaugeChart";
import ResponsiveLineChart from "./ResponsiveLineChart";
import CentralizationBoard from "../centralization/CentralizationBoard";
import NumberWithLabel from "../../../components/NumberWithLabel";
import GridItem from "../../../components/GridItem";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddVendorForm from "../preferred_vendors/AddVendorForm";
import AddPropertyManagerForm from "../property_managers/AddPropertyManagerForm";

const useStyles = makeStyles(theme => ({
    grid: {
        width: "100%"
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

const FAKE_DATA = {
    vendorScore: 4.25,
    qualityThreshold: 4.0,
    maxVendorScore: 5,
    qualityText: "All sites are operating well. \n Average vendor rating is 5% above CF threshold.",
    opportunities: 11,
    threats: 9,
    vendors: 1243,
    propertyManagers: 43,
    IndustryCFChart: {
        data: [
            { month: "Jan", Industry: 1.5, CF: 0.9 },
            { month: "Feb", Industry: 2.2, CF: 1 },
            { month: "Mar", Industry: 1.9, CF: 1.5 },
            { month: "Apr", Industry: 3.05, CF: 1.4 },
            { month: "May", Industry: 4.7, CF: 2.2 },
            { month: "Jun", Industry: 4.3, CF: 3.7 },
            { month: "Jul", Industry: 3.2, CF: 4.8 },
            { month: "Aug", Industry: 2.6, CF: 3.3 },
            { month: "Sep", Industry: 3.25, CF: 1.4 },
            { month: "Oct", Industry: 3.55, CF: 2.55 },
            { month: "Nov", Industry: 4.9, CF: 3.66 },
            { month: "Dec", Industry: 3.96, CF: 4.05 },
            { month: "Jan", Industry: 2.2, CF: 3.35 },
            { month: "Feb", Industry: 3.1, CF: 3.45 }
        ]
    },
    tasks: {}
};

export default function Dashboard() {
    const classes = useStyles();
    const [state] = useState(FAKE_DATA);
    const [openModal, setOpenModal] = useState(false);
    const [openVendor, setOpenVendor] = useState(false);

    const handleOpen = () => {
        setOpenModal(true);
    };
    const handleClose = () => {
        setOpenModal(false);
    };

    const handleVendorsButton = async () => {
        await setOpenVendor(true);
        handleOpen();
    };
    const handlePropertyManagersButton = async () => {
        await setOpenVendor(false);
        handleOpen();
    };

    return (
        <Container maxWidth={false}>
            <Grid container direction="row" justify="flex-start" alignItems="stretch" spacing={2}>
                <GridItem xs={6}>
                    <Grid container justify="space-between" spacing={1} className={classes.grid}>
                        <Grid item xs={6}>
                            <GaugeChart
                                label="Vendor Score"
                                value={state.vendorScore}
                                maxValue={state.maxVendorScore}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="h4">
                                Quality: {FAKE_DATA.qualityThreshold}/5
                            </Typography>
                            <Typography variant="body1">{state.qualityText}</Typography>
                        </Grid>
                    </Grid>
                </GridItem>

                <GridItem xs={6}>
                    <NumberWithLabel
                        value={state.opportunities}
                        primaryLabel="Opportunities"
                        secondaryLabel="to Centralize Services"
                        labelColor="green"
                    />
                </GridItem>

                <GridItem xs={3}>
                    <NumberWithLabel
                        value={state.vendors}
                        secondaryLabel="Vendors"
                        onClick={handleVendorsButton}
                    />
                </GridItem>

                <GridItem xs={3}>
                    <NumberWithLabel
                        value={state.propertyManagers}
                        secondaryLabel="Property Managers"
                        onClick={handlePropertyManagersButton}
                    />
                </GridItem>

                <GridItem xs={6}>
                    <NumberWithLabel
                        value={state.threats}
                        labelColor="red"
                        primaryLabel="Threats"
                        secondaryLabel="to Service Quality, Vendors and PMs"
                    />
                </GridItem>

                <GridItem>
                    <ResponsiveLineChart
                        label="Cadillac Fairfield: Industry VS CF"
                        data={state.IndustryCFChart.data}
                        xAxisKey="month"
                        lineKeys={["Industry", "CF"]}
                    />
                </GridItem>
                <Box pt={3} justifyContent="center" display="flex" flex={1}>
                    <CentralizationBoard />
                </Box>
            </Grid>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={openModal}>
                    {openVendor ? (
                        <AddVendorForm onSubmit={handleClose} />
                    ) : (
                        <AddPropertyManagerForm onSubmit={handleClose} />
                    )}
                </Fade>
            </Modal>
        </Container>
    );
}
