import React from "react";
import Container from "@material-ui/core/Container";
import {Paper} from "@material-ui/core";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            padding: 64,
            paddingTop: 16,
            margin: "auto",
            marginTop: 80,
            borderRadius: 0,
            maxWidth: 900,
            marginBottom: 40,
            textAlign: "left",
        },

        header: {
            textAlign: "center",
            marginBottom: 15,
            marginTop: 25
        },


    }),
);

export default function ContentPage({children, size = 'lg', paper = true}) {
    const classes = useStyles();

    return <Container maxWidth={size} style={paper ? {} : {backgroundColor: "#fff" }}>
        {!paper
            ? children :
            <Paper className={classes.paper} elevation={1}>
                {children}
            </Paper>}
    </Container>
}