import React from "react";
import ProfileInformationEditor from "./ProfileInformationEditor";
import { Paper, Typography, Container, Grid, Tabs } from "@material-ui/core";
import { GET_USER, GET_VENDOR } from "../../lib/apollo/queries";
import { useQuery } from "@apollo/react-hooks";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/Error";
import { Redirect, useParams, useHistory } from "react-router-dom";
import AccountPane from "./AccountPane";
import RequestPane from "./RequestPane";
import PostedReviews from "./PostedReviews";
import { EditVendorProfilePane } from "../../components/EditVendorProfilePane";
import { useStyles, StyledTab, TabPanel, a11yProps } from "../../styles/Settings";

export default function AccountSettings() {
    const classes = useStyles();
    const { page = "profile" } = useParams();
    const [value, setValue] = React.useState(
        {
            profile: 0,
            account: 1,
            invites: 2,
            reviews: 3,
            vendorProfile: 4
        }[page] || 0
    );
    const { data, loading, error } = useQuery(GET_USER, { skip: false });
    const { data: vendorData, loading: vendorLoading, error: vendorError } = useQuery(GET_VENDOR, {
        variables: { vendor_id: data && data.getUser && data.getUser.vendor },
        skip: !(data && data.getUser && data.getUser.vendor)
    });

    // const [apolloSignOut] = useMutation(SIGN_OUT)
    const history = useHistory();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    if (error || vendorError) return <ErrorMessage message={error || vendorError} />;
    if (loading || vendorLoading) {
        console.log([loading, vendorLoading]);
        return <Loading />;
    }

    if (!(data && data.getUser && (!data.getUser.vendor || (vendorData && vendorData.getVendor))))
        return <Redirect to="/" />;

    return (
        <Paper elevation={0} className={classes.root}>
            <Container maxWidth="md">
                <Grid container>
                    <Grid item xs={4} sm={3} className={classes.tabs}>
                        <Typography
                            variant="h6"
                            component="h4"
                            gutterBottom
                            style={{
                                paddingTop: 8,
                                marginBottom: 16,
                                fontWeight: 600
                            }}
                        >
                            Account
                        </Typography>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            orientation="vertical"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            <StyledTab disableRipple label="Profile" {...a11yProps(0)} />
                            <StyledTab disableRipple label="Settings" {...a11yProps(1)} />
                            <StyledTab disableRipple label="Invites" {...a11yProps(2)} />
                            <StyledTab disableRipple label="Reviews" {...a11yProps(3)} />
                            {vendorData && (
                                <StyledTab disableRipple label="Vendor Profile" {...a11yProps(4)} />
                            )}
                            <StyledTab
                                disableRipple
                                label="Sign Out"
                                onClick={() => history.push("/sign-out")}
                            />
                        </Tabs>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <TabPanel value={value} index={0}>
                            <ProfileInformationEditor
                                user={data.getUser}
                                setUser={u => console.log(u)}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <AccountPane />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <RequestPane
                                vendor={!!data.getUser.vendor}
                                invitees={data.getUser.invitees}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <PostedReviews reviews_objs={data.getUser.reviews} />
                        </TabPanel>
                        {vendorData && (
                            <TabPanel value={value} index={4}>
                                <EditVendorProfilePane
                                    vendor={vendorData.getVendor}
                                    refetchQueries={[
                                        {
                                            query: GET_VENDOR,
                                            variables: { vendor_id: data.getUser.vendor }
                                        }
                                    ]}
                                    onCompleted={undefined}
                                />
                            </TabPanel>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Paper>
    );
}
