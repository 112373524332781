import React from "react";
import { Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    reviewLink: {
        textDecoration: "none",
        color: "rgba(0,0,0,0.87)",
        display: "block",
        padding: 2,
        textShadow: "0 0 1px rgba(0,0,0,0.15)",
        transition: "200ms",
        "&:hover": {
            color: "rgba(0,0,0,0.6)",
            textShadow: "0 0 1px rgba(0,0,0,0)"
        }
    },

    reviewButtonText: {
        fontWeight: 600,
        fontSize: 16
    },

    container: {
        padding: theme.spacing(4, 3),
        marginTop: 0,
        paddingTop: 8
    }
}));

export default function ServiceList({ listOfCategories, category, flowCategories, makeLinkProps }) {
    const classes = useStyles();

    const numItems = Object.values(listOfCategories).flat().length;
    const nCols = 3;
    const goalItemsPerCol = numItems / 3;
    const allowableError = 2;
    const entries = Object.entries(listOfCategories);
    const colCategories = flowCategories
        ? entries.reduce(
              (cols, [sCategory, services], i) => {
                  const currentCol = cols[cols.length - 1];
                  const colCount = currentCol.map(c => c[1]).flat().length;
                  if (services.length === 0) {
                      return cols;
                  } else if (cols.length === nCols) {
                      currentCol.push([sCategory, services]);
                  } else if (colCount + services.length < goalItemsPerCol + allowableError) {
                      currentCol.push([sCategory, services]);
                  } else {
                      const splitSize = goalItemsPerCol - colCount;
                      currentCol.push([sCategory, services.slice(0, splitSize)]);
                      cols.push([[sCategory + " cont.", services.slice(splitSize)]]);
                  }
                  return cols;
              },
              [[]]
          )
        : [[entries[0]], [entries[1], entries[2]], [entries[3], entries[4]]];

    return (
        <Grid container className={classes.container} spacing={1} justify="flex-start">
            {colCategories.map((items, i) => (
                <Grid
                    key={"grid for " + items[0][0]}
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    style={{ paddingBottom: 16 }}
                >
                    {items.map(([sCategory, services], i) => {
                        //blue subheader
                        //review management
                        return (
                            (!category || sCategory === category) && (
                                <List key={sCategory} style={{ marginBottom: 24 }}>
                                    <Typography variant="h6" color="primary" gutterBottom>
                                        {sCategory}
                                    </Typography>
                                    {services.map(service => {
                                        if (!service) return;
                                        return (
                                            <Link
                                                className={classes.reviewLink}
                                                key={service}
                                                {...makeLinkProps(service)}
                                            >
                                                <ListItem>
                                                    <ListItemText
                                                        variant="button"
                                                        className={classes.reviewButtonText}
                                                    >
                                                        {service}
                                                    </ListItemText>
                                                </ListItem>
                                            </Link>
                                        );
                                    })}
                                </List>
                            )
                        );
                    })}
                </Grid>
            ))}
        </Grid>
    );
}
