import React from "react";
import { Button, Grid, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import PasswordField from "../../components/PasswordField";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";

import firebase from "firebase/app";
import Loading from "../../components/Loading";

export default function AccountPane() {
    const [currentPassword, setCurrentPassword] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [passwordsMatch, setPasswordsMatch] = React.useState(false);
    const [errorMsg, setError] = React.useState("");
    const [result, setResult] = React.useState(false);
    const user = firebase.auth().currentUser;

    return !user ? (
        <Loading />
    ) : (
        <Grid container spacing={2}>
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ marginBottom: 24 }}>
                            Account Settings
                        </Typography>
                        <Divider style={{ marginBottom: 24 }} />
                    </Grid>
                </Grid>
            </React.Fragment>

            {/* TODO: hide this make it visible when they click a button saying Change Password  */}
            {user.providerData.filter(providerData => {
                return providerData.providerId === "password";
            }).length > 0 && (
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" style={{ marginBottom: 6 }}>
                        Change Password
                    </Typography>
                    <PasswordField
                        confirm
                        newPass
                        email={user.email}
                        passwordsMatch={setPasswordsMatch}
                        {...{
                            password,
                            setPassword,
                            currentPassword,
                            setCurrentPassword
                        }}
                    />

                    <Typography color="error">{errorMsg}</Typography>

                    <Button
                        variant="outlined"
                        onClick={() => {
                            if (!passwordsMatch) return setError("Passwords Did Not Match");

                            const credential = firebase.auth.EmailAuthProvider.credential(
                                user.email,
                                currentPassword
                            );

                            user.reauthenticateWithCredential(credential)
                                .then(userCredential => {
                                    return userCredential.user
                                        .updatePassword(password)
                                        .then(() => setResult(true));
                                })
                                .catch(reason => {
                                    console.log(reason);
                                    setResult(reason);
                                });
                        }}
                        style={{
                            marginRight: 0,
                            marginLeft: "auto"
                        }}
                    >
                        Change Password
                    </Button>
                </Grid>
            )}

            <Grid item xs={12}>
                {result === true ? (
                    <Alert severity="info" color="info">
                        Password Changed Successfully
                    </Alert>
                ) : (
                    result !== false && (
                        <Alert severity="error" color="error">
                            {result.message}
                        </Alert>
                    )
                )}
            </Grid>
            {/* 
            <Grid item xs={12} md={6}>
                <Link to="/sign-out" style={{ textDecoration: "none" }}>
                    <Button
                        variant="outlined"
                        style={{
                            marginRight: 0,
                            marginLeft: "auto",
                            marginTop: 12
                        }}
                    >
                        Sign Out
                    </Button>
                </Link>
            </Grid> */}
        </Grid>
    );
}
