import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import { FiXSquare, FiEdit2 } from "react-icons/fi";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { Button } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { ServiceMultiPicker } from "../../components/ServiceMultiPicker";

import { CREATE_RFQ, UPDATE_RFQ, DELETE_RFQ } from "../../lib/apollo/mutations";
import {GET_USER} from "../../lib/apollo/queries";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    title: {
        marginRight: 40,
        textTransform: "capitalize",
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const style_delete_button = {
    color: 'white',
    background: 'red',
    marginTop: '10px',
    width: '60%'
}

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6" className={classes.title}>
                {children}
            </Typography>

            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <FiXSquare size={26} />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function RFQDialog({
    open,
    closeDialog,
    rfqIn = {
        service: "",
        priority: "Upcoming",
    },
    onSave = _ => {},
    creatingNew = false,
}) {
    const priorities = ["Urgent", "Upcoming", "Wishlist", "Completed"];
    const inputLabel = React.useRef(null);
    const [editableTitle, setEditableTitle] = React.useState(false);
    const [rfq, setRfq] = React.useState(rfqIn);
    React.useEffect(() => {setRfq(rfqIn)},[rfqIn]);
    const requestProps = {
        onCompleted: (...data) => {
            onSave(data[0] && data[0].createRFQ || rfq);
            closeDialog();
        },
        refetchQueries: [{
            query: GET_USER
        }]
    };

    const [updateRFQ] = useMutation(UPDATE_RFQ, requestProps);
    const [createRFQ] = useMutation(CREATE_RFQ, requestProps);

    function getFormattedDate(date) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, "0");
        let day = date
            .getDate()
            .toString()
            .padStart(2, "0");

        return month + "/" + day + "/" + year;
    }

    const updateWithServer = () => {
        if (!rfq.service) return closeDialog();
        if (creatingNew) {
            createRFQ({
                variables: { rfq },
                ...requestProps
            });
        } else {
            const {
                _id,
                scope_of_work,
                notes,
                priority,
                deadline_date,
            } = rfq;
            updateRFQ({
                variables: {
                    rfq: {
                        _id,
                        scope_of_work,
                        notes,
                        priority,
                        deadline_date,
                    },
                },
                ...requestProps
            });
        }
    };

    const [deleteRFQ] = useMutation(DELETE_RFQ, {variables: {rfq: rfq._id}})
    return (
        <div>
            <Dialog
                onClose={updateWithServer}
                fullWidth
                maxWidth="xs"
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClick={() => !editableTitle && setEditableTitle(true)}
                    onClose={updateWithServer}
                >
                    <Typography variant="h6" component="p" gutterBottom>
                        {rfq.service ? "Edit Service Plan" : "Add Service Plan"}
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <div
                        style={{
                            minHeight: 300,
                            margin: 24,
                            position: "relative",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {editableTitle || creatingNew ? (
                                    <ServiceMultiPicker
                                        findOnly
                                        multiple={false}
                                        services={
                                            rfq.service && rfq.service.length
                                                ? [rfq.service]
                                                : []
                                        }
                                        setServices={value =>
                                            setRfq({
                                                ...rfq,
                                                service: rfq.service
                                                    ? value[1]
                                                    : value[0],
                                            })
                                        }
                                    />
                                ) : (
                                    <Typography variant="h6" onClick={() => setEditableTitle(true)}>{rfq.service} <FiEdit2 size={15}/></Typography>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} fullWidth>
                                    <KeyboardDatePicker
                                        fullWidth
                                        margin="none"
                                        id="date-picker-dialog"
                                        label="Scheduled Start Date"
                                        format="MM/dd/yyyy"
                                        inputVariant="outlined"
                                        value={
                                            rfq?.deadline_date
                                                ? new Date(rfq.deadline_date)
                                                : new Date(
                                                      Date.now() +
                                                          1000 *
                                                              60 *
                                                              60 *
                                                              24 *
                                                              7
                                                  )
                                        }
                                        onChange={_ => {}}
                                        onAccept={date =>
                                            setRfq({
                                                ...rfq,
                                                deadline_date: Date.parse(date),
                                            })
                                        }
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                                    <Select
                                        labelId="select-priority-label"
                                        id="select-priority"
                                        label="Priority"
                                        value={rfq.priority}
                                        labelWidth={40}
                                        onChange={event =>
                                            setRfq({
                                                ...rfq,
                                                priority: event.target.value,
                                            })
                                        }
                                    >
                                        {priorities.map(o => (
                                            <MenuItem key={o} value={o}>
                                                {o}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <div style={{ position: "absolute", bottom: 0 }}>
                            {
                                creatingNew &&
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={updateWithServer}
                                >
                                    Create Service Plan
                                </Button>
                            }
                            {
                                !creatingNew && 
                                <Button
                                    style={{width:'60%'}}
                                    variant="outlined"
                                    color="primary"
                                    onClick={updateWithServer}
                                >
                                    Save Service Plan
                                </Button>
                            }
                            {
                            !creatingNew && 
                            <Button
                                style={style_delete_button}
                                variant="outlined"
                                color="primary"
                                onClick={async () => {
                                    updateWithServer()
                                    await deleteRFQ()
                                }}
                            >
                                Delete Service Plan
                            </Button>
                            }
                            {/* <Button
                                onClick={() => {
                                    // mutate local state to put scope and notes in and then go to select vendors
                                }}
                            >
                                Request Quotes
                            </Button> */}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}
