import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { GET_USER } from '../lib/apollo/queries'
import { useQuery } from 'react-apollo'
import Loading from '../components/Loading'
import { Paper, Typography, Container, Grid, Tabs, TextField } from '@material-ui/core'
import { useStyles, StyledTab, TabPanel, a11yProps } from '../styles/Settings'
import UsersPanel from './UsersPanel'
import VendorPanel from './VendorPanel'
import RFQPanel from './RFQPanel'
import ReviewPanel from './ReviewPanel'

export default function AdminPanel() {
    const classes = useStyles();
    const { page = "users" } = useParams();
    const [value, setValue] = React.useState(
        {
            users: 0,
            vendors: 1,
            rfqs: 2,
            // reviews: 3
        }[page] || 0
    )

    const handleChange = (event, newValue) => { setValue(newValue) }
    const { loading, data, error } = useQuery(GET_USER);
    
    if (loading) return <Loading />
    if (error) return 
    const user = data.getUser
    if (!user || !user.admin) return <Redirect to='/'/>

    return (
        <Paper elevation={0} className={classes.root}>
            <Container maxWidth="md">
                <Grid container>
                    <Grid item xs={4} sm={3} className={classes.tabs}>
                        <Typography
                            variant="h6"
                            component="h4"
                            gutterBottom
                            style={{
                                paddingTop: 8,
                                marginBottom: 16,
                                fontWeight: 600
                            }}
                        >
                            Admin Panel
                        </Typography>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            orientation="vertical"
                            scrollButtons="auto"
                        >
                            <StyledTab disableRipple label="Users" {...a11yProps(0)}/>
                            <StyledTab disableRipple label="Vednors" {...a11yProps(1)}/>
                            <StyledTab disableRipple label="RFQs" {...a11yProps(2)}/>
                            <StyledTab disableRipple label="Reviews" {...a11yProps(3)}/>  
                        </Tabs>
                    </Grid>
                    <Grid item xs={8} sm={9}>
                        <TabPanel value={value} index={0}>
                            <UsersPanel/>
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <VendorPanel/>
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <RFQPanel />
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            <ReviewPanel />
                        </TabPanel>
                    </Grid>
                </Grid>
            </Container>
        </Paper>

    )
}