import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { asReadableString, getFormattedAddress } from "../../lib/address";

const useStyles = makeStyles(theme => ({
    listItem: {
        padding: theme.spacing(1, 0)
    },
    total: {
        fontWeight: "700"
    },
    title: {
        marginTop: theme.spacing(2)
    }
}));

function formatDate(date) {
    var monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();

    return day + " " + monthNames[monthIndex] + " " + year;
}

export default function Review({
    service_id,
    files,
    notes,
    date,
    firstName,
    lastName,
    address,
    scopeOfWork
}) {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Profile Information
                    </Typography>
                    <Typography gutterBottom>{firstName + " " + lastName}</Typography>
                    {/* <Typography gutterBottom>
                        {asReadableString(address)}
                    </Typography> */}
                </Grid>
                <Grid item container direction="column" xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Quote Request Details
                    </Typography>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography gutterBottom>{formatDate(date)}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom>
                                {files.map(f => f.name).toString(", ")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom>{scopeOfWork}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutterBottom>{notes}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
