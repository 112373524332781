import firebase from "firebase/app";
import "firebase/auth";
import { FIREBASE_CONFIG, FIREBASE_ID_TOKEN, DEVELOPMENT } from "../constants";

firebase.initializeApp(FIREBASE_CONFIG, "enterprise");

// localStorage functions
const getIdToken = () => localStorage.getItem(FIREBASE_ID_TOKEN);
const setIdToken = async () => localStorage.setItem(FIREBASE_ID_TOKEN, user() ? await user().getIdToken() : '');

firebase.auth().onIdTokenChanged(async () => {
  await setIdToken();
  if (DEVELOPMENT) console.log(FIREBASE_ID_TOKEN, getIdToken());
});

// exported functions
export const user = () => firebase.auth().currentUser;
export const idToken = getIdToken;

export const signOut = async () => {
  await firebase.auth().signOut();
  await setIdToken();
  return user();
};

export const signInWithEmailAndPassword = async (email, password) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
  }
  await setIdToken();
  return user();
}

export const createUserWithEmailAndPassword = async (email, password) => {
  try {
    await firebase.auth().createUserWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
  }
  await setIdToken();
  return user();
}

export default firebase;
