import { createMuiTheme } from "@material-ui/core/styles";
import { palette } from "@material-ui/system";

export default createMuiTheme({
    palette: {
        primary: {
            main: "#3949ab",
            light: "#0073bb",
            dark: "#1a237e",
        },
        secondary: {
            main: "#00e676",
            light: "#66ffa6",
            dark: "#00b248",
            contrastText: "#fff",
        },
        // Ryan added these from the default
        error: {
            light: "#e57373",
            main: "#f44336",
            dark: "#d32f2f",
            contrastText: "#fff",
        },
        warning: {
            light: "#ffb74d",
            main: "#ff9800",
            dark: "#f57c00",
            contrastText: "rgba(0, 0, 0, 0.87)",
        },
        info: {
            light: "#64b5f6",
            main: "#2196f3",
            dark: "#1976d2",
            contrastText: "#fff",
        },
        success: {
            light: "#81c784",
            main: "#4caf50",
            dark: "#388e3c",
            contrastText: "rgba(0, 0, 0, 0.87)",
        },
    },
    typography: {
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
        fontWeightMedium: 500,
        useNextVariants: true,
    },
});
