const Fuse = require("fuse.js");

var options = {
    shouldSort: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    includeScore: true,
    keys: ["title"]
};

const SortAlphabetically = (a, b) => {
    if (a < b) {
        return -1;
    }
    if (a > b) {
        return 1;
    }
    return 0;
};

export const listOfCategories = {
    "Facility Maintenance": [
        "Amenity / Recreation Equipment",
        // Some company specifically asked for glass replacement, for now it's an option with no vendors registered under it
        "Glass Replacement",
        "Animal Control / Pest Control",
        "Building Restoration",
        "Carpet - Cleaning, Repair / Installation",
        "Caulking",
        "Ducts / Vents Cleaning",
        "Eavestrough Cleaning / Repair",
        "Electricians",
        "Elevators",
        "Fire Protection",
        "Snow Removal / Salting",
        "Fire Doors",
        "Garage Cleaning",
        "Garage - CO Systems / Doors",
        "Garbage Chutes",
        "Generators - Fuel Delivery / Maintenance",
        "Graffiti Removal",
        "Grounds Maintenance Services",
        "Heat Pump - Suppliers / Maintenance",
        "Hoarding Remediation",
        "HVAC",
        "Infrared Inspection",
        "Lawn Irrigation",
        "Janitorial / House Keeping",
        "Landscaping",
        "Laundry Equipment", // None as of 2019-01-19 ??? did you mean 2020 ???
        "Life Safety Systems",
        "Living Walls",
        "Locksmith",
        "Mould Remediation / Testing",
        "Moving Services ", // None as of 2019-01-19 ??? did you mean 2020 ???
        "Noise Testing",
        "Power Washing",
        "Odour Control",
        "Painting",
        "Parking Control",
        "Litter Control",
        "Playground Equipment", // None as of 2019-01-19 ??? did you mean 2020 ???
        "Plumbing",
        "Pressure Reducing Valves",
        "Property Beautification",
        "Recreation Equipment", // None as of 2019-01-19 ??? did you mean 2020 ???
        "Roof Anchors - Inspection / Repair",
        "Roofing",
        "Screens",
        "Security",
        "Deep Cleaning",
        "Signs",
        "Sound Proofing",
        "Sub Metering",
        "Swimming Pools / Spa - Maintenance",
        "Tennis Court Restoration", // None as of 2019-01-19
        "Tree Pruning",
        "Washroom Accessories",
        "Waste Management",
        "Water Conservation",
        "Water Proofing",
        "Marble Polishing",
        "Water Treatment",
        "Window Cleaning",
        "Windows / Doors",
        "Wood Restoration"
    ].sort(SortAlphabetically),
    "Construction & GC": [
        "Balcony Glazing",
        "Boiler Heating Equipment",
        "General Contracting",
        "Elevators",
        "Bollards", //  maybe a diff category?
        "Fencing / Railing",
        "Hardwood / Flooring",
        "Heat Pump - Suppliers / Maintenance",
        "Interior Design", // None as of 2019-01-19
        "Life Safety Systems",
        "Lighting Suppliers",
        "Concrete / Masonry",
        "Office Equipment", // None as of 2019-01-19
        "Paving",
        "Plastering / Drywall",
        "Property Management Software",
        "Real Estate Condominium Redevelopment", // None as of 2019-01-19
        "Tiles - Installation / Repair",
        "Toilet Partitions",
        "Wall Coverings"
    ].sort(SortAlphabetically),
    "Professional Services": [
        "Auditing / Accounting",
        "Book Keeping Services",
        "Building Sciences",
        "Colour Consulting", // None as of 2019-01-19
        "Concierge Services",
        "Engineers", // None as of 2019-01-19
        "Enviromental Consultants",
        "Financial Services",
        "Health / Safety",
        "Independent Security Consultants", // None as of 2019-01-19
        "Insurance",
        "Lawyers",
        "Mechanical",
        "Mediation / Arbitration", // None as of 2019-01-19
        "Printers/ Web _ Graphic Design",
        "Real Estate - Sales",
        "Recreation Facilities Management", // None as of 2019-01-19
        "Reserve Fund Planners",
        "Valet Parking"
    ].sort(SortAlphabetically),
    "Emergency Services": ["Emergency Restoration", "Flood Restoration", "Water Treatment"].sort(
        SortAlphabetically
    ),
    "Smart & Connected": [
        "Building Automation Systems",
        "Communications",
        "Energy Services",
        "Information Technology",
        "Minutes, Condominium Recording Secretary",
        "Telecommunications",
        "Video Surveillance"
    ].sort(SortAlphabetically),
    Other: []
};

const enum_all_services = Object.values(listOfCategories).flat();

export const listOfServices = enum_all_services.sort(SortAlphabetically);

// [
//     "Swimming Pools / Spa - Maintenance",
//     "Information Technology",
//     "Water Proofing",
//     "Garage Cleaning",
//     "Graffiti Removal",
//     "Grounds Maintenance Services",
//     "Janitorial / Housekeeping",
//     "Landscaping",
//     "Odour Control",
//     "Auditing & Accounting",
//     "Electricians",
//     "Energy Services",
//     "HVAC",
//     "Balcony Glazing",
//     "Building Restoration",
//     "Caulking",
//     "Communications",
//     "Concrete / Masonry",
//     "Concierge Services",
//     "Eavestrough Cleaning / Repair",
//     "Emergency Restoration",
//     "Fencing / Railing",
//     "Fire Protection",
//     "Flood Restoration",
//     "Garage - CO Systems / Doors",
//     "General Contracting",
//     "Interior Design",
//     "Lawn Irrigation",
//     "Mould Remediation / Testing",
//     "Painting",
//     "Plastering / Drywall",
//     "Roof Anchors - Inspection / Repair",
//     "Roofing",
//     "Sound Proofing",
//     "Telecommunications",
//     "Tiles - Installation / Repair",
//     "Tree Pruning",
//     "Washroom Accessories",
//     "Waste Management",
//     "Windows / Doors",
//     "Boiler Heating Equipment",
//     "Building Automation Systems",
//     "Heat Pump",
//     "Mechanical",
//     "Plumbing",
//     "Ducts / Vents",
//     "Engineers",
//     "Security",
//     "Property Management Software",
//     "Concierge Services",
//     "Life Safety Systems",
//     "Paving",
//     "Carpet - Sales / Installation",
//     "Insurance",
//     "Locksmith",
//     "Elevators",
//     "Health / Safety",
//     "Garbage Chutes",
//     "Financial Services",
//     "Snow Removal & Salting",
//     "Power Washing ",
//     "Floor Maintenance",
//     "Video Surveillance",
//     "Litter control",
//     "Living Walls",
//     "Deep Cleaning",
//     "Window Cleaning",
//     "Light Cleaning",
//     "Rope Access",
//     "Signage Installation",
// ].sort(SortAlphabetically);

// console.log(
//     Object.values(listOfCategories)
//         .reduce((p, n) => p.concat(n), [])
//         .filter(s => !enum_all_services.includes(s))
// );

// const hi = Object.values(listOfCategories).reduce((p, n) => p.concat(n), []);
// console.log(enum_all_services.filter(s => !hi.includes(s)));

const searchDomain = listOfServices.map(item => ({
    title: item
}));

var fuse = new Fuse(searchDomain, options);
export default text => fuse.search(text);
