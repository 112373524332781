import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MapGL, { Marker } from "react-map-gl";
import MAPBOX_TOKEN from "../../../lib/mapbox_token";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    labels: {
        padding: theme.spacing(2)
    }
}));

const defaultViewport = {
    latitude: 41,
    longitude: -95,
    zoom: 2.7,
    bearing: 0,
    pitch: 0
};

const FAKE_DATA = [
    { label: "Chicago", latitude: 41, longitude: -87, radius: 120 },
    { label: "Toronto", latitude: 43, longitude: -79, radius: 80 },
    { label: "San Francisco", latitude: 37, longitude: -122, radius: 100 }
];

export default function CentralizationMap() {
    const [viewport, setViewport] = useState(defaultViewport);

    return (
        <>
            <MapGL
                {...viewport}
                width="100%"
                height={400}
                mapStyle="mapbox://styles/mapbox/light-v10"
                onViewportChange={setViewport}
                mapboxApiAccessToken={MAPBOX_TOKEN}
            >
                {FAKE_DATA.map((item, i) => (
                    <Marker key={i} latitude={item.latitude} longitude={item.longitude}>
                        <Dot radius={item.radius} />
                    </Marker>
                ))}
            </MapGL>
            <Labels items={FAKE_DATA} />
        </>
    );
}

function Dot({ radius }) {
    const outerStyle = {
        backgroundColor: "rgba(46,92,255, 1)",
        display: "inline-block",
        borderRadius: "50%",
        height: 15,
        width: 15,
        boxShadow: `0 0 0 ${radius / 2}px rgba(46,92,255,0.2)`
    };

    return <span style={outerStyle}></span>;
}

function Labels({ items }) {
    const classes = useStyles();

    return (
        <Grid container justify="flex-start" spacing={2} className={classes.labels}>
            {items.map((item, i) => (
                <Grid item key={i}>
                    <Typography>{item.label}</Typography>
                </Grid>
            ))}
        </Grid>
    );
}
