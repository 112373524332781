import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@material-ui/core";
import React from "react";

export function AssociationPicker({ classes, associations, setAssociations }) {
    function selectAssociation(e) {
        // @ts-ignore
        const value = e.target.value;
        associations.includes(value)
            ? setAssociations(associations.filter(a => a !== value))
            : setAssociations([...associations, value]);
    }

    return (
        <React.Fragment>
            <Typography
                gutterBottom
                variant="subtitle2"
                style={{ marginTop: 16 }}
                className={classes.inputPrompt}
            >
                Associations
            </Typography>

            <div className={classes.toggleContainer}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={associations.includes("boma")}
                                color="primary"
                                value="boma"
                                onClick={selectAssociation}
                            />
                        }
                        label="BOMA"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={associations.includes("acmo")}
                                color="primary"
                                value="acmo"
                                onClick={selectAssociation}
                            />
                        }
                        label="ACMO"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={associations.includes("complyworks")}
                                color="primary"
                                value="complyworks"
                                onClick={selectAssociation}
                            />
                        }
                        label="ComplyWorks"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={associations.includes(
                                    "contractorWorks"
                                )}
                                color="primary"
                                onClick={selectAssociation}
                                value="contractorWorks"
                            />
                        }
                        label="Contractor Check"
                    />
                </FormGroup>
            </div>
        </React.Fragment>
    );
}
