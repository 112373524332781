import React, { memo } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { PieChart, Pie, Cell, Label } from "recharts";

const colors = ["#3949ab", "#7986cb"];

function GaugeChart({ label, value = 0, maxValue = 5 }) {
    const data = [
        { name: "Current", value: value },
        { name: "Max", value: maxValue - value }
    ];

    const centerLabel = (
        <text x={105} y={65} textAnchor="middle">
            <tspan fontSize="40" fontWeight="400">
                {value}
            </tspan>
            <tspan x={105} y={83} fontSize="16">
                {label.toUpperCase()}
            </tspan>
        </text>
    );

    return (
        <Typography component="div">
            <Box>
                <PieChart width={220} height={95}>
                    <Pie
                        dataKey="value"
                        startAngle={180}
                        endAngle={0}
                        data={data}
                        cx={100}
                        cy={80}
                        innerRadius={65}
                        outerRadius={85}
                        paddingAngle={2}
                    >
                        <Label content={centerLabel} position="center" />
                        <Cell key={0} fill={colors[0]} />
                        <Cell key={1} fill={colors[1]} />
                    </Pie>
                </PieChart>
            </Box>
        </Typography>
    );
}

export default memo(props => <GaugeChart {...props} />);
