import React, { useState } from 'react'
import { GET_ALL_USERS } from '../lib/apollo/queries'
import { useQuery } from 'react-apollo'
import Loading from '../components/Loading'
import { TextField } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useStyles } from '../styles/Settings'
import DeleteUserButton from './DeleteUserButton'

export default function UsersPanel() {
    const [searchText, setSearchText] = useState('');
    const classes = useStyles();
    const keyPress = (e) => { if (e.keyCode == 13) setSearchText(e.target.value) }
    const resetSearch = () => { setSearchText('') }

    const { loading, data: all_users, error } = useQuery(GET_ALL_USERS);
    if (loading) return <Loading />
    if (error) return 

    return (
        <React.Fragment>
        <TextField id='searchBar' className={classes.textField} onKeyDown={keyPress} label="Search Users (case-sensitive)" variant="outlined" fullWidth/>
        {/* <Button onClick={() => resetSearch} color="primary">Reset</Button> */}

        <table>
        <thead>
            <tr>
            <th>Name</th>
            <th>Company</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Address</th>
            <th>Joined</th>
            <th>Reviews</th>
            </tr>
        </thead>
        <tbody>

        {
        all_users.getAllUsers
            .filter(a => JSON.stringify(a).toLowerCase().includes(searchText.toLowerCase()))
            .map((user, index) => (
            <tr key={index} id={index}>
            <td className={classes.tableItem} component="th" scope="row">{
                user.name ? 
                <span>{user.name.first} {user.name.last}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                user.company_name ?
                <span>{user.company_name}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right">{
                user.phone_number ?
                <span>{user.phone_number}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem} align="right"><a target="__blank" href={"mailto:"+user.email}>{user.email}</a></td>
            <td className={classes.tableItem} align="right">{
                user.address ?
                <span>{user.address.street_number} {user.address.street}, {user.address.city}, {user.address.province}</span>
                : 'unknown'
            }</td>
            <td className={classes.tableItem}>{
                user.join_date ?
                <span>{new Date(user.join_date).toISOString().substring(0, 10)}</span> : 'unknown'
            }</td>
            <td className={classes.tableItem}>{
                user.reviews ?
                <span>{user.reviews.map(review => review._id).join('\n')}</span> : 'none'
            }</td>
            <td className={classes.tableItem}><DeleteUserButton del={user} index={index} /></td>
            </tr>
            ))
        }
        </tbody>
        </table>
        </React.Fragment>
    )
}
