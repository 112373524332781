import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography, Box, Button } from "@material-ui/core";
import {
    CardRightContent,
    CardTitle,
    Detail,
    MovableCardWrapper
} from "react-trello/src/styles/Base";
import Tooltip from "@material-ui/core/Tooltip";

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: "#FFFFFF",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9"
    }
}))(Tooltip);

const useStyles = makeStyles(theme => ({
    card: {
        fontFamily: theme.typography.body1.fontFamily,
        minHeight: 64,
        borderRadius: 0,
        boxShadow: "0 1px 5px rgba(0,0,0,0.08)",
        backgroundColor: "#fff",
        borderLeftStyle: "solid",
        borderLeftWidth: 2,
        borderLeftColor: "#9c27b0",
        width: "350px"
    },
    subtitle: {
        color: "#828282"
    },
    wrapperStyle: {
        minWidth: "350px",
        maxWidth: "auto"
    }
}));

export default function TrelloCard(props) {
    const {
        showDeleteButton,
        style,
        tagStyle,
        onClick,
        onDelete,
        className,
        id,
        title,
        label,
        description,
        tags,
        cardDraggable,
        OnHoverContent
    } = props;
    const classes = useStyles();

    const Card = () => {
        return (
            <Box className={classes.card} boxShadow={2}>
                <MovableCardWrapper
                    data-id={id}
                    onClick={onClick}
                    style={style}
                    className={classes.wrapperStyle}
                >
                    <Box flex={1}>
                        <CardTitle draggable={cardDraggable}>{title}</CardTitle>
                    </Box>
                    <Box flex={1} textAlign="right">
                        <Typography variant="subtitle1">{description}</Typography>
                        <Typography
                            variant="subtitle2"
                            color="inherit"
                            className={classes.subtitle}
                        >
                            {label}
                        </Typography>
                    </Box>
                </MovableCardWrapper>
            </Box>
        );
    };

    if (OnHoverContent) {
        return <HtmlTooltip title={<OnHoverContent id={id} />}>{Card()}</HtmlTooltip>;
    } else {
        return Card();
    }
}
