import { loginWithGoogle, signInWithEmail } from "../../lib/signupHelpers";
import PasswordField from "../PasswordField";
import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { SIGN_IN } from "../../lib/apollo/mutations";
import { makeSetterForProperty } from "../../lib/helpers";
import { GET_USER } from "../../lib/apollo/queries";
import CredentialsView from "./CredentialsView";
import firebase from "firebase";
const makeSignInOptions = onCompleted => ({
    refetchQueries: [{ query: GET_USER }],
    awaitRefetchQueries: true,
    onCompleted
});

export default function SigninCredentials({ state, setState, onLogin, ...props }) {
    const [signIn] = useMutation(SIGN_IN, makeSignInOptions(onLogin));
    const setError = makeSetterForProperty(state, setState, "ErrorMsg");
    const setPassword = makeSetterForProperty(state, setState, "password");
    const setEmail = makeSetterForProperty(state, setState, "email");
    const currentUser = firebase.auth().currentUser;

    const doSubmitAction = () => {
        signInWithEmail(
            state.email,
            state.password,
            _ => {
                signIn();
            },
            e => setError(e.message)
        );
    };
    const onGoogleSignIn = () => loginWithGoogle(async user => signIn());
    return (
        <CredentialsView
            {...{ state, setState, setEmail, doSubmitAction, onGoogleSignIn, ...props }}
            submitText="Sign In"
        >
            <PasswordField
                onKeyDown={async e => {
                    if (e.keyCode === 13) {
                        if (currentUser) {
                            window.location.href = "/account";
                        } else {
                            doSubmitAction();
                        }
                    }
                }}
                {...{
                    password: state.password,
                    setPassword,
                    email: state.email
                }}
            />
        </CredentialsView>
    );
}
