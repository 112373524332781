import gql from "graphql-tag";

export default gql`
    extend type Mutation {
        selectVendor(_id: ID!): Boolean
    }
   
    extend type Mutation {
        clearSelectedVendors: Boolean
    }

    extend type Vendor {
        selected: Boolean @client
    }
`;
