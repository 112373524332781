import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { makeStyles, Container, Grid, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.primary.light,
    },
    gridContainer: {
        padding: 16,
    },
}));

export default function ClaimBanner() {
    const classes = useStyles();
    const { vendor_id, token } = useParams();
    const location = useLocation();
    const claim = location.pathname.includes("claim");

    // console.log({ token, claim, location });

    if (!claim) return;
    return (
        <div className={classes.root}>
            <Container>
                <Grid container className={classes.gridContainer} justify="space-between">
                    <Grid item sm={9}>
                        <Typography display="inline" variant="h6" style={{ color: "white" }}>
                            Claim your profile (suggestions for copy?)
                        </Typography>
                    </Grid>
                    <Grid item sm={3} style={{ textAlign: "right" }}>
                        <Button style={{ color: "white" }} variant="outlined">
                            Claim
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
