import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { fieldProps } from "../../lib/helpers";
import { signInWithEmailAndPassword } from "../../lib/firebase";

const useStyles = makeStyles(theme => ({
  formElement: {
    marginTop: theme.spacing(2)
  }
}));

const defaultData = {
  email: '',
  password: '',
};

export default function EnterpriseSignIn() {
  const classes = useStyles();
  const [data, setData] = useState(defaultData);

  const history = useHistory();

  const formFieldProps = (label, type = "text") => fieldProps(label, type, classes.formElement, data, setData);

  const handleSubmit = async event => {
    event.preventDefault();
    const { email, password } = data;
    await signInWithEmailAndPassword(email, password);
    history.push('/enterprise');
  };

  const buttonProps = { variant: 'contained', color: 'primary', fullWidth: true, className: classes.formElement}

  return (
    <Container maxWidth="sm">
      <Typography variant="h3">Enterprise Sign In</Typography>
      <form onSubmit={handleSubmit} className={classes.form}>
        <TextField {...formFieldProps('Email')} />
        <TextField {...formFieldProps('Password', 'password')} />
        <Button type="submit" {...buttonProps}>Sign In</Button>
        <Button component={Link} to="/enterprise/sign-up" {...buttonProps}>Sign Up</Button>
      </form>
    </Container>
  );
}