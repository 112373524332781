import React from "react";
import { Button, Grid, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import PasswordField from "../../components/PasswordField";
import Typography from "@material-ui/core/Typography";

import Loading from "../../components/Loading";
import VendorProfileReviewSection from "../../pages_common/vendorProfile/ReviewSection";

export default function PostedReviews({reviews_objs}) {
    return (
        <Grid container spacing={2}>
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5" style={{ marginBottom: 24 }}>
                            Your Reviews
                        </Typography>
                        <Divider style={{ marginBottom: 24 }} />
                    </Grid>
                </Grid>
            </React.Fragment>
            <Typography variant="subtitle1" style={{ marginBottom: 6 }}>
                Manage ratings and reviews you've written.
            </Typography>
            <VendorProfileReviewSection reviews={reviews_objs || []} reviewerView/>
        </Grid>
    );
}
