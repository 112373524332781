import React from "react";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import cn from "classnames";
import { useMutation } from "react-apollo";
import { CLEAR_SELECTED_VENDORS } from "../../lib/apollo/mutations";

const useStyles = makeStyles(theme => ({
    container: {
        position: "absolute",
        minWidth: "100%",
        maxWidth: "calc(100% + 32px)",
        zIndex: 1,
        background: theme.palette.primary.light,
        left: 0,
        top: "calc(100% + 8px)",
        "& a": {
            textDecoration: "none"
        }
    },
    highlightedItem: {
        background: "#00568c"
    },
    item: {
        color: "#fff",
        padding: 8,
        textOverflow: "ellipsis",
        overflow: "hidden",
        transition: "150ms",
        "&:hover": {
            background: "#015d96"
        }
    },
    divider: {
        backgroundColor: "rgba(255,255,255,0.3)"
    }
}));

export default function Suggestions({ items, highlightedSuggestion }) {
    const classes = useStyles();
    const [clearSelectedVendors] = useMutation(CLEAR_SELECTED_VENDORS);

    const suggestionItems = items.map((item, index) => (
        <Link
            onClick={clearSelectedVendors}
            to={`/vendors-of/${encodeURIComponent(item)}`}
            key={item + index}
        >
            <div
                className={cn(classes.item, {
                    [classes.highlightedItem]: highlightedSuggestion === index
                })}
            >
                {item}
            </div>
            <Divider className={classes.divider} />
        </Link>
    ));

    return <div className={classes.container}>{suggestionItems}</div>;
}
