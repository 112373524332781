import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    title: {
        color: "#828282",
        fontSize: 18
    }
}));

export default function CardTitle({ children }) {
    const classes = useStyles();

    return (
        <Typography variant="subtitle" color="inherit" className={classes.title}>
            {children}
        </Typography>
    );
}
