import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2),
        maxWidth: 900,
        margin: "0 auto 0 auto",
        background: "white",
        paddingBottom: 0,
        "@media (max-width: 960px)": {
            display: "none",
        },
    },
    image: {
        width: "60%",
        marginLeft: "auto",
        margineRight: "auto",
        paddingTop: "56%",
        display: "inline-block",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        "@media (max-width: 960px)": {
            width: "20%",
            paddingTop: "18%",
        },
    },
    card: {
        textAlign: "center",
        color: theme.palette.text.secondary,
    },
    processItem: {
        justifyContent: "space-between",
        textAlign: "center",
        marginTop: 0,
        marginBottom: 0,
        padding: 0,
        paddingBottom: 0,

        // height: 240,
        // "& h5": {
        //     height: 50,
        // },
        // "& h6": {
        //     height: 100,
        // }
    },
}));

export default withWidth()(ThreeItemBlock);

function ThreeItemBlock({ items, width }) {
    const classes = useStyles();
    const small = width === "xs" || width === "sm";
    return (
        <Grid container spacing={4} className={classes.root} justify="center">
            {items.map((category, index) => {
                const orderForItem = i =>
                    small ? index * 3 + i : i * 3 + index;
                return (
                    <React.Fragment key={category.label + "0" + index}>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            className={classes.processItem}
                            style={{ order: orderForItem(0) }}
                        >
                            <Typography
                                variant={category.body ? "h5" : "subtitle1"}
                                component="h5"
                            >
                                {category.label}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={4}
                            key={category.label + "1" + index}
                            className={classes.processItem}
                            style={{ order: orderForItem(1) }}
                        >
                            <div
                                className={classes.image}
                                style={{
                                    backgroundImage:
                                        'url("/' + category.image + '")',
                                }}
                            />
                        </Grid>

                        {category.body && (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                key={category.label + "2" + index}
                                className={classes.processItem}
                                style={{ order: orderForItem(2) }}
                            >
                                <Typography variant="body1" component="h6">
                                    {category.body}
                                </Typography>
                            </Grid>
                        )}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
}
