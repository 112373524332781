import { makeStyles } from "@material-ui/core/styles";
export const useVendorSearchStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(4),
        justifyContent: "flex-start"
    },
    vendorCompanyName: {
        textDecoration: "none",
        color: "#0073bb",
        "&:hover": {
            textDecoration: "underline"
        }
    },

    floatingButtonContainer: {
        marginTop: 56,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: 232,
        textDecoration: "none",
        "& p": {
            margin: "0 24px",
            fontSize: 16,
            fontWeight: 400,
            letterSpacing: 1.1,
            fontStyle: "italic",
            textTransform: "uppercase"
        },
        opacity: 0.8,
        color: "#3f69a5",
        border: "solid 1px #3f69a5",
        borderRadius: 4,
        padding: 8
    },

    vendorCompanyNameText: {
        marginLeft: 8
    },
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(0),
        transition: "0.3s cubic-bezier(0.4, 0.0, 0.2, 1)",
        position: "relative",
        border: `solid 0 ${theme.palette.primary.dark}`,
        maxWidth: 750
    },
    activePaper: {
        border: `solid 2px ${theme.palette.primary.dark}`,
        padding: theme.spacing(2) - 2
    },
    header: {
        margin: theme.spacing(4, 10),
        marginBottom: 0,
        textAlign: "center",
        fontWeight: 900,
        "@media (max-width: 600px)": {
            margin: theme.spacing(3, 3)
        }
    },
    subheader: {
        margin: theme.spacing(1, 7),
        textAlign: "center",
        fontWeight: 600,
        textTransform: "capitalize"
    },
    listItemContainer: {
        flexGrow: 1,
        display: "flex"
    },
    rating: {
        textAlign: "right"
    },
    vendorHeader: {},
    listItemBodyContainer: {
        //marginTop: theme.spacing(3),
        flexGrow: 1,
        display: "flex",
        padding: theme.spacing(1)
    },
    vendorBody: {
        marginBottom: 8,
        marginLeft: 8,
        whiteSpace: "nowrap"
    },
    reviewLink: {
        textDecoration: "none",
        color: "rgba(0,0,0,0.87)",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: "inline-block",
        marginLeft: "16.66%",
        textShadow: "0 0 1px rgba(0,0,0,0.15)",
        transition: "0.15s",
        "&:hover": {
            color: "rgba(0,0,0,0.380)",
            textShadow: "0 0 1px rgba(0,0,0,0)"
        },
        "@media (max-width: 700px)": {
            marginLeft: 8
        }
    },
    hideForMobile: {
        "@media(max-width: 500px)": {
            display: "none"
        }
    },
    reviewButtonText: {
        fontWeight: 600
    },
    serviceDisplay: {
        textTransform: "capitalize",
        textAlign: "center",
        marginBottom: theme.spacing(3)
    },
    button: {
        width: 130,
        marginBottom: 20,
        display: "inline-block",
        backgroundColor: "#111",
        color: "#fff",
        transition: "0.3s cubic-bezier(0.4, 0.0, 0.2, 1)",
        "&:hover": {
            backgroundColor: "#777"
        }
    },
    selectedButton: {
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
    },
    companyLogo: {
        marginTop: theme.spacing(1),
        height: 100,
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat"
    },
    goddamnLinks: {
        textDecoration: "none",
        color: "rgba(0,0,0,0.87)"
    },
    selectedCount: {
        position: "fixed",
        bottom: theme.spacing(2),
        zIndex: 9000,
        right: theme.spacing(2),
        marginLeft: theme.spacing(2),
        padding: theme.spacing(1, 2),
        maxWidth: 400,
        color: "#FFF",
        transition: "0.3s cubic-bezier(0.4, 0.0, 0.2, 1)",
        backgroundColor: "#555"
    },
    selectedCountEnabled: {
        backgroundColor: theme.palette.primary.light,
        "&:hover": {
            backgroundColor: theme.palette.primary.main
        }
    },
    textField: {
        display: "inline-block",
        marginTop: 0,
        marginBottom: 0
    }
}));
