import React from "react";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery } from "@apollo/react-hooks";
import { GET_USER, GET_VENDOR } from "../../lib/apollo/queries";
import Loading from "../../components/Loading";
import ErrorMessage from "../../components/Error";
import { Link, Redirect } from "react-router-dom";
import RFQItem from "./RFQItem";

export default function RFQManager() {
    const classes = useStyles();

    const { data, loading, error } = useQuery(GET_USER, {});
    const { data: vendorData, loading: vendorLoading, error: vendorError } = useQuery(GET_VENDOR, {
        variables: { vendor_id: data?.getUser?.vendor },
        skip: !data?.getUser?.vendor
    });

    if (loading || !data || (data?.getUser?.is_vendor && vendorLoading)) return <Loading />;
    if (error) return <ErrorMessage message={error} />;
    if (!(data && data.getUser !== null)) return <Redirect to="/" />;
    if (data.getUser.is_vendor && vendorError) return <ErrorMessage message={vendorError} />;

    const rfqs = data.getUser.is_vendor
        ? (vendorData && vendorData.getVendor.rfq_objs) || []
        : (data && data.getUser.rfqs) || [];

    return (
        <div>
            <Typography variant="h4" component="div" className={classes.header} gutterBottom>
                {data?.getUser?.is_vendor ? "Manage Leads" : "Manage Quote Requests"}
            </Typography>

            <div style={{ background: "white", paddingBottom: 160 }}>
                <Container className={classes.root} maxWidth="lg">
                    <Grid container spacing={3}>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={9}
                            style={{
                                margin: "48px auto"
                            }}
                        >
                            {rfqs &&
                                !rfqs.filter(
                                    rfq =>
                                        !rfq.closed &&
                                        (data.getUser.is_vendor ||
                                            (rfq.vendors && rfq.vendors.length))
                                ).length && (
                                    <div>
                                        <Typography variant="h5" gutterBottom>
                                            No Active Quote Requests
                                        </Typography>
                                        <Link
                                            to="/services/get-a-quote"
                                            style={{
                                                textDecoration: "none"
                                            }}
                                        >
                                            {!data?.getUser?.is_vendor && (
                                                <Button
                                                    style={{
                                                        marginTop: 24,
                                                        marginRight: 12
                                                    }}
                                                    variant="outlined"
                                                    color="primary"
                                                >
                                                    Request Quotes
                                                </Button>
                                            )}
                                        </Link>

                                        {!data?.getUser.is_vendor && (
                                            <Link
                                                to="/service-planner"
                                                style={{
                                                    textDecoration: "none"
                                                }}
                                            >
                                                <Button
                                                    style={{ marginTop: 24 }}
                                                    variant="outlined"
                                                    color="primary"
                                                >
                                                    Go To Service Planner
                                                </Button>
                                            </Link>
                                        )}
                                    </div>
                                )}

                            {rfqs &&
                                !!rfqs.length &&
                                rfqs
                                    .filter(
                                        rfq =>
                                            !rfq.closed &&
                                            (data.getUser.is_vendor ||
                                                (rfq.vendors && rfq.vendors.length))
                                    )
                                    .map(wrappedItem => (
                                        <RFQItem key={wrappedItem._id} wrappedItem={wrappedItem} />
                                    ))}
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(4)
    },

    vendorCompanyName: {
        textDecoration: "none",
        color: "#0073bb",
        "&:hover": {
            textDecoration: "underline"
        }
    },

    paper: {
        paddingLeft: 64,
        position: "relative",
        padding: 16,
        paddingTop: 24,
        borderRadius: 0,
        flexBasis: 800,
        paddingBottom: 32,
        borderTop: "solid 2px rgba(0,0,0,0.2)",
        marginBottom: 16,
        "@media (max-width: 700px)": {
            paddingLeft: 16
        }
    },

    header: {
        margin: theme.spacing(4, 12),
        marginBottom: 48,
        textAlign: "center",
        fontWeight: 900,
        "@media (max-width: 500px)": {
            margin: theme.spacing(3, 3)
        }
    },

    subheader: {
        margin: theme.spacing(3, 9),
        marginTop: theme.spacing(1),
        textAlign: "center"
    },

    listItemContainer: {
        flexGrow: 1,
        display: "flex"
    },

    leadNavigationContainer: {
        margin: "48px auto",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-start",
        "@media (max-width: 960px)": {
            display: "none"
        }
    },

    rating: {
        textAlign: "right"
    },

    vendorHeader: {},

    listItemBodyContainer: {
        marginTop: theme.spacing(3),
        flexGrow: 1,
        display: "flex"
    },

    vendorBody: {
        marginBottom: 8
    },

    reviewLink: {
        textDecoration: "none",
        color: "rgba(0,0,0,0.87)",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        display: "inline-block",
        textShadow: "0 0 1px rgba(0,0,0,0.15)",
        transition: "0.15s",
        "&:hover": {
            color: "rgba(0,0,0,0.380)",
            textShadow: "0 0 1px rgba(0,0,0,0)"
        }
    },

    reviewButtonText: {
        fontWeight: 600
    },

    serviceDisplay: {
        textTransform: "capitalize",
        textAlign: "center"
    },

    button: {
        width: 130,
        marginBottom: 16,
        display: "inline-block",
        marginTop: theme.spacing(2),
        transition: "0.3s cubic-bezier(0.4, 0.0, 0.2, 1)"
    },

    selectedButton: {
        backgroundColor: theme.palette.secondary.dark,
        boxShadow:
            "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
    },

    companyLogo: {
        backgroundImage: 'url("/process1.png")',
        marginTop: theme.spacing(1),
        height: 100,
        width: "100%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat"
    },

    goddamnLinks: {
        textDecoration: "none",
        color: "rgba(0,0,0,0.87)"
    },

    selectedCount: {
        position: "fixed",
        bottom: theme.spacing(3),
        right: theme.spacing(4),
        marginLeft: theme.spacing(4),
        padding: theme.spacing(2, 3),
        cursor: "pointer",
        transition: "0.15s",
        color: "#FFF",
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.dark
        }
    },

    blueLinks: {
        textDecoration: "none",
        color: "#0073bb",
        textAlign: "center",
        margin: "0 auto",
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));
