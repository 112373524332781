import React from "react";
import { Typography, List, ListItem, ListItemIcon, ListItemText }  from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

export function Compliances({compliance_objs}) {
    return (
        compliance_objs != 0 &&
        <List>
            {
                compliance_objs.includes("liability_insurance") &&
                <ListItem>
                    <ListItemIcon>
                        <CheckIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText>Liability Insurance</ListItemText>
                </ListItem>
            }
            {
                compliance_objs.includes("wsib") && 
                <ListItem>
                    <ListItemIcon>
                        <CheckIcon color="secondary" />
                    </ListItemIcon>
                    <ListItemText>WSIB</ListItemText>
                </ListItem>
            }
        </List>
    )
} 