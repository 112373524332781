import firebase from "firebase";

export default function credentialLevel() {
    const user = firebase.auth().currentUser;
    const admins = [
        "arya@vendorpm.com",
        "aryasolhi@gmail.com",
        "ryanmarks151@gmail.com",
        "emiel@vendorpm.com",
        "ninja8@gmail.com"
    ];
    return user && admins.indexOf(user.email) !== -1 ? "admin" : "user";
}
