import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram, FiMail } from "react-icons/fi";

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 80,
        maxWidth: 600,
        padding: "8px 24px",
        margin: "0 auto 0 auto",
        textAlign: "center",
        marginBottom: 80,
        "@media (max-width: 450px)": {
            padding: "8px 0"
        }
    },
    brand: {
        color: theme.palette.primary.dark,
        textRendering: "optimizeLegibility",
        fontWeight: 700,
        fontSize: 24,
        display: "inline-block",
        marginRight: 25,
        padding: "0 12px 0 12px",
        marginBottom: 10,
        marginTop: 6,
        marginLeft: 4,
        cursor: "default"
    },
    coreLinks: {
        padding: "32px 8px 0 8px",
        "& h6": {
            display: "inline-block",
            color: "rgba(0,0,0,0.87)",
            padding: "0 12px",
            fontWeight: 600,
            fontSize: 14,
            cursor: "pointer",
            "&:hover": {
                textDecoration: "underline"
            },
            "@media (max-width: 450px)": {
                fontSize: 13,
                padding: "0 10px"
            },
            "@media (max-width: 400px)": {
                fontSize: 12,
                padding: "0 8px"
            }
        }
    },
    footerWrapper: {
        padding: "24px 8px 0 8px",
        "& svg": {
            margin: "0 6px"
        }
    },
    secondaryLinks: {
        padding: "24px 8px 0 8px",
        "& h6": {
            "@media (max-width: 450px)": {
                fontSize: 12,
                padding: "0 6px"
            },
            "&:hover": {
                color: "#151515"
            },
            display: "inline-block",
            padding: "0 8px",
            fontSize: 13,
            color: theme.palette.grey[600],
            cursor: "pointer"
        }
    }
}));

function Footer() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.brand}>
                <span>VendorPM</span>
            </div>
            <div className={classes.coreLinks}>
                <Link to="/search-vendors">
                    <Typography variant="subtitle1">Write A Review</Typography>
                </Link>
                <Link to="/services">
                    <Typography variant="subtitle1">Services</Typography>
                </Link>

                <Link to="/search-vendors">
                    <Typography variant="subtitle1">Search Vendors</Typography>
                </Link>
                {/* <Link to="/about">
                    <Typography variant="subtitle1">Vendors</Typography>
                </Link> */}

                {/* <Link to="/faq">
                    <Typography variant="subtitle1">FAQ</Typography>
                </Link> */}
                {/* <Link to="/blog">
                    <Typography variant="subtitle1">Insights</Typography>
                </Link> */}
                {/* <Link to="/account">
                    <Typography variant="subtitle1">Account</Typography>
                </Link> */}
            </div>
            <div className={classes.secondaryLinks}>
                <Link to="/terms-and-conditions">
                    <Typography variant="subtitle1">Terms</Typography>
                </Link>
                <Link to="/terms-and-conditions">
                    <Typography variant="subtitle1">Privacy</Typography>
                </Link>{" "}
                <Link to="/faq">
                    <Typography variant="subtitle1">Help</Typography>
                </Link>
            </div>
            {/* <div className={classes.footerWrapper}>
                <FiFacebook size={20} />
                <FiTwitter size={20} />
                <FiInstagram size={20} />
                <FiMail size={20} />
            </div> */}
        </div>
    );
}

export default Footer;
