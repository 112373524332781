const firebaseConfig = {
  apiKey: "AIzaSyCfvubiHTCD6v7wlPrVJPozea0R6wAXrYM",
  authDomain: "vendorpm-b6167.firebaseapp.com",
  databaseURL: "https://vendorpm-b6167.firebaseio.com",
  projectId: "vendorpm-b6167",
  storageBucket: "vendorpm-b6167.appspot.com",
  messagingSenderId: "737611827041",
  appId: "1:737611827041:web:a96150731087f0fe842720",
  measurementId: "G-CYVPN0FLCL",
};

export default firebaseConfig;
