import React from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Dialog } from "@material-ui/core";
import VendorSignUpForm from "../../pages_vendor/vendor_sign_up/VendorSignUpForm";

export default function ClaimSignUp({ data, open, onClose }) {
    const { vendor_id, token } = useParams();
    const location = useLocation();
    const claim = location.pathname.includes("claim");

    if (!claim) return <React.Fragment />;
    if (data?.getVendor?.claimed) return <React.Fragment />;
    return (
        <Dialog onClose={onClose} aria-labelledby="claim-vendor-sign-up" open={open}>
            <VendorSignUpForm claim prefill={data} />
        </Dialog>
    );
}
