import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Table from "../../../components/Table";
import VendorDetails from "./VendorDetails";
import VendorFilter from "./VendorFilter";
import ComplianceFilter from "./ComplianceFilter";
import { Typography, Box, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AddVendorForm from "./AddVendorForm";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    }
}));

const FAKE_DATA = {
    totalVendors: 1113,
    vendors: [
        {
            firstName: "Bob",
            id: "12322",
            company: "GDI",
            service: "Janitorial",
            internalRating: "4.40",
            industryRating: "4.32",
            role: "Sr. Customer Manager",
            email: "anna@email.com",
            phone: "+16479380024",
            twitter: "@gdi",
            location: "Toronto, Canada",
            bio: "this is a space for a bio."
        },
        {
            firstName: "Heather",
            id: "12323",
            company: "Bee Clean",
            service: "Janitorial",
            internalRating: "3.40",
            industryRating: "4.32",
            role: "Sr. Customer Manager",
            email: "anna@email.com",
            phone: "+16479380024",
            twitter: "@gdi",
            location: "Toronto, Canada",
            bio: "this is a space for a bio."
        },
        {
            firstName: "Eagle",
            id: "12324",
            company: "Bee Clean",
            service: "Janitorial",
            internalRating: "3.40",
            industryRating: "4.32",
            role: "Sr. Customer Manager",
            email: "anna@email.com",
            phone: "+16479380024",
            twitter: "@gdi",
            location: "Toronto, Canada",
            bio: "this is a space for a bio."
        },
        {
            firstName: "Emily",
            id: "12325",
            company: "Bee Clean",
            service: "Janitorial",
            internalRating: "3.40",
            industryRating: "4.32",
            role: "Sr. Customer Manager",
            email: "anna@email.com",
            phone: "+16479380024",
            twitter: "@gdi",
            location: "Toronto, Canada",
            bio: "this is a space for a bio."
        },
        {
            firstName: "Kyle",
            id: "12326",
            company: "Cutting Edge",
            service: "Glass Replacement",
            internalRating: "3.40",
            industryRating: "4.32",
            role: "Sr. Customer Manager",
            email: "anna@email.com",
            phone: "+16479380024",
            twitter: "@gdi",
            location: "Toronto, Canada",
            bio: "this is a space for a bio."
        }
    ]
};

const COLUMNS = [
    { key: "firstName", name: "Contact", align: "left" },
    { key: "company", name: "Company", align: "left" },
    { key: "service", name: "Service", align: "left" },
    { key: "internalRating", name: "Internal Rating", align: "center" },
    { key: "industryRating", name: "Industry Rating", align: "center" }
];

export default function PreferredVendors() {
    const [showDetails, setShowDetails] = useState(false);
    const [selected, setSelected] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const classes = useStyles();

    const handleOpen = () => {
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const openVendorDetails = id => {
        setSelected(id);
        setShowDetails(true);
    };
    const closeVendorDetails = () => {
        setSelected(null);
        setShowDetails(false);
    };

    const data = FAKE_DATA.vendors.map(({ id, ...rest }) => ({
        id,
        onClick: () => openVendorDetails(id),
        ...rest
    }));

    const selectedData = data.find(({ id }) => id === selected);
    return (
        <Box>
            <Box display="flex" flexDirection="row" flex={1}>
                <Box flex={1}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <h1>Preferred Vendors</h1>
                        <Box pt={1} pl={2} flex={1}>
                            <Typography variant="subtitle2" m={2}>
                                {FAKE_DATA.totalVendors} total
                            </Typography>
                        </Box>
                        <Button variant="contained" color="primary" onClick={handleOpen}>
                            Add Vendor
                        </Button>
                    </Box>
                    <Table {...{ columns: COLUMNS, data, selectedId: selected }} />
                </Box>
                <Box>
                    <VendorFilter />
                    <ComplianceFilter />
                </Box>
            </Box>
            <SwipeableDrawer anchor="right" open={showDetails} onClose={() => closeVendorDetails()}>
                {selectedData && (
                    <VendorDetails {...{ vendor: selectedData, closeVendorDetails }} />
                )}
            </SwipeableDrawer>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500
                }}
            >
                <Fade in={openModal}>
                    <AddVendorForm onSubmit={handleClose} />
                </Fade>
            </Modal>
        </Box>
    );
}
