import React, { useState } from "react";
import SignUpContainer from "../../components/sign_up/SignUpContainer";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Button, Divider, Paper, TextField, Typography } from "@material-ui/core";
import { useSignUpStyles } from "../../components/sign_up/useSignUpStyles";
import firebase from "firebase/app";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { CREATE_USER_WITH_VENDOR, CREATE_USER_CLAIM_VENDOR } from "../../lib/apollo/mutations";
import { GET_USER, GET_USER_FROM_TOKEN } from "../../lib/apollo/queries";
import BasicInfo from "../../components/sign_up/BasicInfo";
import SignupCredentials from "../../components/sign_up/SignupCredentials";
import { makeSetterForProperty } from "../../lib/helpers";
import { VerifiedAddressPicker } from "../../components/VerifiedAddressPicker";
import AdditionalInfo from "./AdditionalInfo";

export const makeUserFromTokenOptions = ({ onCompleted, invite, invitation_token }) => ({
    variables: { token: invitation_token },
    skip: !invite,
    onCompleted
});

export default function VendorSignUpForm({ invite, claim, prefill }) {
    const classes = useSignUpStyles();
    const history = useHistory();
    const { invitation_token, auth_needed } = useParams();
    const currentUser = firebase.auth().currentUser;

    async function onLogin() {
        if (auth_needed === "return") {
            history.goBack();
        }
        history.push("/vendor-welcome");
    }

    const [state, setState] = useState({
        step: 0,
        email: "",
        password: "",
        website: "",
        confirmPassword: "",
        firstName: "",
        lastName: "",
        companyName: "",
        displayedFormattedAddress: {},
        phoneNumber: "",
        availability: "Soon",
        services: [],
        associations: [],
        compliances: [],
        description: "",
        logo: null,
        ErrorMsg: ""
    });

    const makeSetStateForProp = prop => value => setState({ ...state, [prop]: value });
    const setDisplayedFormattedAddress = makeSetStateForProp("displayedFormattedAddress");
    const setEmail = makeSetStateForProp("email");
    const setStep = makeSetStateForProp("step");

    const [ErrorMsg, setError] = useState("");

    const makeVariables = uid => ({
        variables: {
            user: {
                invitation_token,
                email: state.email || currentUser?.email,
                company_name: state.companyName,
                phone_number: state.phoneNumber,
                name: {
                    first: state.firstName,
                    last: state.lastName
                },
                uid,
                address: state.displayedFormattedAddress
            },
            vendor: {
                // _id: prefill?.getVendor?._id || undefined,
                address: state.displayedFormattedAddress,
                company_name: state.companyName,
                availability: state.availability,
                services: state.services,
                website: state.website,
                associations: state.associations,
                compliances: state.compliances,
                description: state.description,
                logo: state.logo,
                employee_count: state.employee_count
            }
        },
        refetchQueries: [{ query: GET_USER }],
        awaitRefetchQueries: true,
        onCompleted: _ => {
            onLogin();
        }
    });
    const makeClaimVariables = uid => ({
        variables: {
            user: {
                invitation_token,
                email: state.email || currentUser?.email,
                company_name: state.companyName,
                phone_number: state.phoneNumber,
                name: {
                    first: state.firstName,
                    last: state.lastName
                },
                uid,
                address: state.displayedFormattedAddress
            },
            vendor_id: prefill?.getVendor._id
        },
        refetchQueries: [{ query: GET_USER }],
        awaitRefetchQueries: true,
        onCompleted: _ => {
            onLogin();
        }
    });
    const variables = prefill?.getVendor?._id
        ? makeClaimVariables(currentUser?.uid)
        : makeVariables(currentUser?.uid);
    console.log(prefill?.getVendor);

    const { data: invitedUser, loading, error } = useQuery(
        GET_USER_FROM_TOKEN,
        makeUserFromTokenOptions({
            invite,
            invitation_token,
            onCompleted: data => {
                setEmail(data?.getUserFromToken?.email);
            }
        })
    );

    const [createUser] = useMutation(CREATE_USER_WITH_VENDOR, variables);
    const [createUserClaim] = useMutation(CREATE_USER_CLAIM_VENDOR, variables);
    const onSignUp = uid => {
        prefill?.getVendor?._id
            ? createUserClaim(makeVariables(uid))
            : createUser(makeVariables(uid));
    };
    console.log(state);
    return (
        <Paper
            style={{
                padding: 12,
                width: 364,
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: !prefill ? 56 : 0
            }}
        >
            {claim && (
                <Typography gutterBottom variant="h5" style={{ marginBottom: 24 }}>
                    Claim this Business
                </Typography>
            )}
            {!claim && (
                <Typography gutterBottom variant="h5" style={{ marginBottom: 24 }}>
                    Register your Business
                </Typography>
            )}
            {state.step === 0 && (
                <BasicInfo {...{ state, setState }} prefill={prefill}>
                    <VerifiedAddressPicker
                        prefill={prefill}
                        label={"Address*"}
                        classes={classes}
                        addressPredicate={address => address.province === "Ontario"}
                        onAddress={setDisplayedFormattedAddress}
                    />
                </BasicInfo>
            )}
            {/* if you're claiming a vendor, there's no need for additional info. Skip to step 2 */
            state.step === 1 && prefill && setStep(2)}
            {state.step === 1 && <AdditionalInfo {...{ state, setState, prefill }} />}

            {state.step === 2 ? (
                <SignupCredentials
                    {...{
                        state,
                        setState,
                        setError,
                        onSignUp,
                        onLogin,
                        invitedUser
                    }}
                >
                    <Typography color="error">{ErrorMsg}</Typography>
                </SignupCredentials>
            ) : null}
        </Paper>
    );
}
