import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles({
    root: {
        flex: 1,
        display: "flex"
    },
    input: {
        width: 42
    }
});

const STEP = 0.1;

export default function InputSlider({ min, max, label, initialValue, onChange, ...rest }) {
    const classes = useStyles();
    const [value, setValue] = useState(initialValue);

    const setSliderValue = value => {
        setValue(value);
        onChange(value);
    };

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };

    const handleInputChange = event => {
        setSliderValue(event.target.value === "" ? "" : Number(event.target.value));
    };

    const handleBlur = () => {
        if (value < min) {
            setSliderValue(min);
        } else if (value > max) {
            setSliderValue(max);
        }
    };

    return (
        <FormControl className={classes.root}>
            <Box pb={3}>
                <InputLabel htmlFor="my-input">{label}</InputLabel>
            </Box>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Slider
                        min={min}
                        max={max}
                        step={STEP}
                        value={typeof value === "number" ? value : 0}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        {...rest}
                    />
                </Grid>
                <Grid item>
                    <Input
                        className={classes.input}
                        value={value}
                        margin="dense"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: STEP,
                            min,
                            max,
                            type: "number",
                            "aria-labelledby": "input-slider"
                        }}
                    />
                </Grid>
            </Grid>
        </FormControl>
    );
}
