import React from "react";
import { Card, CardMedia, CardHeader, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const listOfCategories = [
    {
        image: "electrician.jpg",
        label: "Facility Maintenance",
    },
    {
        image: "handyman.jpg",
        label: "Construction & GC",
    },
    {
        image: "landscapeDesign.jpg",
        label: "Professional Services",
    },
    {
        image: "plumbing.jpg",
        label: "Emergency Services",
    },
    {
        image: "smart.jpg",
        label: "Smart & Connected",
    },
];

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        maxWidth: 1200,
        margin: "0 auto",
        width: "100%",
        marginTop: 24,
        padding: "0 32px",
        "& a": {
            textDecoration: "none",
        },
        "@media(max-width: 960px)": {
            padding: "0 80px",
        },
        "@media(max-width: 800px)": {
            padding: "0 40px",
        },
        "@media(max-width: 750px)": {
            padding: "0 32px",
        },
        "@media(max-width: 700px)": {
            padding: "0 24px",
        },
        "@media(max-width: 600px)": {
            padding: "0 80px",
        },
        "@media(max-width: 500px)": {
            padding: "0 56px",
        },
        "@media(max-width: 420px)": {
            padding: "0 12px",
        },
    },
    media: {
        height: 0,
        paddingTop: "56.25%", // 16:9
    },
    card: {
        textAlign: "center",
        border: "solid 1px #d7d7d7",
        color: theme.palette.text.primary,
        marginTop: 8,
        cursor: "pointer",
        "&:hover": {
            filter: "brightness(0.95) contrast(0.85)",
        },
    },
    floatingBtnContainer: {
        position: "absolute",
        bottom: -96,
        left: 0,
        right: 0,
        width: "100%",
    },

    floatingBtns: {
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
        borderRadius: 4,
    },
}));

export default function PopularCategories() {
    const classes = useStyles();

    return (
        <Grid spacing={1} container className={classes.root} justify="center">
            {listOfCategories.map((category, index) => (
                <Grid item xs={12} sm={6} md={4} key={category.label + index}>
                    <Link to={"/categories/" + category.label}>
                        <Card className={classes.card} elevation={0}>
                            <CardHeader title={category.label} />
                            <CardMedia
                                className={classes.media}
                                image={"/" + category.image}
                                title={category.label}
                            />
                        </Card>
                    </Link>
                </Grid>
            ))}

            <Grid item xs={12} style={{ textAlign: "center" }}>
                <Link to="/categories">
                    <Button
                        color="primary"
                        variant="outlined"
                        className={classes.floatingBtns}
                    >
                        Browse Categories
                    </Button>
                </Link>
            </Grid>
        </Grid>
    );
}
