import React from "react";
import { Route } from "react-router-dom";
import Account from "./account/Account";
import SignUp from "./sign_up/SignUp";
import ServicePlanner from "./service_planner/ServicePlanner";
import RFQManager from "./rfq_manager/RFQManager";
import RequestQuotesForm from "./request_quotes_form/RequestQuotesForm";
import ReviewForm from "./ReviewForm";
import SignIn from "./sign_up/SignIn";

export default [
    ["/service-planner/:param1", <ServicePlanner />],
    ["/service-planner", <ServicePlanner />],
    ["/your-quotes", <RFQManager />],
    ["/account", <Account />],
    ["/account/:page", <Account />],
    [["/sign-in/return", "/sign-in"], <SignIn />],
    ["/vendor-profile/:vendor_id/review", <ReviewForm />],
    ["/vendor-profile/:vendor_name/review/new", <ReviewForm />],
    ["/request-quotes/:service_id", <RequestQuotesForm />],
    [["/sign-up/:auth_needed", "/sign-up"], <SignUp />]
].map(([path, component]) => (
    <Route exact key={path} path={path}>
        {component}
    </Route>
));
