import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Toolbar,
    Typography,
    AppBar,
    Drawer,
    List,
    Divider,
    ListItem,
    ListItemText,
    TextField
} from "@material-ui/core";
import { FiMenu } from "react-icons/fi";
import cn from "classnames";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo";
import uglyFeatureFlags from "./lib/uglyFeatureFlags";
import { CLEAR_SELECTED_VENDORS } from "./lib/apollo/mutations";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import firebase from "firebase/app";
import { GET_USER } from "./lib/apollo/queries";
import credentialLevel from "./lib/credentialLevel";
import { user } from "./enterprise/lib/firebase";
import Loading from "components/Loading";

const unauthed_navitems = [
    ["/sign-in", "Sign In"],
    ["/sign-up", "Sign Up"]
];
const authed_pm_navitems = [
    ["/your-quotes", "Your Quotes"],
    ["/service-planner", "Service Planner"],
    ["/account", "Account"]
];

const authed_vendor_navitems = [
    ["/your-quotes", "Your Leads"],
    ["/account", "Account"]
];

function Navbar() {
    const classes = useStyles();
    let location = useLocation();
    const signinReturn = location === "return";
    // make sign in from sign up return go to sign in return and then do a goBack(2)

    const { data, loading, error } = useQuery(GET_USER);
    const [clearSelectedVendors] = useMutation(CLEAR_SELECTED_VENDORS);
    if (loading) return <Loading />;

    const userExists = Boolean(!error && !loading && data && data.getUser);

    const user = data.getUser;
    let navItems;
    if (!user) navItems = unauthed_navitems;
    else if (user.is_vendor) navItems = authed_vendor_navitems;
    else navItems = authed_pm_navitems;

    return (
        <AppBar color="secondary" position="static" className={classes.classyNavbar}>
            <Toolbar variant="dense" className={classes.classyToolbar}>
                <MobileNav items_of_the_nav={navItems} />

                <span className={classes.leftNav}>
                    <LogoButton />

                    {/* hide links when a vendor is logged in, esp write a review */}
                    {location && location.pathname !== "/vendor-sign-up" && (
                        <React.Fragment>
                            {!(userExists && data.getUser.is_vendor) && (
                                <Link to="/search-vendors" onClick={clearSelectedVendors}>
                                    <Typography variant="h6" className={classes.btn}>
                                        Write A Review
                                    </Typography>
                                </Link>
                            )}
                            {!(userExists && data.getUser.is_vendor) && (
                                <Link to="/services/get-a-quote">
                                    <Typography variant="h6" className={classes.btn}>
                                        Request Quotes
                                    </Typography>
                                </Link>
                            )}
                            {!(userExists && data.getUser.is_vendor) && (
                                <Link to="/categories">
                                    <Typography variant="h6" className={classes.btn}>
                                        Categories
                                    </Typography>
                                </Link>
                            )}
                        </React.Fragment>
                    )}
                    {user && user.admin && (
                        <Link to="/admin">
                            <Typography variant="h6" className={classes.btn}>
                                Admin
                            </Typography>
                        </Link>
                    )}
                </span>
                {location &&
                    location.pathname !== "/vendor-sign-up" &&
                    navItems.map(([path, label]) => {
                        const signInLink = path === "/sign-in";
                        return (
                            <Link
                                to={signinReturn && signInLink ? "/sign-in/return" : path}
                                key={path}
                            >
                                <Typography
                                    variant="h6"
                                    className={cn(classes.signin, classes.authlinks)}
                                >
                                    {label}
                                </Typography>
                            </Link>
                        );
                    })}

                {uglyFeatureFlags.areYouAVendorButton &&
                    !userExists &&
                    location &&
                    location.pathname !== "/vendor-sign-up" && (
                        <Link to="/vendor-sign-up">
                            <Typography
                                variant="h6"
                                className={cn(classes.authlinks, classes.signup)}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                            >
                                Are You A Vendor?
                            </Typography>
                        </Link>
                    )}
            </Toolbar>
        </AppBar>
    );
}

const allowedMultiusers = [
    "arya@vendorpm.com",
    "aryasolhi@gmail.com",
    "ryanmarks151@gmail.com",
    "nbali3@live.com",
    "emiel@vendorpm.com"
];

function LogoButton() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const input = React.useRef(null);

    const [user, setUser] = React.useState(null);
    React.useEffect(() => firebase.auth().onAuthStateChanged(setUser), []);
    const history = useHistory();
    const location = useLocation();

    const [page, setPage] = React.useState(null);
    React.useEffect(() => history.listen(setPage), []);

    const [canDeploy, setCanDeploy] = React.useState(false);
    React.useEffect(
        () =>
            setCanDeploy(
                user &&
                    credentialLevel() === "admin" &&
                    location &&
                    (location.pathname === "/" || location.pathname === "")
            ),
        [user, page]
    );

    const [asUser, setAsUser] = React.useState(localStorage.getItem("as_user") || "");
    const { data, loading, error } = useQuery(GET_USER);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <Tooltip
            PopperProps={{
                disablePortal: true
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            arrow
            title={
                <TextField
                    size="small"
                    variant="filled"
                    style={{ width: 280 }}
                    InputProps={{ style: { textColor: "#FFFFFF", color: "#FFFFFF" } }}
                    ref={input}
                    inputRef={input => input?.focus()}
                    value={asUser}
                    onChange={e => {
                        setAsUser(e.target.value);
                        localStorage.setItem("as_user", e.target.value);
                    }}
                    onKeyDown={e => {
                        if (e.keyCode === 13) {
                            window.location.href = "/account";
                        }
                    }}
                    onBlur={() => {
                        window.location.href = "/account";
                    }}
                />
            }
        >
            {allowedMultiusers.includes(firebase.auth().currentUser?.email) ? (
                <span onClick={handleTooltipOpen} className={classes.title}>
                    VendorPM
                </span>
            ) : (
                <Link to={"/"} style={{ textDecoration: "none" }} className={classes.title}>
                    VendorPM
                </Link>
            )}
        </Tooltip>
    );
}

const useMobileNavStyles = makeStyles(theme => ({
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    },
    openMobileNavIcon: {
        "@media (max-width: 900px)": {
            color: "#000",
            display: "inline"
        },
        cursor: "pointer",
        display: "none"
    },

    title: {
        margin: "auto 0",
        paddingTop: 3,
        paddingBottom: 3,
        fontWeight: 700,
        textRendering: "optimizeLegibility",
        color: theme.palette.primary.dark,
        fontSize: 24,
        display: "inline-block",
        height: "100%",
        cursor: "pointer"
    },

    link: {
        display: "block",
        textAlign: "center",
        textDecoration: "none",
        color: "rgba(0,0,0,0.87)",
        marginTop: 8
    }
}));

function MobileNav({ items_of_the_nav }) {
    const classes = useMobileNavStyles();
    const [open, setOpen] = useState(false);
    const closeUp = () => setOpen(false);
    const openUp = () => setOpen(true);
    const { data, loading, error } = useQuery(GET_USER);
    const userExists = !!(!(error || loading) && data && data.getUser);

    return (
        <div>
            <FiMenu className={classes.openMobileNavIcon} size={24} onClick={openUp} />

            <Drawer anchor="top" open={open} onClose={closeUp}>
                <div
                    className={classes.fullList}
                    role="presentation"
                    onClick={closeUp}
                    onKeyDown={closeUp}
                >
                    <Link to="/" className={classes.link}>
                        <Typography variant="h6" className={classes.title}>
                            VendorPM
                        </Typography>
                    </Link>

                    <List>
                        {[
                            ["Write A Review", "/search-vendors"],
                            ["Request Quotes", "/services/get-a-quote"],
                            ["Categories", "/categories"]
                        ].map(
                            (text, index) =>
                                !(userExists && data.getUser.is_vendor) && (
                                    <Link
                                        to={text[1]}
                                        key={text[1] + index}
                                        className={classes.link}
                                    >
                                        <ListItem
                                            button
                                            style={{ paddingTop: 0, paddingBottom: 0 }}
                                        >
                                            <ListItemText primary={text[0]} />
                                        </ListItem>
                                    </Link>
                                )
                        )}
                    </List>

                    <Divider />

                    <List style={{ paddingBottom: 24 }}>
                        {items_of_the_nav.map((text, index) => (
                            <Link to={text[0]} className={classes.link} key={text[0] + index}>
                                <ListItem
                                    button
                                    key={text[1]}
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                >
                                    <ListItemText primary={text[1]} />
                                </ListItem>
                            </Link>
                        ))}
                    </List>
                </div>
            </Drawer>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    classyNavbar: {
        backgroundColor: "#fff",
        boxShadow: `
             0px 2px 2px -1px rgba(80,80,80,0.05),
             0px 4px 3px 0px rgba(80,80,80,0.025),
             0px 1px 2px 0px rgba(80,80,80,0.025)
        `,
        "@media (min-width: 900px)": {
            padding: "8px 0"
        },
        "@media (max-width: 900px)": {
            boxShadow: `none`,
            borderBottom: "solid 1px #dadae0"
        }
    },
    leftNav: {
        marginRight: 16,
        flexGrow: 1,
        "& a": {
            margin: "auto 0"
        },
        "@media (max-width: 900px)": {
            textAlign: "center"
        }
    },
    title: {
        margin: "auto 0",
        paddingTop: 3,
        paddingBottom: 3,
        fontWeight: 700,
        textRendering: "optimizeLegibility",
        color: theme.palette.primary.dark,
        transition: "opacity 150ms linear",
        opacity: 1,
        "&:hover": {
            opacity: 0.5
        },
        fontSize: 24,
        display: "inline-block",
        marginRight: 12,
        height: "100%",
        marginLeft: 4,
        cursor: "pointer"
    },
    btn: {
        color: "rgba(0,0,0,0.87)",
        userSelect: "none",
        fontFamily: "Segoe UI, Verdana, sans-serif",
        display: "inline-block",
        fontSize: 14,
        letterSpacing: "-0.03em",
        padding: "12px 8px 12px 8px",
        marginLeft: 4,
        marginRight: 4,
        fontWeight: 500,
        cursor: "pointer",
        borderBottomWidth: 0,
        borderBottomStyle: `solid`,
        borderBottomColor: theme.palette.primary.light,
        transition: "0.12s",
        "&:hover": {
            color: "rgba(0,0,0,0.38)"
        },
        "@media (max-width: 900px)": {
            display: "none"
        }
    },
    goddamnLinks: {
        textDecoration: "none",
        color: "rgba(0,0,0,0.87)"
    },

    authlinks: {
        userSelect: "none",
        marginRight: 4,
        letterSpacing: "-0.03em",
        fontFamily: "Segoe UI, Verdana, sans-serif",
        display: "inline-block",
        fontSize: 13,
        padding: "13px 14px 13px 14px",
        cursor: "pointer",
        color: "#5a5a5a",
        transition: "0.15s",
        "@media (max-width: 900px)": {
            display: "none"
        }
    },
    signin: {
        fontSize: 14,
        fontWeight: 700,
        color: "#4a4a4a",
        textShadow: "0 0 7px rgba(250,250,250,0.87)",
        "&:hover": {
            color: "rgba(0,0,0,0.38)"
        }
    },
    signup: {
        fontWeight: 600,
        padding: "9px 16px 9px 16px",
        margin: "3px 4px 3px 4px",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        transition: "background-color 0.22s linear",
        "&:hover": {
            color: "#fff",
            backgroundColor: theme.palette.primary.dark
        }
    },
    account_menu_item: {
        transition: "0.15s",
        fontWeight: 500,
        padding: theme.spacing(1, 4),
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
            color: "#fff"
        }
    }
}));

export default Navbar;
