import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Legend } from "recharts";

const fills = ["#EFC255", "#385FF6", "#845CF6", "#56BFD1"];

const useStyles = makeStyles(theme => ({
    label: {
        textTransform: "uppercase",
        paddingBottom: theme.spacing(2),
        legend: {
            position: "relative",
            left: 100
        }
    }
}));

function ResponsivePieChart({ label, data, xAxisKey = "name", lineKeys = [] }) {
    const classes = useStyles();
    const addColors = data => {
        return data.map((item, ind) => ({ ...item, fill: fills[ind] }));
    };
    const augmentData = addColors(data);
    return (
        <Box>
            <ResponsiveContainer width="100%" height={400}>
                <PieChart margin={{ top: 0, right: 0, left: -20, bottom: 10 }}>
                    <Pie
                        data={augmentData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={"60%"}
                        outerRadius={"80%"}
                        label
                    />
                    <Tooltip />
                    <Legend
                        iconType="circle"
                        // layout="vertical"
                        align="center"
                        wrapperStyle={{ paddingTop: 10 }}
                        // verticalAlign="middle"
                    />
                </PieChart>
            </ResponsiveContainer>
        </Box>
    );
}

export default memo(props => <ResponsivePieChart {...props} />);
